export default [
	{
		brand: "dejun",
		card: 11,
		name: "Up Dejun",
		order: 1,
		perso: true,
	},
	{
		brand: "cadou",
		card: 12,
		name: "Up Cadou",
		order: 2,
		perso: true,
	},
];
