export default [
    {
        fileName: "afiliat/afiliat2.jpg",
        link:
            "#",
        category: "Banner",
        action: "Click",
        label: "afiliati_banner",
    },
  // {
  //   fileName: "client/Creste-Valoarea-nominala-uponline-1018x166.png",
  //   link:
  //     "https://upromania.ro/fisiere/ordonanta-de-urgenta-nr-69-2023-pentru-modificarea-art-14-din-legea-nr-165-2018-privind-acordarea-biletelor-de-valoare-precum-si-pentru-stabilirea-unor-masuri-pentru-aplicarea-acestor-prevederi.pdf",
  //   category: "Banner",
  //   action: "Click",
  //   label: "Companii_VN35",
  // },
];
