<template>
  <div>
    <div v-if="this.company" class="menu-items">
      <a v-if="(this.user.role === 'FAC'  || this.user.role === 'HLP') " href="/company/search" class="search-icon" @click="removeSelectedCompany"
        :class="this.menuSelected.companySearch && 'search-active'">{{
    $t("title.searchName") }}</a>
      <a
      v-if="this.company.company_id !== 0 "
        href="/affiliate/dashboard"
        class="dashboard"
        :class="this.menuSelected.dashboard && 'dashboard-active'"
        >{{ $t("title.dashboardName") }}</a
      >
      <a v-if="this.company.company_id !== 0 "
        href="/affiliate/cardTransactions"
        class="cardTransactions"
        :class="this.menuSelected.cardTransactions && 'cardTransactions-active'"
        >{{ $t("title.cardTransactionsName") }}</a
      >
      <a
        v-if="this.company.company_id !== 0"
        href="/affiliate/invoices"
        class="invoices"
        :class="this.menuSelected.invoices && 'invoices-active'"
        >{{ $t("title.invoicesName") }}</a
      >
      <a
        v-if="this.company.company_id !== 0"
        href="/affiliate/workingPoints"
        class="workingPoints"
        :class="this.menuSelected.workingPoints && 'workingPoints-active'"
        >{{ $t("title.workingPoints") }}</a
      >
      <!-- <a v-if="this.company.company_id !== 0 "
        href="/affiliate/pos"
        class="pos"
        :class="this.menuSelected.pos && 'pos-active'"
        >{{ $t("title.pos") }}</a
      > -->
      <a
        v-if="this.company.company_id !== 0"
        href="/affiliate/stickers"
        class="stickers"
        :class="this.menuSelected.stickers && 'stickers-active'"
        >{{ $t("title.stickersName") }}</a
      >
      <!-- <a
        v-if="this.company.company_id !== 0"
        href="/affiliate/offers"
        class="offers"
        :class="this.menuSelected.offers && 'offers-active'"
        >{{ $t("title.offersLabel") }}</a
      > -->
      <a
        href="/affiliate/account"
        class="amanage"
        :class="this.menuSelected.account && 'amanage-active'"
        >{{ $t("title.settingsName") }}</a
      >
      <a
        v-if="
          this.company.company_id !== 0 &&
          !user.username.includes('@') &&
          this.user.companies[0].user_config &&
          this.user.companies[0].user_config.roles.includes('EDITOR')
        "
        href="/company/manage"
        class="cmanage"
        :class="this.menuSelected.manageCompany && 'cmanage-active'"
        >{{ $t("title.companyManagementTitle") }}</a
      >
      <!-- <a
        v-if="contractStatus > -1"
        href="/affiliate/additionalDocuments"
        class="additionalDocuments"
        :class="
          this.menuSelected.additionalDocuments && 'additionalDocuments-active'
        "
        >{{ $t("title.additionalDocuments") }}</a
      > -->
      <a
        href="/affiliate/contact"
        class="contact"
        :class="this.menuSelected.contact && 'contact-active'"
        >{{ $t("title.contactName") }}</a
      >
      <!-- <a
        href="/affiliate/terms"
        class="term"
        :class="this.menuSelected.terms && 'term-active'"
        >{{ $t("title.termsAndConditionsName") }}</a
      >
      <a
        href="/affiliate/gdpr"
        class="policy"
        :class="this.menuSelected.gdpr && 'policy-active'"
        >{{ $t("title.personalDataPolicyName") }}</a
      > -->

      <a href="#" class="logout" @click="logout()">{{ $t("title.logoutName") }}</a>
    </div>
    <MenuSocials />
  </div>
</template>
<script>
import Cookies from "js-cookie";
import MenuSocials from "@/components/MenuSocials.vue";

export default {
  name: "MenuItemsAffiliate",
  components: {
    MenuSocials
  },
  props: {
    company: Object,
    menuSelected: {
      dashboard: false,
      cardTransactions: false,
      invoices: false,
      workingPoints: false,
      pos: false,
      statistics: false,
      stickers: false,
      account: false,
      contact: false,
      terms: false,
      gdpr: false,
      affiliateSearch: false,
      additionalDocuments: false,
      offers: false
    },
    user: Object
  },
  methods: {
    logout() {
      Cookies.remove("userLink");
      Cookies.remove("token");
      Cookies.remove("selectedCompany");

      this.$router.push({
        name: "login",
        params: { loginUserSelected: false }
      });
    },
    removeSelectedCompany(){
      Cookies.remove("selectedCompany");
    },
    goBack() {
      window.history.length > 1 ? this.$router.go(-1) : this.$router.push("/");
    },
  },
  mounted() {
    if (this.menuSelected.companySearch) {
      this.company.companyId = "0";
    }
  }
};
</script>
<style scoped src="@/assets/styles/beneficiary.css"></style>
