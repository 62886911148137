import "vue-form-generator/dist/vfg-core.css";
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue-3/dist/bootstrap-vue-3.css'
import "bootstrap/dist/js/bootstrap.js";
import 'vue3-carousel/dist/carousel.css'
import "./assets/styles/fonts.css";
import { createApp } from "vue";
import router from "./router.js";
import store from "./store.js";

// import VueGtm from "vue-gtm";
import VueAnalytics from "vue-analytics";
// import qs from 'qs'
import axios from "axios"
import VueAxios from 'vue-axios'
import { BootstrapVue3 } from 'bootstrap-vue-3'

import Cookies from "js-cookie";
// import auth from "@/auth";


import i18n from './plugins/language/i18n.js';

import { library } from "@fortawesome/fontawesome-svg-core";
import {
	faInfoCircle,
	faCalendarAlt,
	faChevronLeft,
	faPlusCircle,
	faMinusCircle,
	faCheckCircle,
	faUserPlus,
	faUserMinus,
	faArrowAltCircleRight,
	faTrashAlt,
	faEnvelope,
	faPrint,
	faChevronDown,
	faUniversity,
	faEyeSlash,
	faEye
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

import Pagination from "./components/fragments/Pagination";

library.add(faInfoCircle);
library.add(faCalendarAlt);
library.add(faChevronLeft);
library.add(faPlusCircle);
library.add(faMinusCircle);
library.add(faCheckCircle);
library.add(faUserPlus);
library.add(faUserMinus);
library.add(faArrowAltCircleRight);
library.add(faTrashAlt);
library.add(faEnvelope);
library.add(faPrint);
library.add(faChevronDown);
library.add(faUniversity);
library.add(faEyeSlash);
library.add(faEye);

// require("./assets/styles/normalize.css");
require("./http/http-common.js");

import App from "./App.vue";
const app = createApp(App)

// app.mixin({
// 	methods: {
// 		titleCase(value){
// 			if (!value) return "";
// 			value = value
// 				.toLowerCase()
// 				.replace("-", " ")
// 				.split(" ");
// 			for (var i = 0; i < value.length; i++) {
// 				value[i] = value[i].charAt(0).toUpperCase() + value[i].slice(1);
// 			}
// 			return value.join(" ");
// 		},
		
// 	},
// });

if (!FileReader.prototype.readAsBinaryString) {
	FileReader.prototype.readAsBinaryString = function (fileData) {
		var binary = "";
		var pt = this;
		var reader = new FileReader();
		reader.onload = function () {
			var bytes = new Uint8Array(reader.result);
			var length = bytes.byteLength;
			for (var i = 0; i < length; i++) {
				binary += String.fromCharCode(bytes[i]);
			}
			//pt.result  - readonly so assign binary
			pt.content = binary;
			pt.onload();
		};
		reader.readAsArrayBuffer(fileData);
	};
}

import JsonExcel from "vue-json-excel3";
import { VueReCaptcha } from 'vue-recaptcha-v3'

	app.use(router)
	.use(store)
	.use(VueAxios, axios)
	.use(BootstrapVue3)
	.use(i18n())
	.use(VueReCaptcha, { siteKey: '6LffKn0pAAAAADaPhfkz5ZWy-2sIMkui0KmSqjEJ' })
	.component("font-awesome-icon", FontAwesomeIcon)
	.component("listPagination", Pagination)
	.component("downloadExcel", JsonExcel)
	.mount('#app');


// new Vue({
// 	delimiters: ["[[", "]]"],
// 	router,
// 	store,
// 	i18n,
// 	render: (h) => h(App),
// 	mounted() {
// 		const isProd = process.env.NODE_ENV === "production";
// 		if (Cookies.get("cookies_analytics") !== null && isProd) {
// 			Vue.use(VueGtm, {
// 				id: "GTM-N2W59PJ", // Your GTM single container ID or array of container ids ['GTM-xxxxxxx', 'GTM-yyyyyyy']
// 				// queryParams: {
// 				// 	// Add url query string when load gtm.js with GTM ID (optional)
// 				// 	gtm_auth: "AB7cDEf3GHIjkl-MnOP8qr",
// 				// 	gtm_preview: "env-4",
// 				// 	gtm_cookies_win: "x"
// 				// },
// 				enabled: true, // defaults to true. Plugin can be disabled by setting this to false for Ex: enabled: !!GDPR_Cookie (optional)
// 				debug: true, // Whether or not display console logs debugs (optional)
// 				loadScript: true, // Whether or not to load the GTM Script (Helpful if you are including GTM manually, but need the dataLayer functionality in your components) (optional)
// 				vueRouter: router, // Pass the router instance to automatically sync with router (optional)
// 				// ignoredViews: ["homepage"] // If router, you can exclude some routes name (case insensitive) (optional)
// 			});
// 			Vue.use(VueAnalytics, {
// 				id: "UA-59971354-2",
// 				router,
// 				checkDuplicatedScript: true,
// 				enabled: (typeof process.env.VUE_APP_ENABLE_GOOGLE_ANALITICS != 'undefined') ? parseInt(process.env.VUE_APP_ENABLE_GOOGLE_ANALITICS) : 1,
// 				debug: {
// 					enabled: false, // default value
// 					trace: false, // default value
// 					sendHitTask: true, // default value
// 				},
// 			});
// 		}
// 	},
// }).$mount("#app");
