<template>
  <div>
    <!-- <div class="language-div">
      <LanguageBar />
    </div> -->

    <div class="loginWrapper">
      <div class="containerSingleBox">
        <!--PAN and code box -->
        <div class="loginBox background-white">
          <div class="logo">
            <img src="../../assets/images/up.svg" />
            <div v-show="!registrationDataConfirmed">
              <p
                v-show="
                  !cardDataSubmitted &&
                  !activationCode &&
                  !this.showNotifications
                "
              >
                {{ $t("title.selectCardTitle") }}
              </p>
              <p
                v-show="
                  cardDataSubmitted && !activationCode && !this.showPasswordForm
                "
              >
                {{ $t("title.registrationCheckDataTitle") }}
              </p>
              <p v-show="cardDataSubmitted && this.showPasswordForm">
                {{ $t("title.setPasswordTitle") }}
              </p>
              <p class="loginError" v-if="this.registrationError !== null">
                {{ this.config.errors[this.registrationError.error_code] }}
              </p>
              <p v-show="this.showNotifications">
                {{ $t("title.notificationsTitle") }}
              </p>
            </div>
          </div>

          <div
            id="select_card"
            v-if="
              !cardDataSubmitted &&
              !this.showNotifications &&
              !this.activationCode
            "
          >
            <div class="selectCard">
              <div class="arrow-left" @click="previousCard()">
                <img src="../../assets/images/left.png" />
              </div>
              <div class="arrow-right" @click="nextCard()">
                <img src="../../assets/images/right.png" />
              </div>
              <div class="dots">
                <div
                  v-for="(productCard, index) in productCardsData"
                  :key="index"
                  class="dot"
                  @click="selectCard(index)"
                  :class="index === selectedCardIndex && 'active-dot'"
                ></div>
              </div>
              <div class="card1" v-if="selectedCard">
                <img :src="selectedCard.image" />
                <p>{{ this.formatPAN(selectedCard.pan) }}</p>
                <pre
                  >{{ selectedCard.label1 }}
                  {{ selectedCard.label2 }}
                </pre>
              </div>
            </div>
            <br />
            <div class="loginForm">
              <form @submit.prevent="handleSubmit">
                <input
                  type="text"
                  :placeholder="[[$t('title.cardNumberFieldLabel')]]"
                  id="input-card-number"
                  v-model="registrationFormData.cardNumber"
                  required
                />
                <input
                  type="text"
                  :placeholder="[[$t('title.registrationCodeFieldLabel')]]"
                  id="input-code"
                  v-model="registrationFormData.code"
                  required
                />

                <input
                  type="submit"
                  :value="[[$t('title.confirmButtonLabel')]]"
                  variant="primary"
                  class="submitCompanies"
                />
              </form>
            </div>
          </div>
          <!--End PAN and code box -->
          <!-- Begin beneficiary details box -->

          <div
            class="loginForm"
            v-if="cardDataSubmitted && !registrationDataConfirmed"
          >
            <form @submit.prevent="submitBeneficiaryDetails">
              <div v-if="!showPasswordForm">
                <div v-if="this.activationCode">
                  <p
                    v-if="beneficiaryCardData.active"
                    v-html="
                      $t('title.flexAccountAlreadyCreated', {
                        email: beneficiaryCardData.email,
                      })
                    "
                  ></p>
                  <p v-else v-html="$t('title.flexAccountNew')"></p>
                </div>
                <input
                  type="text"
                  :placeholder="[[$t('title.surnameFieldLabel')]]"
                  id="input-first-name"
                  v-model="beneficiaryCardData.first_name"
                  :disabled="this.beneficiaryCardData.active"
                  required
                />
                <br />
                <input
                  type="text"
                  :placeholder="[[$t('title.lastNameField')]]"
                  id="input-last-name"
                  v-model="beneficiaryCardData.last_name"
                  :disabled="this.beneficiaryCardData.active"
                  required
                />
                <br />
                <input
                  type="text"
                  :placeholder="[[$t('title.emailField')]]"
                  id="input-email"
                  v-model="beneficiaryCardData.email"
                  :style="validateEmailClass"
                  :disabled="this.beneficiaryCardData.active"
                  required
                />
                <br />

                <div class="form-check">
                  <input
                    type="checkbox"
                    id="terms"
                    v-model="terms"
                    class="form-check-input"
                    required
                  />
                  <label for="terms">
                    {{ $t("title.textReadAndAgree") }}
                    <b
                      ><a @click="showModalPopup(true, 'terms')" href="#">{{
                        $t("title.termsAndConditionsName")
                      }}</a></b
                    >
                    <span v-if="this.activationCode">{{
                      $t("title.textReadAndAgreeAnd")
                    }}</span>
                    <b
                      ><a
                        v-if="this.activationCode"
                        @click="showModalPopup(true, 'terms_multiben')"
                        href="#"
                        >{{ $t("title.termsAndConditionsMultibenName") }}</a
                      ></b
                    >
                  </label>
                </div>

                <div class="form-check">
                  <input
                    type="checkbox"
                    id="gdpr"
                    v-model="gdpr"
                    class="form-check-input"
                    required
                  />
                  <!-- <label for="gdpr">
                    {{ $t("title.textReadAndAgree") }}
                    <b
                      ><a @click="showModalPopup(true, 'gdpr')" href="#">{{
                        $t("title.gdprName")
                      }}</a></b
                    >
                    <span v-if="this.activationCode">{{
                      $t("title.textReadAndAgreeAnd")
                    }}</span>
                    <b
                      ><a
                        v-if="this.activationCode"
                        @click="showModalPopup(true, 'gdpr_multiben')"
                        href="#"
                        >{{ $t("title.gdprMultibenName") }}</a
                      ></b
                    >
                  </label> -->
                </div>
                <input
                  type="button"
                  :value="[[$t('title.confirmButtonLabel')]]"
                  variant="primary"
                  class="submitCompanies"
                  @click="showPasswordFields"
                />
              </div>
              <div v-if="showPasswordForm">
                <div class="password">
                  <input   :placeholder="[[$t('title.passwordField')]]"  v-model="beneficiaryCardData.password"
                  @input="validatePassword" required
                    :type="showNewPassword ? 'text' : 'password'"
                      />
                    <i v-on:click="showNewPassword = !showNewPassword">
                      <font-awesome-icon :icon="showNewPassword ? 'eye-slash' : 'eye'"
                        />

                    </i>
                </div>
                <div class="password">
                  <input    :placeholder="[[$t('title.confirmPasswordField')]]" required
                  :style="confirmPasswordStyle" @input="validatePassword" v-model="confirmPassword"
                    :type="showConfirmPassword ? 'text' : 'password'"
                      />
                    <i v-on:click="showConfirmPassword = !showConfirmPassword">
                      <font-awesome-icon :icon="showConfirmPassword ? 'eye-slash' : 'eye'"
                        />

                    </i>
                </div>
                <div class="passwordConditions">
                  <p>
                    <img
                      v-if="!isIE"
                      :src="
                        this.validPassword.passwordLengthValid
                          ? require('../../assets/images/yes.png')
                          : require('../../assets/images/no.png')
                      "
                    />
                    &nbsp; Minimum 8 characters
                  </p>
                  <p>
                    <img
                      v-if="!isIE"
                      :src="
                        this.validPassword.containsLetter
                          ? require('../../assets/images/yes.png')
                          : require('../../assets/images/no.png')
                      "
                    />
                    &nbsp; At least one letter character
                  </p>
                  <p>
                    <img
                      v-if="!isIE"
                      :src="
                        this.validPassword.containsNumber
                          ? require('../../assets/images/yes.png')
                          : require('../../assets/images/no.png')
                      "
                    />
                    &nbsp; At least one numeric digit
                  </p>
                </div>
                <br />
                <br />
                <input
                  type="submit"
                  :value="[[$t('title.confirmButtonLabel')]]"
                  variant="primary"
                  class="submitCompanies"
                />
              </div>
            </form>
          </div>
          <div id="modal_popup_terms" class="modal-popup">
            <div class="modal-popup-content">
              <a @click="showModalPopup(false)" class="close-button">
                <img src="../../assets/images/close-button.svg" />
              </a>
              <p>
                <iframe :src="this.docSrc"></iframe>
              </p>
            </div>
          </div>
          <!--End Email box -->
          <!-- Begin congrats registration -->
          <div
            class="loginForm"
            v-if="
              this.registrationDataConfirmed &&
              !this.showNotifications &&
              !notificationsSubmitted
            "
          >
            <form @submit.prevent="completeRegistration" align="center">
              <img class="margin20" src="../../assets/images/congrats.png" />

              <h2 align="center">{{ $t("title.successMessageTitle") }}</h2>
              <p align="center" class="lightGrey" v-if="!this.activationCode">
                {{ $t("title.registrationSuccessMessage") }}
                <br />
                <strong>{{ this.beneficiaryCardData.email }}</strong>
              </p>
              <p align="center" class="lightGrey" v-else>
                {{ $t("title.flexAccountSuccessMessage") }}
                <br />
                <strong>{{ this.beneficiaryCardData.email }}</strong>
              </p>
              <input type="submit" value="CONFIRM" class="submitCompanies" />
            </form>
          </div>
          <!-- End congrats registration-->
          <!-- Begin notifications -->
          <div class="loginForm" v-if="this.showNotifications">
            <form @submit.prevent="confirmNotifications" align="center">
              <!-- <div class="notificare">
                <div>
                  <img src="../../assets/images/notificare-portocaliu.svg" />
                </div>
                <div>{{ $t('title.notificationsTransactionalLabel')}}</div>
                <div>
                  <div class="custom-control custom-switch">
                    <input
                      type="checkbox"
                      id="customSwitchTN"
                      class="custom-control-input"
                      v-model="this.notifications[2].active"
                    />
                    <label class="custom-control-label" for="customSwitchTN"></label>
                  </div>
                </div>
              </div>-->
              <br />
              <div>
                <p>
                  <strong>{{ $t("title.notificationsMarketingTitle") }}</strong>
                </p>
              </div>

              <div class="notificare">
                <div>
                  <img src="../../assets/images/notificare-portocaliu.svg" />
                </div>
                <div>{{ $t("title.notificationsLabel") }}</div>
                <div>
                  <div class="custom-control custom-switch">
                    <input
                      type="checkbox"
                      class="custom-control-input"
                      id="customSwitchN"
                      v-model="this.notifications[0].active"
                    />
                    <label
                      class="custom-control-label"
                      for="customSwitchN"
                    ></label>
                  </div>
                </div>
              </div>
              <div class="notificare">
                <div>
                  <img src="../../assets/images/notificare-portocaliu.svg" />
                </div>
                <div>{{ $t("title.notificationsSMSLabel") }}</div>
                <div>
                  <div class="custom-control custom-switch">
                    <input
                      type="checkbox"
                      class="custom-control-input"
                      id="customSwitchSN"
                      v-model="this.notifications[1].active"
                    />
                    <label
                      class="custom-control-label"
                      for="customSwitchSN"
                    ></label>
                  </div>
                </div>
              </div>
              <div class="notificare">
                <div>
                  <img src="../../assets/images/notificare-portocaliu.svg" />
                </div>
                <div>{{ $t("title.notificationsEmailLabel") }}</div>
                <div>
                  <div class="custom-control custom-switch">
                    <input
                      type="checkbox"
                      class="custom-control-input"
                      id="customSwitchEN"
                      v-model="this.notifications[3].active"
                    />
                    <label
                      class="custom-control-label"
                      for="customSwitchEN"
                    ></label>
                  </div>
                </div>
              </div>
              <br />
              <br />

              <input
                type="submit"
                :value="[[$t('title.confirmButtonLabel')]]"
                class="submitCompanies"
              />
            </form>
          </div>
          <!-- End notifications-->
          <!-- Begin congrats notifications -->
          <div class="loginForm" v-if="notificationsSubmitted">
            <form
              @submit.prevent="completeRegistrationWithNotifications"
              align="center"
            >
              <img class="margin20" src="../../assets/images/congrats.png" />

              <h2 align="center">{{ $t("title.successMessageTitle") }}</h2>
              <p align="center" class="lightGrey">
                {{ $t("title.notificationsCongrats") }}
              </p>
              <input
                type="submit"
                :value="[[$t('title.confirmButtonLabel')]]"
                class="submitCompanies"
              />
            </form>
          </div>
          <!-- End congrats registration-->
        </div>
      </div>
    </div>
  </div>
</template>
<!-- <script src="https://unpkg.com/vue-recaptcha@^1/dist/vue-recaptcha.min.js"></script> -->
<script>
import httpService from "../../http/http-common.js";
import httpServiceBeneficiary from "../../http/http-beneficiary.js";
import validator from "../../plugins/fieldValidation";
import utils from "@/utils.js";
import Cookies from "js-cookie";
// import LanguageBar from "@/components/LanguageBar.vue";
import VueRecaptcha from "vue-recaptcha-v3";

export default {
  setup() {
    const { formatPAN } = utils();

    return {
      formatPAN,
    }
  },
  components: {  
    // LanguageBar, 
    VueRecaptcha },
  name: "create_account",
  props: {
    msg: String,
  },
  data() {
    return {
      registrationFormData: {
        cardNumber: "",
        code: "",
      },
      token: "",
      confirmPassword: "",
      config: null,
      productCardsData: [],
      selectedCardIndex: 0,
      showPasswordForm: false,
      showNewPassword: false,
      showConfirmPassword: false,
      beneficiaryCardData: null,
      notifications: [
        {
          //tipurile de notificari
          id: 1,
          type: "NOTIFICATION", // tranzactionale
          active: false,
        },
        {
          id: 2,
          type: "GDPR_SMS",
          active: false,
        },
        {
          id: 4,
          type: "GDPR_PSH",
          active: true,
        },
        {
          id: 8,
          type: "GDPR_EML",
          active: false,
        },
      ],
      url: null,
      registrationError: null,
      validPassword: {
        passwordValid: false,
        passwordLengthValid: false,
        containsLetter: false,
        containsNumber: false,
        confirmPasswordValid: false,
      },
      activationCode: "",
      notificationsSubmitted: false,
      showNotifications: false,
      terms: false,
      gdpr: false,
      docSrc: "",
      isIE: false,
      recaptchaToken: null,
      recaptchaKey: "6Lc4RJ8kAAAAADYYxYgaoCCPXbEg7857BOI-BzpO",
    };
  },
  methods: {
    handleSubmit() {
      this.$refs.recaptcha.execute("recaptcha");
    },
    submitCardNumber() {
      httpService.beneficiaryService
        .userCheckForRegistration(this.registrationFormData, this.token, this.recaptchaToken)
        .then(function (response) {
          return response;
        })
        .catch((error) => {
          return error.response;
        })
        .then((response) => {
          if (response.status == 200) {
            this.beneficiaryCardData = response.data;
            this.beneficiaryCardData.password = "";
            this.registrationError = null;
            this.showPasswordForm = false;
          } else if (response.status == 400) {
            this.beneficiaryCardData = null;
            this.registrationError = response.data;
            this.showPasswordForm = false;
          } else {
            this.beneficiaryCardData = null;
            this.registrationError = { error_code: response.code };
            this.showPasswordForm = false;
          }
        });
    },
    getBeneficiaryInfosForActivation() {
      httpService.beneficiaryService
        .userGetActivationInfos("hash=" + this.activationCode, this.token)
        .then(function (response) {
          return response;
        })
        .catch((error) => {
          return error.response;
        })

        // email : userlogin actual
        // code : email-ul pe care a venit invitatia
        // active: true / false; daca e true nu mai cer parola

        .then((response) => {
          if (response.status == 200) {
            this.beneficiaryCardData = response.data;
            this.beneficiaryCardData.password = "";
            this.registrationError = null;
            this.showPasswordForm = false;
          } else if (response.status == 400) {
            this.beneficiaryCardData = null;
            this.registrationError = response.data;
            this.showPasswordForm = false;
          } else {
            this.beneficiaryCardData = null;
            this.registrationError = { error_code: response.code };
            this.showPasswordForm = false;
          }
        });
    },
    showPasswordFields() {
      this.registrationError = null;
      if (
        !this.terms ||
        this.terms === false 
        // ||
        // !this.gdpr ||
        // this.gdpr === false
      ) {
        this.registrationError = {
          description: "Terms",
          error_code: "TERMS",
        };
        return;
      }
      if (this.activationCode && this.beneficiaryCardData.active) {
        this.submitBeneficiaryDetails();
      } else {
        if (validator.validateEmail(this.beneficiaryCardData.email)) {
          // if the email address is correct
          this.showPasswordForm = true;
        } else {
          this.registrationError = {
            description: "Invalid email",
            error_code: "LS04",
          };
        }
      }
    },
    submitBeneficiaryDetails() {
      if (!this.validPassword.passwordValid && !this.activationCode) {
        this.registrationError = {
          description: "Invalid password",
          error_code: "LS03",
        };
        return;
      } else {
        if (this.activationCode) {
          this.beneficiaryCardData.product = 30;
          if (this.beneficiaryCardData.active) {
            this.beneficiaryCardData.password = null;
          }
        }
        this.beneficiaryCardData.active = true;
        httpService.beneficiaryService
          .confirmBeneficaryData(this.beneficiaryCardData, this.token)
          .then(function (response) {
            // handle success
            return response;
          })
          .catch((error) => {
            // handle error
            return error.response;
          })
          .then((response) => {
            if (response.status == 200) {
              this.beneficiaryCardData = response.data;
              this.registrationError = null;
              this.showPasswordForm = false;
              this.activationCode = "SUCCESS";

              !cardDataSubmitted && activationCode && !this.showNotifications;

              if (this.activationCode) {
                return;
              }

              let formData = {
                username: this.beneficiaryCardData.email,
                password: this.confirmPassword,
              };

              httpService.beneficiaryService
                .userLogin(formData, this.recaptchaToken)
                .then(function (response) {
                  // handle success
                  return response;
                })
                .catch((error) => {
                  // handle error
                  return error.response;
                })
                .then((response) => {
                  if (response.status == 200) {
                    let beneficiaryLink =
                      response.headers["link"].split(";")[0];
                    beneficiaryLink = beneficiaryLink.replace(">", "");
                    beneficiaryLink = beneficiaryLink.replace("<", "");
                    this.url = beneficiaryLink;
                    this.token = response.data.access_token;

                    Cookies.set(
                      "beneficiaryLink",
                      beneficiaryLink,
                      response.data.expires_in,
                      {
                        sameSite: "Strict",
                      }
                    );
                    Cookies.set(
                      "token",
                      response.data.access_token,
                      response.data.expires_in,
                      {
                        sameSite: "Strict",
                      }
                    );
                  } else if (response.status == 400 || response.status == 403) {
                    this.loginError = {
                      code: response.data,
                      description: "Invalid login",
                    };
                  } else {
                    this.loginError = { description: response.description };
                  }
                });

            } else if (response.status == 400) {
              this.beneficiaryCardData = null;
              this.registrationError = response.data;
            } else {
              this.beneficiaryCardData = null;
              this.registrationError = { error_code: response.code };
            }
          });
      }
    },
    confirmNotifications() {
      this.beneficiaryCardData.notifications = this.notifications;
      httpServiceBeneficiary.beneficiaryService
        .confirmBeneficaryData(
          this.url,
          {
            id: this.beneficiaryCardData.id,
            notifications: this.notifications,
          },
          this.token
        )
        .then(function (response) {
          // handle success
          return response;
        })
        .catch((error) => {
          // handle error
          return error.response;
        })
        .then((response) => {
          if (response.status == 200) {
            this.beneficiaryCardData = response.data;
            this.registrationError = null;
            this.showPasswordForm = false;
            this.showNotifications = false;
            this.notificationsSubmitted = true;
          } else if (response.status == 400) {
            this.beneficiaryCardData = null;
            this.registrationError = response.data;
          } else {
            this.beneficiaryCardData = null;
            this.registrationError = { error_code: response.code };
          }
        });
    },
    completeRegistrationWithNotifications() {
      this.$router.push({
        name: "beneficiary",
      });
    },
    validatePassword() {
      this.validPassword = validator.validatePassword(
        this.beneficiaryCardData.password,
        this.confirmPassword
      );
    },
    previousCard() {
      var noOfCards = Object.keys(this.productCardsData).length;
      this.selectedCardIndex--;
      if (this.selectedCardIndex < 0) {
        this.selectedCardIndex = noOfCards - 1;
      }
    },
    selectCard(index) {
      this.selectedCardIndex = index;
    },
    nextCard() {
      var noOfCards = Object.keys(this.productCardsData).length;
      this.selectedCardIndex++;
      if (this.selectedCardIndex === noOfCards) {
        this.selectedCardIndex = 0;
      }
    },
    completeRegistration() {
      this.registrationFormData = null;
      this.confirmPassword = "";
      this.selectedCardIndex = 0;
      this.showPasswordForm = false;
      this.registrationError = null;
      this.validPassword = null;
      this.showNotifications = true;

      if (this.activationCode) {
        this.$router.push({
          name: "login",
          params: { loginUserSelected: true },
        });
      }
    },
    showModalPopup(show, documentKey) {
      if (show) {
        this.docSrc = this.config[documentKey];
        document.getElementById("modal_popup_terms").style.visibility =
          "visible";
      } else {
        document.getElementById("modal_popup_terms").style.visibility =
          "hidden";
      }
    },
    loadConfigData() {
      this.config = JSON.parse(sessionStorage.getItem("mobile_jsonFile"));

      var query = window.location.pathname; // Remove question mark
      var parameters = query.split("/");
      this.activationCode = parameters[parameters.length - 1];

      this.productCardsData = this.config.cards;
      this.selectedCardIndex = 0;
      httpService.beneficiaryService
        .requestTokenForRegistration("webopen")
        .then(function (response) {
          // handle success
          return response.data;
        })
        .then((data) => {
          this.token = data.access_token;
          if (this.activationCode !== "create_account") {
            this.getBeneficiaryInfosForActivation();
          } else {
            this.activationCode = null;
          }
        })
        .catch((error) => {
          // handle error
        });
    },
    onCaptchaExpired(recaptcha) {
      this.$refs.recaptcha.reset();
      this.recaptchaToken = false;
    },
    getRecaptchaRP(recaptchaToken) {
      this.recaptchaToken = recaptchaToken;
      this.submitCardNumber()
      this.$refs.recaptcha.reset();

    },
  },
  mounted() {
    this.isIE = /*@cc_on!@*/ false || !!document.documentMode;

    if (!sessionStorage.getItem("mobile_jsonFile")) {
      if (localStorage.language === undefined) {
        localStorage.language = "ro";
      }
      httpService.commonsService
        .getConfigFile(localStorage.language, "mobile")
        .then(function (response) {
          return response;
        })
        .then((data) => {
          this.loadConfigData();
        });
    } else {
      this.loadConfigData();
    }
  },
  computed: {
    selectedCard() {
      return this.productCardsData[this.selectedCardIndex];
    },
    cardDataSubmitted() {
      return this.beneficiaryCardData !== null;
    },
    registrationDataConfirmed() {
      return (
        this.beneficiaryCardData !== null &&
        this.beneficiaryCardData.active &&
        this.activationCode === "SUCCESS"
      );
    },
    confirmPasswordStyle() {
      return this.beneficiaryCardData.password === this.confirmPassword
        ? ""
        : "outline-color: #ff0000a6; ";
    },
    validateEmailClass() {
      return this.beneficiaryCardData.email === ""
        ? ""
        : validator.validateEmail(this.beneficiaryCardData.email)
        ? ""
        : "outline-color: #ff0000a6; ";
    },
  },
};
</script>
<style scoped src="@/assets/styles/login.css"></style>
