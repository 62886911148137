<template>
  <div class="loginWrapper">
    <div class="containerSingleBox">
      <!--Login box -->
      <div class="loginBox background-white">
        <div class="logo">
          <img src="../../assets/images/up.svg" />
          <br />
          <br />
          <h2 v-if="!this.resetComplete">{{ $t("title.forgotPasswordTitle") }}</h2>
          <p class="lightGrey padding-20" v-if="(this.newPasswordData == null) & !this.resetComplete"
            v-html="$t('title.forgotPasswordMessage')"></p>
          <p class="loginError" v-if="this.registrationError !== null" v-html="this.getErrorMessage()" >
          </p>
        </div>

        <div class="loginForm">
          <form @submit.prevent="confirmPasswordReset">
            <div v-if="
              (this.newPasswordData == null) & !this.resetComplete
            ">
              <input type="text" :placeholder="[[$t('title.usernameField')]]" id="input-email" v-model="formData.username"
                :style="validateEmailClass" required />
              <!-- <input type="text" :placeholder="[[$t('title.registrationCodeFieldLabel')]]" id="input-passs-token"
                v-model="formData.pass_token" required /> -->
              <input type="button" :value="[[$t('title.confirmButtonLabel')]]" variant="primary" class="submitCompanies"
                @click="validateUserAccount" />
            </div>
            <div v-if="
              (this.newPasswordData != null) & !this.resetComplete
            ">
              <input type="text" :placeholder="[[$t('title.otpField')]]" id="input-otp" v-model="otp_password"
                :style="validateEmailClass" required />
              <p class="text-right">
                <a href="#" class="link" @click="resendOtp()">{{
                  $t("title.otpResendText")
                }}</a>
              </p>
              <p v-if="otpResendSuccess" class="lightGrey text-center">
                {{ $t("title.msgEmailSuccess") }}
              </p>
              <div class="password">
                  <input   :placeholder="[[$t('title.passwordField')]]" v-model="newPasswordData.new_password"
                  @input="validatePassword" required
                    :type="showNewPassword ? 'text' : 'password'"
                      />
                    <i v-on:click="showNewPassword = !showNewPassword">
                      <font-awesome-icon :icon="showNewPassword ? 'eye-slash' : 'eye'"
                        />

                    </i>
                </div>
                <div class="password">
                  <input    :placeholder="[[$t('title.confirmPasswordField')]]" required
                  :style="confirmPasswordStyle" @input="validatePassword" v-model="confirmPassword"
                    :type="showConfirmPassword ? 'text' : 'password'"
                      />
                    <i v-on:click="showConfirmPassword = !showConfirmPassword">
                      <font-awesome-icon :icon="showConfirmPassword ? 'eye-slash' : 'eye'"
                        />

                    </i>
                </div>
              <div class="passwordConditions">
                <p>
                  <img v-if="!isIE" :src="
                    this.validPassword.passwordLengthValid
                      ? require('../../assets/images/yes.png')
                      : require('../../assets/images/no.png')
                  " />
                  &nbsp; Minimum 8 characters
                </p>
                <p>
                  <img v-if="!isIE" :src="
                    this.validPassword.containsLetter
                      ? require('../../assets/images/yes.png')
                      : require('../../assets/images/no.png')
                  " />&nbsp; At least one letter character
                </p>
                <p>
                  <img v-if="!isIE" :src="
                    this.validPassword.containsNumber
                      ? require('../../assets/images/yes.png')
                      : require('../../assets/images/no.png')
                  " />&nbsp; At least one numeric digit
                </p>
              </div>
              <br />
              <br />
              <input type="submit" :value="[[$t('title.confirmButtonLabel')]]" variant="primary" class="submitCompanies" />
            </div>
            <!-- Begin congrats -->
            <div class="loginForm" v-if="this.resetComplete">
              <form @submit.prevent="completePasswordReset" align="center">
                <img src="../../assets/images/congrats.png" />

                <h2 align="center">{{ $t("title.successMessageTitle") }}</h2>
                <p align="center" class="lightGrey">
                  {{ $t("title.resetPasswordSuccessMessage") }}
                </p>
                <input type="submit" value="CONFIRM" class="submitCompanies" />
              </form>
            </div>
            <!-- End congrats -->
          </form>
        </div>
      </div>
      <!--End Login box -->
    </div>
  </div>
</template>

<script>
import validator from "../../plugins/fieldValidation";
import httpService from "../../http/http-common.js";
import $ from "jquery";

export default {
  name: "forgot_password",
  props: {
    msg: String,
  },
  data() {
    return {
      formData: {
        username: "",
        pass_token: "",
      },
      token: "",
      registrationError: null,
      newPasswordData: null,
      confirmPassword: "",
      showNewPassword: false,
      showConfirmPassword: false,
      validPassword: {
        passwordValid: false,
        passwordLengthValid: false,
        containsLetter: false,
        containsNumber: false,
        confirmPasswordValid: false,
      },
      resetComplete: false,
      isIE: false,
      config: null,
      otp_password: null,
      otp_resend: false, //
      otpResendSuccess: false,
    };
  },
  methods: {
    validateUserAccount() {
      if (validator.validateEmail(this.formData.username) || validator.validateMobilePhone(this.formData.username)) {
        // console.log("validate account");
        // if the email address is correct
        httpService.beneficiaryService
          .beneficiaryCheckForPasswordReset(this.formData, this.token)
          .then(function (response) {
            return response;
          })
          .catch((error) => {
            return error.response;
          })
          .then((response) => {
            if (response.status == 200) {
              this.newPasswordData = response.data;
              this.registrationError = null;
            } else if (response.status == 400 || response.status == 403) {
              this.newPasswordData = null;
              this.registrationError = {
                code: response.data.error_code,
                description: response.data.error_params ? response.data.error_params : response.data.description
              };
            } else {
              this.newPasswordData = null;
              this.registrationError = { description: response.code };
            }
          });
      } else {
        this.registrationError = {
          description: "Invalid email",
          code: "LS04",
        };
      }
    },

    resendOtp() {
      let otpData = {
        ...this.newPasswordData,
        otp_resend: true,
      };
      this.otpResendSuccess = false;

      httpService.beneficiaryService
        .validateOtp(otpData, this.token)
        .then((response) => {
          if (response.status == 200) {
            this.otpResendSuccess = true;
          } else if (response.status == 400 || response.status == 403) {
            this.registrationError = {
              code: response.data.error_code,
              description: response.data.description,
            };
          } else {
            this.registrationError = { description: response.code };
          }
        })
        .catch((error) => {
          return error.response;
        });
    },
    validatePassword() {
      this.validPassword = validator.validatePassword(
        this.newPasswordData.new_password,
        this.confirmPassword
      );
    },
    confirmPasswordReset() {
      if (!this.validPassword.passwordValid) {
        this.registrationError = {
          description: "Invalid password",
          code: "LS03",
        };
        return;
      } else {
        let otpData = {
          ...this.newPasswordData,
          otp_password: this.otp_password,
        };
        httpService.beneficiaryService
          .resetBeneficiaryPassword(otpData, this.token)
          .then(function (response) {
            // handle success
            return response;
          })
          .catch((error) => {
            // handle error
            this.registrationError = error.response.data;
            return error.response;
          })
          .then((response) => {
            if (response.status == 200) {
              this.registrationError = null;
              this.resetComplete = true;
              // do congrats
            } else if (response.status == 400) {
              this.registrationError = {
              code: response.data.error_code,
              description: response.data.description,
            };
            } else {
              this.registrationError = { description: response.code };
            }
          });
      }
    },
    completePasswordReset() {
      this.newPasswordData = null;
      this.token = "";
      this.confirmPassword = "";
      this.registrationError = null;
      this.validPassword = null;
      this.formData = null;
      this.resetComplete = false;

      this.$router.push({ name: "login", params: { loginUserSelected: true } });
    },
    checkLoginPage() {
      if (!$("body").hasClass("login-pages")) {
        $("body").addClass("login-pages");
      } else {
        $("body").removeClass("login-pages");
      }
    },
    getErrorMessage() {
      if (this.registrationError.description) {
        try {
          let description = this.registrationError.description;
          if (Array.isArray(description)) {
            if (description.length > 1) {
              return this.$t("title.errorMessage_" + this.registrationError.code, {
                param: description[0],
                param1: description[1]
              });
            } else {
              return this.$t("title.errorMessage_" + this.registrationError.code, {
                param: description[0]
              });
            }
          } else {
            return this.config.errors[this.registrationError.code]
          }
        } catch {
          return this.config.errors[this.registrationError.code]
        }
      } else {
        return this.config.errors[this.registrationError.code]
      }
    },
  },
  mounted() {
    if (!sessionStorage.getItem("mobile_jsonFile")) {
      httpService.commonsService
        .getConfigFile(localStorage.language, "mobile")
        .then(function (response) {
          return response;
        })
        .then((data) => {
          this.config = JSON.parse(
            sessionStorage.getItem("mobile_jsonFile")
          );
        });
    } else {
      this.config = JSON.parse(sessionStorage.getItem("mobile_jsonFile"));
    }
    this.isIE = /*@cc_on!@*/ false || !!document.documentMode;
    httpService.beneficiaryService
      .requestTokenForRegistration("webopen")
      .then(function (response) {
        // handle success
        return response.data;
      })
      .then((data) => {
        this.token = data.access_token;
      })
      .catch((error) => {
        // handle success
      });

    this.checkLoginPage();
  },
  computed: {
    confirmPasswordStyle() {
      return this.confirmPassword === ""
        ? ""
        : this.newPasswordData.new_password === this.confirmPassword
          ? ""
          : "outline-color: #ff0000a6;";
    },
    validateEmailClass() {
      return this.formData.username === ""
        ? ""
        : (validator.validateEmail(this.formData.username) || validator.validateMobilePhone(this.formData.username))
          ? ""
          : "outline-color: #ff0000a6;  ";
    },
  },
};
</script>
<style scoped src="@/assets/styles/login.css"></style>
<style scoped>
.padding-20 {
  padding: 20px;
}
</style>
