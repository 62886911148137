<template>
  <div>
    <div v-if="this.dataLoaded" >
      <div v-if="!isMobile" class="menu">
        <a href="/beneficiary" :class="{ logo: false }">
          <img  src="@/assets/images/logo-up.png" />
        </a>
        <div class="menu-items">
          <a href="/beneficiary/dashboard" class="dashboard" :class="this.menuSelected.dashboard && 'dashboard-active'">{{
            $t("title.dashboardName") }}</a>

          <a v-if="beneficiary !== null" href="/beneficiary/cards" class="cards"
            :class="this.menuSelected.cards && 'cards-active'">{{ $t("title.cardsName") }}</a>
          <a href="/beneficiary/services" class="services" :class="this.menuSelected.services && 'services-active'">{{
            $t("title.servicesName") }}</a>
          <a href="/beneficiary/faq" class="faq" :class="this.menuSelected.faq && 'faq-active'">{{ $t("title.faqName") }}</a>
          <a href="/beneficiary/account" class="account" :class="this.menuSelected.account && 'account-active'">{{
            $t("title.settingsName") }}</a>
          <a href="/beneficiary/contact" class="contact" :class="this.menuSelected.contact && 'contact-active'">{{
            $t("title.contactName") }}</a>
          <a href="/beneficiary/terms" class="term" :class="this.menuSelected.terms && 'term-active'">{{
            $t("title.termsAndConditionsName") }}</a>
          <!-- <a href="/beneficiary/gdpr" class="policy" :class="this.menuSelected.gdpr && 'policy-active'">{{
            $t("title.personalDataPolicyName") }}</a> -->
          <a href="#" class="logout" @click="logout()">{{ $t("title.logoutName") }}</a>
        </div>
        <MenuSocials />
      </div>

      <div v-if="isMobile" class="menu-mobile p-0 w-100" :class="{ 'open-menu': showMobileMenu }">
        <div class="user-mobile px-3 w-100 d-flex m-0 box-sizing-bb">
          <!-- <div class="openNotifications">
            <Notification :user="this.beneficiary" :isMobile="isMobile"></Notification>
          </div> -->
          <img src="../../assets/images/linie.png" />
          <div>{{ titleCase(beneficiary.first_name + ' ' + beneficiary.last_name)  }}</div>
          <div class="ml-auto close-menu" @click="openMenu(!showMobileMenu)">
            <img src="../../assets/images/x.svg" />
          </div>
        </div>
        <div class="menu-items box-sizing-bb px-3">
          <a href="/beneficiary/dashboard" class="dashboard" :class="this.menuSelected.dashboard && 'dashboard-active'">{{
            $t("title.dashboardName") }}</a>
          <a v-if="beneficiary !== null" href="/beneficiary/cards" class="cards"
            :class="this.menuSelected.cards && 'cards-active'">{{ $t("title.cardsName") }}</a>
          <a href="/beneficiary/services" class="services" :class="this.menuSelected.services && 'services-active'">{{
            $t("title.servicesName") }}</a>
          <a href="/beneficiary/faq" class="faq" :class="this.menuSelected.faq && 'faq-active'">{{ $t("title.faqName") }}</a>
          <a href="/beneficiary/account" class="account" :class="this.menuSelected.account && 'account-active'">{{
            $t("title.settingsName") }}</a>
          <a href="/beneficiary/contact" class="contact" :class="this.menuSelected.contact && 'contact-active'">{{
            $t("title.contactName") }}</a>
          <a href="/beneficiary/terms" class="term" :class="this.menuSelected.terms && 'term-active'">{{
            $t("title.termsAndConditionsName") }}</a>
          <!-- <a href="/beneficiary/gdpr" class="policy" :class="this.menuSelected.gdpr && 'policy-active'">{{
            $t("title.personalDataPolicyName") }}</a> -->
          <a href="#" class="logout" @click="logout()">{{ $t("title.logoutName") }}</a>
        </div>
        <MenuSocials />
      </div>

      <div class="container-right">
        <div class="barTop" :class="menuSelected.dashboard ? 'view' : 'bar-top-white'">
          <div class="logo-mobile" v-if="menuSelected.dashboard">
            <img src="../../assets/images/logo-up.png" />
          </div>
          <div class="menu-white" v-if="menuSelected.dashboard" @click="openMenu(true)">
            <img src="../../assets/images/menu-burger-alb.svg" />
          </div>

          <div class="mobile-nav d-flex border-bottom" v-if="!menuSelected.dashboard && isMobile">
            <div class="page-title mx-auto">
              <h3>{{ this.getPageTitle() }}</h3>
            </div>
            <div class="menu-white" @click="openMenu(true)">
              <img src="../../assets/images/menu-burger-gri.svg" />
            </div>
          </div>
          <div class="user float-right bg-white ml-auto">
            <!-- <Notification :user="this.beneficiary"></Notification>
            <div>
              <img src="@/assets/images/linie.png" />
            </div> -->
            <div class="openUserMenu" v-click-outside="hide" @click="openUserMenu">
              <img src="@/assets/images/settings.png" />
              <a href="#">{{ titleCase(this.beneficiary.first_name) }}
                {{ titleCase(this.beneficiary.last_name) }}</a>
              <img src="@/assets/images/arrow-down.svg" />
              <div class="userMenu" v-show="isOpen">
                <a href="/beneficiary/account" class="account" :class="this.menuSelected.account && 'account-active'">{{
                  $t("title.settingsName") }}</a>
                <a href="/beneficiary/faq" class="faq" :class="this.menuSelected.faq && 'faq-active'">{{ $t("title.faqName")
                }}</a>
                <a href="#" class="logout" @click="logout()">{{
                  $t("title.logoutName")
                }}</a>
              </div>
            </div>
            <div>
              <img src="@/assets/images/linie.png" />
            </div>
            <!-- <LanguageBar /> -->
          </div>
        </div>

        <!-- <div class="content"> -->
        <Dashboard :accounts="this.beneficiary.accounts" :surname="this.beneficiary.first_name" :accessToken="this.token"
          v-if="this.menuSelected.dashboard" />

        <Cards :accounts="this.beneficiary.accounts" :accessToken="this.token" v-if="this.menuSelected.cards" />
        <Services :accessToken="this.token" :beneficiaryCardData="this.beneficiary"
          :recommandPartnerLink="this.config.suggest" :accounts="this.beneficiary.accounts"
          v-if="this.menuSelected.services" />
        <LinkFrame :urlAddress="this.config.web_faq" :accessToken="this.token" v-if="this.menuSelected.faq" />
        <MyAccount :beneficiaryCardData="this.beneficiary" :accessToken="this.token" v-if="this.menuSelected.account" />
        <LinkFrame :urlAddress="this.config.contact" v-if="this.menuSelected.contact" />
        <LinkFrame :urlAddress="this.config.web_terms" v-if="this.menuSelected.terms" />
        <!-- <LinkFrame :urlAddress="this.config.web_gdpr" v-if="this.menuSelected.gdpr" /> -->
      </div>
    </div>
    <!-- <ModalPopupNPS :url="this.config.nps_url" :type="'nps_beneficiary'" /> -->
  </div>
</template>

<style scoped src="@/assets/styles/beneficiary.css"></style>
<style>
.box-sizing-bb {
  box-sizing: border-box !important;
}
</style>

<script>
import moment from "moment";
// import LanguageBar from "@/components/LanguageBar.vue";
import Dashboard from "@/components/beneficiary/Dashboard.vue";
import Cards from "@/components/beneficiary/Cards.vue";
import Services from "@/components/beneficiary/Services.vue";
import MyAccount from "@/components/beneficiary/MyAccount.vue";
import LinkFrame from "@/components/beneficiary/LinkFrame.vue";
// import NotificationsPage from "@/components/notification/Notifications.vue";
import httpServiceBeneficiary from "../../http/http-beneficiary.js";
import httpService from "../../http/http-common.js";
import Cookies from "js-cookie";
import ClickOutside from "vue-click-outside";
// import ModalPopupNPS from '@/components/fragments/ModalNps.vue';
import MenuSocials from "@/components/MenuSocials.vue";


 import utils from '@/utils.js'

export default {
  name: "Beneficiary",
  setup() {
    const { titleCase } = utils()
    return {
      titleCase,
    }
  },
  components: {
    Dashboard,
    Cards,
    Services,
    MyAccount,
    LinkFrame,
    // LanguageBar,
    MenuSocials
    // NotificationsBox,
    // NotificationsPage,
    // ModalPopupNPS
  },
  directives: {
    ClickOutside
  },
  data() {
    return {
      beneficiaryLink: "",
      token: "",
      beneficiary: null,
      dataLoaded: false,
      menuSelected: {
        dashboard: false,
        cards: false,
        services: false,
        faq: false,
        account: false,
        contact: false,
        terms: false,
        gdpr: false,
        notifications: false,
      },
      config: JSON.parse(sessionStorage.getItem("mobile_jsonFile")),
      isOpen: false,
      isMobile: (document.body.clientWidth < 1004),
      marketingMessage: true,
      showMobileMenu: false
    };
  },
  filters: {
    fromNow(date) {
      return moment(date).fromNow();
    }
  },
  methods: {
    loadBeneficiaryData() {
      if (Cookies.get("beneficiaryLink") === null) {
        console.error('No beneficiary Link set');
        return this.logout();
      }

      let beneficiaryLink = Cookies.get("beneficiaryLink");
      let token = Cookies.get("token");

      if (beneficiaryLink !== null && token !== null) {
        this.beneficiaryLink = beneficiaryLink;
        this.token = token;
      } else {
        console.error('Beneficiary link and token is null')
        return this.logout();
      }

      if (!this.beneficiaryLink || !this.token) {
        console.error('Beneficiary link and token is undefined')
        return this.logout();
      }

      httpServiceBeneficiary.beneficiaryService
        .getBeneficiaryData(this.beneficiaryLink, this.token)
        .then(function (response) {
          // handle success
          return response.data;
        })
        .then(data => {
          this.beneficiary = data;
          this.dataLoaded = true;
          this.$emit(
            "sendUserRole",
            "BEN",
            ""
          );
        })
        .catch(function (error) {
          console.log(error);
          this.logout();
        });
    },
    openMenu(open) {
      this.showMobileMenu = open;
    },
    openUserMenu() {
      this.isOpen = !this.isOpen;
    },
    hide() {
      this.isOpen = false;
    },
    getPageTitle() {
      if (this.menuSelected.cards) {
        return "Cards";
      } else if (this.menuSelected.services) {
        return "Services";
      } else if (this.menuSelected.faq) {
        return "FAQ";
      } else if (this.menuSelected.account) {
        return "My Account";
      } else if (this.menuSelected.contact) {
        return "Contact";
      } else if (this.menuSelected.terms) {
        return "Terms and conditions";
      // } else if (this.menuSelected.gdpr) {
      //   return "Personal data policy";
      } else if (this.menuSelected.notifications) {
        return "Notifications";
      } else {
        return "Dashboard";
      }
    },
    logout() {
      Cookies.remove("beneficiaryLink");
      Cookies.remove("token");
      sessionStorage.removeItem("chatOpened");
      this.$router.push({
        name: "login",
        params: { loginUserSelected: true }
      });
    },
    goBack() {
      window.history.length > 1 ? this.$router.go(-1) : this.$router.push("/");
    },
    getExpireAmountDateFormat(date) {
      return moment(new Date(date), "YYYY-MM-DD").format("DD MMM YYYY");
    },
    showModalPopup(elementId, show) {
      if (show) {
        document.getElementById(elementId).style.visibility = "visible";
      } else {
        document.getElementById(elementId).style.visibility = "hidden";
      }
    },
  },
  mounted() {
    // prevent click outside event with popupItem.
    // if (this.checkNpsSurvey("nps_beneficiary") < 4) {
    //   this.showModalPopup("modal_popup_nps", true);
    // }
  },
  created() {
    switch (this.$route.name) {
      case "beneficiary_cards":
        this.menuSelected.cards = true;
        break;
      case "beneficiary_services":
        this.menuSelected.services = true;
        break;
      case "beneficiary_faq":
        this.menuSelected.faq = true;
        break;
      case "beneficiary_account":
        this.menuSelected.account = true;
        break;
      case "beneficiary_contact":
        this.menuSelected.contact = true;
        break;
      case "beneficiary_terms":
        this.menuSelected.terms = true;
        break;
      // case "beneficiary_gdpr":
      //   this.menuSelected.gdpr = true;
      //   break;
      case "beneficiary_notifications":
        this.menuSelected.notifications = true;
        break;
      default:
        this.menuSelected.dashboard = true;
    }
    if (!sessionStorage.getItem("mobile_jsonFile")) {
      httpService.commonsService.getConfigFile(
        localStorage.language,
        "mobile"
      );
    }
    if (!Cookies.get("beneficiaryLink")) {
      this.$router.push({
        name: "login",
        params: { loginUserSelected: true }
      });
    }

    if (this.beneficiary == null) {
      this.loadBeneficiaryData();
    }

  }
};
</script>

<style scoped>
.modal-popup-content[data-v-6f547fa0] {
  width: 550px !important;
  padding: 0px !important;
  max-width: 550px !important;
}
</style>
