<template>
  <div class="container backgroundAlb gdpr">
    <div class="tab-bkg">
      <ul class="nav nav-tabs paddingTopBottom20px">
        <li class="active">
          <a data-toggle="tab" href="#cookies">
            <p class="text-center">{{ $t("title.cookieTab1") }}</p>
          </a>
        </li>
        <li>
          <a data-toggle="tab" href="#date">
            <p class="text-center">{{ $t("title.cookieTab2") }}</p>
          </a>
        </li>
        <li>
          <a data-toggle="tab" href="#optiuni">
            <p class="text-center">{{ $t("title.cookieTab3") }}</p>
          </a>
        </li>
      </ul>
      <div class="tab-content">
        <div id="cookies" class="tab-pane fade show active">
          <hr />
          <div class="flex text-center" @click="showInfo('cookieInfo')">
            <div class="col-xl-4 col-4">
              <span>
                <b>{{ $t("title.cookiesGeneralInfo") }}</b>
              </span>
              &nbsp;
              <font-awesome-icon icon="info-circle" />
            </div>
          </div>
          <div
            id="cookieInfo"
            style="display: block;"
            v-html="$t('title.cookiesGeneralInfoDescription')"
          ></div>
          <hr />
          <div class="flex text-center">
            <div class="col-xl-4 col-4" @click="showInfo('cookieNecesar')">
              <span>
                <b>{{ $t("title.cookiesNecessary") }}</b>
              </span>
              &nbsp;
              <font-awesome-icon icon="info-circle" />
            </div>
            <div class="col-xl-4 col-4" @click="showInfo('cookieNecesar')">
              <span>{{ $t("title.cookiesMandatory") }}</span>
            </div>
            <div class="col-xl-4 col-4">
              <label class="switch">
                <input id="necessary" type="checkbox" checked disabled />
                <span id="lighter" class="slider round"></span>
              </label>
            </div>
          </div>
          <div id="cookieNecesar">
            <p v-html="$t('title.cookiesNecessaryDescription')"></p>
            <ul class="no-bullet">
              <li>
                <p class="custom-link">{{ $t("title.cookiesNecessaryNecessary") }}</p>
              </li>
              <li>
                <p class="custom-link">{{ $t("title.cookiesNecessarySession") }}</p>
              </li>
            </ul>
          </div>
          <hr />
          <div class="flex text-center">
            <div class="col-xl-4 col-4" @click="showInfo('cookieFunctional')">
              <span>
                <b>{{ $t("title.cookiesFunctional") }}</b>
              </span>
              &nbsp;
              <font-awesome-icon icon="info-circle" />
            </div>
            <div class="col-xl-4 col-4" @click="showInfo('cookieFunctional')">
              <span id="txtFunctional">{{ $t("title.cookiesAllow") }}</span>
            </div>
            <div class="col-xl-4 col-4">
              <label class="switch">
                <input
                  id="functional"
                  type="checkbox"
                  v-model="cookiesData.functional"
                  @change="this.changeCookieFunctional"
                />
                <span class="slider round"></span>
              </label>
            </div>
          </div>
          <div id="cookieFunctional" @click="showInfo('cookieFunctional')">
            <p v-html="$t('title.cookiesFunctionalDescription')"></p>
            <ul class="no-bullet">
              <li>
                <p class="custom-link">
                  {{ $t("title.cookiesFunctionalDescriptionLink") }}
                </p>
              </li>
            </ul>
          </div>
          <hr />
          <!-- <div class="flex text-center">
            <div class="col-xl-4 col-4" @click="showInfo('cookieAnaliza')">
              <span>
                <b>{{ $t("title.cookiesAnalisys") }}</b>
              </span>
              &nbsp;
              <font-awesome-icon icon="info-circle" />
            </div>
            <div class="col-xl-4 col-4" @click="showInfo('cookieAnaliza')">
              <span id="txtAnalisys">{{ $t("title.cookiesAllow") }}</span>
            </div>
            <div class="col-xl-4 col-4">
              <label class="switch">
                <input
                  id="analisys"
                  type="checkbox"
                  v-model="cookiesData.analisys"
                  @change="changeCookieAnalisys"
                />
                <span class="slider round"></span>
              </label>
            </div>
          </div> -->
          <!-- <div id="cookieAnaliza" @click="showInfo('cookieAnaliza')">
            <p v-html="$t('title.cookiesAnalisysDescription')"></p>
            <ul class="no-bullet">
              <li>
                <p class="custom-link">
                  {{ $t("title.cookiesAnalisysDescriptionLink") }}
                </p>
              </li>
            </ul>
          </div> -->
          <hr />
          <div class="flex text-center">
            <div class="col-xl-4 col-4" @click="showInfo('cookieMarketing')">
              <span>
                <b>{{ $t("title.cookiesMarketing") }}</b>
              </span>
              &nbsp;
              <font-awesome-icon icon="info-circle" />
            </div>
            <div class="col-xl-4 col-4" @click="showInfo('cookieMarketing')">
              <span id="txtMarketing">{{ $t("title.cookiesAllow") }}</span>
            </div>
            <div class="col-xl-4 col-4">
              <label class="switch">
                <input
                  id="marketing"
                  type="checkbox"
                  v-model="cookiesData.marketing"
                  @change="changeCookieMarketing"
                />
                <span class="slider round"></span>
              </label>
            </div>
          </div>
          <div id="cookieMarketing" @click="showInfo('cookieMarketing')">
            <p v-html="$t('title.cookiesMarketingDescription')"></p>
            <ul class="no-bullet">
              <li>
                <p class="custom-link">
                  {{ $t("title.cookiesMarketingDescriptionLink") }}
                </p>
              </li>
            </ul>
          </div>
          <hr />
        </div>
        <!-- <div id="date" class="tab-pane fade">
          <div id="notLogged" style="display: block">
            <p class="gdprP">
              {{ $t("title.gdprP1") }}
              <a
                class="custom-link"
                href="http://www.dataprotection.ro"
                target="_blank"
                >(www.dataprotection.ro)</a
              >
            </p>
            <p class="gdprP">{{ $t("title.gdprP2") }}</p>
            <br />
            <div class="scroll-background">
              <h4 class="text-center bold">{{ $t("title.gdprTitle") }}</h4>
              <div id="scrollable" class="scrollable">
                <p class="gdprP" v-html="$t('title.gdprP3')"></p>
                <p class="gdprP" v-html="$t('title.gdprP4')"></p>
                <p class="gdprP" v-html="$t('title.gdprP5')"></p>
                <p class="gdprP" v-html="$t('title.gdprP6')"></p>
                <p class="gdprP" v-html="$t('title.gdprP7')"></p>
                <p class="gdprP" v-html="$t('title.gdprP8')"></p>
                <p class="gdprP" v-html="$t('title.gdprP9')"></p>
                <p class="gdprP" v-html="$t('title.gdprP10')"></p>
                <p class="gdprP" v-html="$t('title.gdprP11')"></p>
              </div>
              <br />
            </div>
            <br />
            <br />
            <p class="gdprP2 bold">{{ $t("title.gdprP12") }}</p>
            <div class="text-center">
              <a class="customA2 bold" href="mailto:%20info@upromania.ro"
                >info@upromania.ro</a
              >
            </div>
            <p class="gdprP">{{ $t("title.gdprP13") }}</p>
            <ul class="listaPatrata listaPortocalie">
              <li>
                <p>{{ $t("title.gdprLi1") }}</p>
              </li>
              <li>
                <p>{{ $t("title.gdprLi2") }}</p>
              </li>
              <li>
                <p>{{ $t("title.gdprLi3") }}</p>
              </li>
              <li>
                <p>{{ $t("title.gdprLi4") }}</p>
              </li>
              <li>
                <p>{{ $t("title.gdprLi5") }}</p>
              </li>
            </ul>
          </div>
        </div> -->
        <!-- <div id="optiuni" class="tab-pane fade">
          <div id="options-logged" style="display: block;">
            <p class="gdprP">{{ $t("title.gdprP14") }}</p>
            <div class="text-center">
              <b>
                <a class="customA2" href="mailto:%20info@upromania.ro"
                  >info@upromania.ro</a
                >
              </b>
            </div>
            <br />
          </div>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
require("@/assets/js/gdpr.js");
import $ from "jquery";
import Cookies from "js-cookie";

export default {
  name: "Cookies",
  props: {
    msg: String
  },
  data() {
    return {
      cookiesData: {
        functional: Cookies.get("functional")
          ? Cookies.get("functional")
          : true,
        analisys: Cookies.get("analytics") ? Cookies.get("functional") : true,
        marketing: Cookies.get("marketing") ? Cookies.get("functional") : true
      }
    };
  },
  methods: {
    showInfo(id) {
      $("#" + id).toggle("fast");
    },
    changeCookieFunctional() {
      this.$emit("change", this.cookiesData.functional);
      if (this.cookiesData.functional) {
        Cookies.set("functional", this.cookiesData.functional);
      } else {
        Cookies.remove("functional");
      }
    },
    changeCookieAnalisys() {
      this.$emit("change", this.cookiesData.analisys);
      if (this.cookiesData.analisys) {
        Cookies.set("analytics", this.cookiesData.analisys);
        this.$gtm.enable(true);
        this.$ga.enable(true);
      } else {
        Cookies.remove("analytics");
        this.$gtm.enable(false);
        this.$ga.enable(false);
      }
    },
    changeCookieMarketing() {
      this.$emit("change", this.cookiesData.marketing);
      if (this.cookiesData.marketing) {
        Cookies.set("marketing", this.cookiesData.marketing);
      } else {
        Cookies.remove("marketing");
      }
    }
  },
  mounted() {}
};
</script>

<style scoped src="../assets/styles/gdpr.css"></style>
