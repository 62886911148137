export default [
	{
		electronic: [
			{
				id: "autocolant_masa",
				products: [1, 11],
				file_name: "autocolant_masa.png",
				description: "Autocolant masa",
			},
		],
		medium: [
			{
				id: "autocolant_masa",
				products: [1, 11],
				file_name: "autocolant_masa.png",
				description: "Autocolant masa",
			},
			{
				id: "autocolant_cadou",
				products: [ 2, 12],
				file_name: "autocolant_cadou.png",
				description: "Autocolant cadou",
			},
		],

	},
];
