<template>
  <div class="user">
    <!-- <div class="openNotifications" @click="openNotifications('NOTIFICATION')" v-click-outside="hideNotification">
      <div v-if="this.readMessagesCount > 0" class="unread-notifications">
        <span>{{ this.readMessagesCount }}</span>
      </div>
      <img src="@/assets/images/clopotel-notificari.svg" />
      <div class="notification-box" v-show="isOpenNotification">
        <div v-if="this.companyMessages && this.companyMessages.length > 0">
          <NotificationsBox :notifications="this.companyMessages" />
          <a class="notification-view-all" href="/customer/notifications">{{
            $t("title.showAllNotifications")
          }}</a>
        </div>
        <!-- <div v-else-if="this.notificationON === false" class="noNotifications">
            <p>{{ $t("title.notificationIsOFF") }}</p>
            <p>
              <a :href="'/beneficiary/account?tabSettings=1'">{{ $t("title.goToSetting") }}</a>
            </p>
          </div> --
        <div v-else class="noNotifications">
          <p>{{ $t("title.notificationMessage") }}</p>
        </div>
      </div>
    </div>
    <div>
      <img src="@/assets/images/linie.png" />
    </div> -->
    <!-- <div class="openNotifications cursor-pointer" @click="openNotifications('COMPANY')" v-click-outside="hideCompany">
      <div v-if="this.company.role !== 'FAC' && this.company.role !== 'AFA'">
        <font-awesome-icon icon="university" size="2x" />
        <span>{{ " " + selectedCompanyName }}</span>
        <img src="@/assets/images/arrow-down.svg" />
      </div>
      <div v-else>
        <span>{{ " " + selectedCompanyName }}</span>
      </div>
      <div class="notification-box" v-show="isOpenCompany"
        v-if="this.company.role !== 'FAC' && this.company.role !== 'AFA'">
        <div class="main-notification">
          <table cellpadding="10" cellspacing="0" width="100%" style="text-align: left" align="center"
            class="hover-table">
            <tr v-for="company in this.user.companies" :key="company.company_id" v-if="company.active"
              @click="selectCompany(company)" class="trhover" :class="selectedCompany.company_id === company.company_id && 'gri-cell'
                ">
              <td class="notification-footer">
                <p>
                  <font-awesome-icon icon="university" />

                  {{ company.name }}
                </p>
              </td>
              <td class="notification-footer" style="text-align: right">
                <p>{{ company.company_id }}</p>
              </td>
            </tr>
          </table>
        </div>
      </div>
    </div> -->
    <div>
      <img src="@/assets/images/linie.png" />
    </div>
    <div class="openUserMenu" v-click-outside="hide" @click="openUserMenu">
      <img src="@/assets/images/settings.png" />
      <a href="#">{{ this.selectedCompanyName }}</a>

      <div class="userMenu" v-show="isOpen">
        <a :href="this.user.role === 'AFF'
          ? '/affiliate/account'
          : '/customer/account'
          " class="account" :class="this.menuSelected.account && 'account-active'">{{ $t("title.settingsName") }}</a>
        <a :href="this.user.role === 'AFF' ? '/affiliate/faq' : '/customer/faq'" class="faq"
          :class="this.menuSelected.faq && 'faq-active'">{{ $t("title.faqName") }}</a>
        <a v-if="this.company.role === 'FAC'" href="#" class="logout" @click="deselect()">{{ $t("title.deselectName") }}</a>
        <a v-else href="#" class="logout" @click="logout()">{{
          $t("title.logoutName")
        }}</a>
      </div>
    </div>
    <!-- <LanguageBar @languageChanged="languageChanged" v-if="this.user.role !== 'AFF'" /> -->
  </div>
</template>
<script>
import Cookies from "js-cookie";
// import httpServiceCustomer from "@/http/http-customer.js";
import ClickOutside from "vue-click-outside";
// import LanguageBar from "@/components/LanguageBar.vue";
// import NotificationsBox from "@/components/NotificationsBox.vue";

export default {
  name: "TopBarCompany",
  props: {
    user: Object,
    company: Object,
    companyMessages: Array
  },
  directives: {
    ClickOutside
  },
  components: {
    // LanguageBar,
    // NotificationsBox
  },
  data() {
    return {
      customerMessages: null,
      menuSelected: {
        faq: false,
        account: false
      },
      userLink: "",
      token: "",
      readMessagesCount: 0,
      isOpenNotification: false,
      isOpenCompany: false,
      isOpen: false,
      selectedCompany: this.user.companies[0],
      dataLoaded: false,
      config: JSON.parse(localStorage.getItem("jsonFile"))
    };
  },
  methods: {
    openUserMenu() {
      this.isOpen = !this.isOpen;
    },
    // openNotifications(windowType) {
    //   if (windowType === "NOTIFICATION") {
    //     this.loadMessages();
    //     this.isOpenNotification = !this.isOpenNotification;
    //     this.isOpenCompany = false;
    //   }
    //   if (windowType === "COMPANY") {
    //     this.isOpenCompany = !this.isOpenCompany;
    //     this.isOpenNotification = false;
    //   }
    // },
    // isReadNotification(message) {
    //   message.read = true;
    //   httpServiceCustomer.customerService
    //     .markMessageAsRead(message, this.token)
    //     .catch(error => { });
    // },
    // loadMessages() {
    //   let formData =
    //     "?user_id=" +
    //     this.company.company_id +
    //     "&lang=" +
    //     localStorage.language;
    //   httpServiceCustomer.customerService
    //     .getMessages(formData, this.token)
    //     .then(function (response) {
    //       // handle success
    //       return response;
    //     })
    //     .then(response => {
    //       // total no of messages X-Up-Count
    //       // no of read messages
    //       this.readMessagesCount = response.headers["x-up-count1"];
    //       // no of unread messages X-Up-Count2
    //       this.customerMessages = response.data;
    //     })
    //     .catch(error => {
    //       // handle error
    //     });
    // },
    hide() {
      this.isOpen = false;
    },
    // hideNotification() {
    //   this.isOpenNotification = false;
    // },
    hideCompany() {
      this.isOpenCompany = false;
    },
    logout() {
      Cookies.remove("userLink");
      Cookies.remove("token");
      this.$router.push({
        name: "login",
        params: { loginUserSelected: false }
      });
      this.$emit("sendUserRole", "", "");
    },
    goBack() {
      window.history.length > 1 ? this.$router.go(-1) : this.$router.push("/");
    },
    deselect() {
      Cookies.set(
        "selectedCompany",
        {
          company_id: 0,
          role: "FAC"
        },
        {
          sameSite: "Strict"
        }
      );
      localStorage.removeItem("customer_data");
      localStorage.removeItem("affiliate_data");
      window.location.href = "/company/search";
    },
    selectCompany(company) {
      Cookies.set(
        "selectedCompany",
        {
          company_id: company.company_id,
          role: company.role
        },
        {
          sameSite: "Strict"
        }
      );

      localStorage.removeItem("affiliate_data");
      localStorage.removeItem("customer_data");
      window.location.href = "/company";
    },
    languageChanged() {
      this.$emit("languageChanged");
    }
  },
  computed: {
    selectedCompanyName() {
      // if (this.company.role === "FAC" || this.company.role === "AFA") {
      return this.company.company_id + " - " + this.company.name;
      // }
      // for (let index in this.user.companies) {
      //   let companyI = this.user.companies[index];
      //   if (this.selectedCompany.company_id === companyI.company_id) {
      //     return companyI.company_id + " " + companyI.name;
      //   }
      // }
      // return "";
    }
  },
  created() {
    switch (this.$route.name) {
      case "customer_faq":
        this.menuSelected.faq = true;
        break;
      case "customer_account":
        this.menuSelected.account = true;
        break;
    }
    this.selectedCompany = Cookies.getJSON("selectedCompany");
    if (!this.selectedCompany) {
      this.selectedCompany = this.user.companies[0];
    }
  }
};
</script>
<style scoped src="@/assets/styles/beneficiary.css"></style>
