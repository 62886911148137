<template>
  <div>
    <date-range-picker
      :dateRange="timeValue ? timeValue : time"
      @select="changeValue"
      :auto-apply="true"
      :locale-data="locale"
      :opens="openDirection ? 'right' : 'left'"
      :ranges="noRanges ? false : ranges"
      @toggle="changeLanguage"
      :single-date-picker="single"
      :always-show-calendars="true"
      :minDate="minDateOrder ? minDateOrder : null"
      :maxDate="maxDateCourier ? maxDateCourier : null"
      :timePicker="timePicker ? timePicker : false"
      :timePicker24Hour="timePicker24Hour ? timePicker24Hour : false"
      :timePickerIncrement="1"
      :dateFormat="format"
      ref="openDate"
    >
      <div slot="input">
        <span v-if="noRanges && single">{{ formatStartDate() }}</span>
        <span v-else>{{ formatStartDate() }} - {{ formatEndDate() }}</span>
        <span class="mobile-icon" style="margin-left: 5px">
          <font-awesome-icon icon="calendar-alt" />
        </span>
      </div>
    </date-range-picker>
  </div>
</template>

<script>
  import moment from "moment";
  import DateRangePicker from "vue3-daterange-picker";
  // import "vue3-daterange-picker/dist/vue3-daterange-picker.css";

  export default {
    components: {
      DateRangePicker
    },
    props: {
      msg: String,
      time: Array,
      single: Boolean,
      noRanges: Boolean,
      minDateOrder: Date,
      maxDateCourier: Date,
      timePicker: Boolean,
      timePicker24Hour: Boolean,
      format: String,
      openDirection: Boolean
    },
    data() {
      return {
        locale: {
          locale: moment.locale(localStorage.language),
          direction: "ltr", //direction of text
          format: "dd-mm-yyyy", //fomart of the dates displayed
          separator: " - ", //separator between the two ranges
          applyLabel: "Apply",
          cancelLabel: "Cancel",
          weekLabel: "W",
          customRangeLabel: "Custom Range",
          daysOfWeek: moment.weekdaysMin(), //array of days - see moment documenations for details
          monthNames: moment.monthsShort(), //array of month names - see moment documenations for details
          firstDay: 1 //ISO first day of week - see moment documenations for details,
        },
        timeValue: this.time
          ? { startDate: this.time[0], endDate: this.time[1] }
          : { startDate: moment(), endDate: moment() }
      };
    },
    computed: {
      ranges: function () {
        let today = new Date()
        today.setHours(0, 0, 0, 0)

        //         {
        //           'Today': [today, today],
        //             'Yesterday': [yesterday, yesterday],
        //               'This month': [thisMonthStart, thisMonthEnd],
        //                 'This year': [],
        // }
        console.log(today);

        if (localStorage.language === "ro") {
          return {
            "Luna curenta": [new Date(today.getFullYear(), today.getMonth(), 1), new Date(today.getFullYear(), today.getMonth() + 1, 0)],
            "Luna trecuta": [new Date(today.getFullYear(), today.getMonth() - 1, 1), new Date(today.getFullYear(), today.getMonth(), 0)],
            "Ultimele 3 luni": [new Date(today.getFullYear(), today.getMonth() - 3, today.getDay()), new Date(today.getFullYear(), today.getMonth(), today.getDay())],
            "Anul curent": [new Date(today.getFullYear(), 0, 1), new Date(today.getFullYear(), 11, 31)]
          };
        } else {
          return {
            "This month": [new Date(today.getFullYear(), today.getMonth(), 1), new Date(today.getFullYear(), today.getMonth() + 1, 0)],
            "Last month": [new Date(today.getFullYear(), today.getMonth() - 1, 1), new Date(today.getFullYear(), today.getMonth(), 0)],
            "Last 3 months": [new Date(today.getFullYear(), today.getMonth() - 3, today.getDay()), new Date(today.getFullYear(), today.getMonth(), today.getDay())],
            "This year": [new Date(today.getFullYear(), 0, 1), new Date(today.getFullYear(), 11, 31)]
          };
        }
      }
    },
    watch: {
      time: function (val) {
        this.timeValue = val;
      }
    },
    methods: {
      changeValue(newValue) {

        this.timeValue = [newValue.startDate, newValue.endDate];
        console.log(this.timeValue)
        this.$emit("close", this.timeValue);
      },
      changeLanguage() {
        this.locale.locale = moment.locale(localStorage.language);
        this.$emit("toggle", this.locale.locale);
      },
      formatStartDate() {
        if (this.format) {
          return moment(this.timeValue[0]).format(this.format);
        } else {
          return moment(this.timeValue[0]).format(this.locale, format);
        }
      },
      formatEndDate() {
        if (this.format) {
          return moment(this.timeValue[1]).format(this.format);
        } else {
          return moment(this.timeValue[1]).format(this.locale.format);
        }
      }
    }
  };
</script>
