<template>
  <Line v-if="chartData" :data="chartData" :options="this.options" />
</template>

<script>
// import { Line } from "vue-chartjs";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
} from 'chart.js'
import { Line } from 'vue-chartjs'

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
)

export default {
  components: { Line },
  // mixins: [reactiveProp],
  props: {
    chartData: {
      type: Object,
      default: null,
      fill: false
    }
  },
  data() {
    return {
      options: {
        //Chart.js options
        scales: {
          // yAxes: [
          //   {
          //     ticks: {
          //       beginAtZero: true
          //     },
          //     lineFill: false
          //   }
          // ],
          // xAxes: [
          //   {
          //     lineFill: false
          //   }
          // ]
        },
        responsive: true,
        maintainAspectRatio: false
      }
    };
  },
  mounted() {
    // this.renderChart(this.chartData, this.options);
  }
};
</script>
