<template>
  <div>
    <div v-if="dataLoaded">
      <div v-if="!isMobile" class="menu">
        <a href="/company" :class="{ logo: true }">
          <img src="@/assets/images/logo-up.png" />
        </a>
        <MenuItems :company="this.customer" :user="this.user" :menuSelected="this.menuSelected" ref="menu_items" />
      </div>

      <div v-if="isMobile" class="menu-mobile p-0 w-100" :class="{ 'open-menu': showMobileMenu }">
        <!-- <div class="p-3 w-100 d-flex m-0 box-sizing-bb border-bottom">
          <div class="openNotifications" @click="showModalPopup('modal_popup_mobileNotifications', true)">
            <div v-if="this.readMessagesCount > 0" class="unread-notifications">
              <span>{{ this.readMessagesCount }}</span>
            </div>
            <img src="@/assets/images/clopotel-alb.svg" />
          </div>

          <div id="modal_popup_mobileNotifications" class="modal-popup">
            <div class="modal-popup-content">
              <a @click="
                showModalPopup('modal_popup_mobileNotifications', false)
                " class="close-button">
                <img src="@/assets/images/close-button.svg" />
              </a>
              <div class="notifications">
                <NotificationsBox :notifications="this.companyMessages" />
              </div>
            </div>
          </div>

          <div>
            <a href="#">{{ this.customer.name | titleCase }}</a>
          </div>
          <div class="ml-auto close-menu" @click="openMenu(false)">
            <img src="@/assets/images/x.svg" />
          </div>
        </div> -->
        <div class="px-3">
          <MenuItems :company="this.customer" :user="this.user" :menuSelected="this.menuSelected" ref="menu_items" />
        </div>
      </div>

      <div class="container-right">
        <div class="barTop" :class="menuSelected.dashboard ? 'view' : 'bar-top-white'">
          <div class="logo-mobile" v-if="menuSelected.dashboard">
            <img src="@/assets/images/logo-up.png" />
          </div>
          <div class="menu-white" v-if="menuSelected.dashboard" @click="openMenu(true)">
            <img src="@/assets/images/menu-burger-alb.svg" />
          </div>

          <div class="mobile-nav" v-if="!menuSelected.dashboard">
            <div class="arrow-back" @click="goBack()">
              <img src="@/assets/images/arrow-back.svg" />
            </div>
            <div class="page-title">
              <h3>{{ this.getPageTitle() }}</h3>
            </div>
            <div class="menu-white" @click="openMenu(true)">
              <img src="@/assets/images/menu-burger-gri.svg" />
            </div>
          </div>
          <TopBar :company="this.customer" :user="this.user" :companyMessages="this.companyMessages"
            @languageChanged="languageChanged" />
        </div>

        <div :class="{
          content: true,
          'dashboard-beneficiary': this.menuSelected.dashboard
        }">
          <CompanySearch :customer="this.customer" v-if="this.menuSelected.companySearch" />

          <Dashboard :customer="this.customer" :user="this.user" v-if="this.menuSelected.dashboard" />
          <Beneficiaries :customer="this.customer" :userRole="this.user.role" :user="this.user"
            v-if="this.menuSelected.beneficiaries" />
          <DeliveryPoints :customer="this.customer" v-if="this.menuSelected.deliveryPoints" />
          <Invoices :customer="this.customer" v-if="this.menuSelected.invoices" />
          <Orders :customer="this.customer" v-if="this.menuSelected.orders" />
          <NewOrder :customer="this.customer" :userRole="this.user.role" :user="this.user"
            v-if="this.menuSelected.newOrder" />
          <CompanySettings :companyDetails="this.customer" :user="this.user" :userRole="this.user.role"
            v-if="this.menuSelected.account" />
          <LinkFrame :urlAddress="this.config.faq" :key="linkFrameIndex"
            v-if="this.menuSelected.faq && this.user.role !== 'FAC'" />
          <Contact :customer="this.customer" v-if="this.menuSelected.contact && this.user.role !== 'FAC'" />
          <LinkFrame :urlAddress="this.config.terms" v-if="this.menuSelected.terms && this.user.role !== 'FAC'" />
          <!-- <LinkFrame :urlAddress="this.config.gdpr" v-if="this.menuSelected.gdpr && this.user.role !== 'FAC'" /> -->
          <!-- <Notifications :companyDetails="this.customer" :user="this.user" :notifications="this.companyMessages"
            v-if="this.menuSelected.notifications" /> -->
          <ManageCompany :companyDetails="this.customer" :user="this.user" :userType="this.user.role"
            v-if="this.menuSelected.manageCompany" />
        </div>
      </div>
    </div>
    <div id="modal_popup_loading" class="modal-popup">
      <div class="modal-popup-content">
        <img src="@/assets/images/loading.gif" />
        <p v-html="$t('title.loadingPopupMessage')"></p>
      </div>
    </div>
  </div>
</template>
<style>
.box-sizing-bb {
  box-sizing: border-box !important;
}
</style>
<script>
import {defineAsyncComponent} from "vue";
import MenuItems from "@/components/company/customer/MenuItems.vue";
import TopBar from "@/components/company/TopBar.vue";
const Beneficiaries = defineAsyncComponent(() =>  
  import("@/components/company/customer/Beneficiaries.vue"));
const Dashboard = defineAsyncComponent(() =>  import("@/components/company/customer/Dashboard.vue"));
const Invoices = defineAsyncComponent(() =>  import("@/components/company/customer/Invoices.vue"));
const Orders = defineAsyncComponent(() =>  import("@/components/company/customer/Orders.vue"));
const NewOrder = defineAsyncComponent(() =>  import("@/components/company/customer/NewOrder.vue"));
const DeliveryPoints = defineAsyncComponent(() => 
  import("@/components/company/customer/DeliveryPoints.vue"));
const CompanySearch = defineAsyncComponent(() => 
  import("@/components/company/CompanySearch.vue"));
const Contact = defineAsyncComponent(() =>  import("@/components/company/customer/Contact.vue"));
const CompanySettings = defineAsyncComponent(() => 
  import("@/components/company/CompanySettings.vue"));

const LinkFrame = defineAsyncComponent(() =>  import("@/components/beneficiary/LinkFrame.vue"));
// const NotificationsBox = defineAsyncComponent(() =>  import("@/components/NotificationsBox.vue");
// const Notifications = defineAsyncComponent(() => 
//   import("@/components/company/customer/Notifications.vue");
const ManageCompany = defineAsyncComponent(() =>  import("@/components/company/ManageCompany.vue"));
import httpServiceCustomer from "@/http/http-customer.js";

import httpServiceUser from "@/http/http-user.js";
import Cookies from "js-cookie";
import httpService from "@/http/http-common.js";

export default {
  components: {
    MenuItems,
    TopBar,
    LinkFrame,
    Dashboard,
    Beneficiaries,
    DeliveryPoints,
    Invoices,
    Orders,
    NewOrder,
    CompanySettings,
    CompanySearch,
    Contact,
    // NotificationsBox,
    // Notifications,
    ManageCompany,
  },
  props: {
    user: Object,
    company: Object
  },
  data() {
    return {
      userLink: "",
      token: "",
      customer: null,
      customerMessages: null,
      dataLoaded: false,
      menuSelected: {
        dashboard: false,
        orders: false,
        newOrder: false,
        beneficiaries: false,
        deliveryPoints: false,
        invoices: false,
        faq: false,
        account: false,
        contact: false,
        terms: false,
        gdpr: false,
        companySearch: false,
        notifications: false,
        manageCompany: false,
      },
      config: JSON.parse(sessionStorage.getItem("client_jsonFile")),
      isOpen: false,
      isOpenNotification: false,
      readMessagesCount: 0,
      linkFrameIndex: 1,
      companyMessages: [
        {
          dateIssued: "2021-04-28T21:00:00.000Z",
          dateRead: "2021-05-19T14:06:19.069Z",
          id: 1,
          lang: "ro",
          message:
            "Acest mesaj are peste 100 de caractere! Acest mesaj are peste 100 de caractere! Acest mesaj are peste 100 de caractere! Acest mesaj are peste 100 de caractere!",
          read: true,
          title: "Mesaj lung",
          user_id: 123799,
          validFrom: "2021-04-28T21:00:00.000Z",
          validTo: "2021-05-28T21:00:00.000Z"
        },
      ],
      isMobile: (document.body.clientWidth < 1004),
      showMobileMenu: false
    };
  },

  methods: {
    orderProducts() {
      if (this.customer.products) {
        this.customer.products.forEach((product) => {
          if (product.name === "Up Dejun") {
            product.order = 1;
          } else if (product.name === "Up Cadou") {
            product.order = 2;
          } else if (product.name === "Up Cadou STIM") {
            product.order = 3;
          } else if (product.name === "Up Vacanta") {
            product.order = 4;
          } else if (product.name === "Up Cultura") {
            product.order = 5;
          } else if (product.name === "Up Social") {
            product.order = 6;
          } else if (product.name === "Up Gradinita") {
            product.order = 7;
          } else {
            product.order = 8;
          }
        });

        this.customer.products.sort((a, b) => (a.order > b.order ? 1 : -1));
      }
    },
    loadCustomerData() {
      if (Cookies.get("userLink") !== null) {
        this.userLink = Cookies.get("userLink");
        this.token = Cookies.get("token");
      } else {
        this.$router.push({
          name: "login",
          params: { loginUserSelected: true }
        });
      }

      if (localStorage.getItem("customer_data")) {
        this.customer = JSON.parse(localStorage.getItem("customer_data"));
        this.dataLoaded = true;
      } else {
        this.showModalPopup("modal_popup_loading", true);
        httpServiceUser.userService
          .getCompanyData(this.company.company_id, Cookies.get("token"), "CST")
          .then(function (response) {
            return response;
          })
          .catch((error) => {
            this.dataLoaded = false;
            this.customerError = {
              code: error.response.status,
              description: "Error fetching customer"
            };
            this.showModalPopup("modal_popup_loading", false);
            window.location.href = "/?serverError=500";
          })
          .then((response) => {
            if (response.status == 200) {
              this.customer = response.data;
              this.customer.role = this.company.role;
              this.customer.userRole = this.user.role;
              let canOrderUp = response.headers["x-up-order"];
              if (typeof canOrderUp !== "undefined") {
                this.customer.can_order_up =
                  canOrderUp === "true" || canOrderUp;
                this.company.can_order_up = canOrderUp === "true" || canOrderUp;
              } else {
                this.customer.can_order_up = true;
                this.company.can_order_up = true;
              }

              this.orderProducts();
              localStorage.setItem(
                "customer_data",
                JSON.stringify(this.customer)
              );
              this.dataLoaded = true;

            } else if (response.status == 400 || response.status == 403) {
              this.dataLoaded = false;
              this.customerError = {
                code: response.data,
                description: "Error fetching transactions",
              };
            } else {
              this.dataLoaded = false;
              this.customerError = { description: response.description };
            }
            this.showModalPopup("modal_popup_loading", false);
          })
          .catch((e) => {
            console.log(e)
          });
      }
    },
    // loadMessages() {
    //   let formData =
    //     "?user_id=" +
    //     this.customer.company_id +
    //     "&lang=" +
    //     localStorage.language;
    //   httpServiceCustomer.customerService
    //     .getMessages(formData, this.token)
    //     .then(function (response) {
    //       // handle success
    //       return response;
    //     })
    //     .then((response) => {
    //       // total no of messages X-Up-Count
    //       // no of read messages
    //       this.readMessagesCount = Number(response.headers["x-up-count1"]);
    //       // no of unread messages X-Up-Count2
    //       this.customerMessages = response.data;
    //     })
    //     .catch((error) => {
    //       // handle error
    //     });
    // },
    openMenu(open) {
      this.showMobileMenu = open;
    },
    openUserMenu() {
      this.isOpen = !this.isOpen;
    },
    // openNotifications() {
    //   this.loadMessages();
    //   this.isOpenNotification = !this.isOpenNotification;
    // },
    // isReadNotification(message) {
    //   message.read = true;
    //   httpServiceCustomer.customerService
    //     .markMessageAsRead(message, this.token)
    //     .catch((error) => {
    //       // handle success
    //     });
    // },
    hide() {
      this.isOpen = false;
    },
    // hideNotification() {
    //   this.isOpenNotification = false;
    // },
    getPageTitle() {
      if (this.menuSelected.orders) {
        return "Orders";
      } else if (this.menuSelected.beneficiaries) {
        return "Beneficiaries";
      } else if (this.menuSelected.deliveryPoints) {
        return "Delivery points";
      } else if (this.menuSelected.invoices) {
        return "Invoices";
      } else if (this.menuSelected.newOrder) {
        return "New Order";
      } else if (this.menuSelected.faq) {
        return "FAQ";
      } else if (this.menuSelected.account) {
        return "My Account";
      } else if (this.menuSelected.contact) {
        return "Contact";
      } else if (this.menuSelected.terms) {
        return "Terms and conditions";
      // } else if (this.menuSelected.gdpr) {
      //   return "Personal data policy";
      } else if (this.menuSelected.companySearch) {
        return "Company search";
      } else if (this.menuSelected.notifications) {
        return "Notifications";
      } else if (this.menuSelected.manageCompany) {
        return "Manage Company";
      } else {
        return "Dashboard";
      }
    },
    logout() {
      this.$store.commit('logout');
      Cookies.remove("userLink");
      Cookies.remove("token");
      localStorage.removeItem("customer_data");
    },
    goBack() {
      let foundBackFunction = false;
      let childIndex = null;
      if (foundBackFunction && childIndex !== null) {
        this.$children[childIndex].goBack();
      } else {
        window.history.length > 1
          ? this.$router.go(-1)
          : this.$router.push("/");
      }
    },
    getExpireAmountDateFormat(date) {
      return moment(new Date(date), "YYYY-MM-DD").format("DD MMM YYYY");
    },
    showModalPopup(elementId, show) {
      if (show) {
        document.getElementById(elementId).style.visibility = "visible";
      } else {
        document.getElementById(elementId).style.visibility = "hidden";
      }
    },

    languageChanged() {
      setTimeout(() =>  {
        this.config = JSON.parse(sessionStorage.getItem("client_jsonFile"));
        this.linkFrameIndex++;
      }, 1000);
    },
  },
  mounted() {
    // prevent click outside event with popupItem.
    this.loadCustomerData();

    //  if (this.company.role === "CST" && this.checkNpsSurvey("nps_client") < 4){
    //     this.showModalPopup("modal_popup_nps", true);
    //   }
  },
  created() {
    switch (this.$route.name) {
      case "customer_dashboard":
        this.menuSelected.dashboard = true;
        break;
      case "customer_orders":
        this.menuSelected.orders = true;
        break;
      case "customer_invoices":
        this.menuSelected.invoices = true;
        break;
      case "customer_new_order":
        this.menuSelected.newOrder = true;
        break;
      case "customer_beneficiaries":
        this.menuSelected.beneficiaries = true;
        break;
      case "delivery_points":
        this.menuSelected.deliveryPoints = true;
        break;
      case "customer_services":
        this.menuSelected.services = true;
        break;
      case "customer_faq":
        this.menuSelected.faq = true;
        break;
      case "customer_account":
        this.menuSelected.account = true;
        break;
      case "customer_contact":
        this.menuSelected.contact = true;
        break;
      case "customer_terms":
        this.menuSelected.terms = true;
        break;
      // case "customer_gdpr":
      //   this.menuSelected.gdpr = true;
      //   break;
      case "company_search":
        this.menuSelected.companySearch = true;
        break;
      case "customer_notifications":
        this.menuSelected.notifications = true;
        break;
      case "manage_company":
        this.menuSelected.manageCompany = true;
        break;
      default:
        if (this.user.role === "FAC" || this.user.role === "HLP") {
          this.menuSelected.companySearch = true;
        } else {
          this.menuSelected.dashboard = true;
        }
    }
    if (!sessionStorage.getItem("client_jsonFile")) {
      httpService.commonsService.getConfigFile(localStorage.language, "client");
    }
  }
};
</script>
<style scoped src="@/assets/styles/beneficiary.css"></style>

