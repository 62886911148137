<template>
  <div class="socials">
    <a href="https://www.facebook.com//UpMoldova" class="facebook"></a>
    <a href="https://www.linkedin.com/in/up-servicii-srl-7b81671a0/?original_referer=https%3A%2F%2Fupmoldova.md%2F" class="linkedin"></a>
    <a href="https://www.youtube.com/@upmoldova738" class="youtube"></a>
    <a href="https://www.instagram.com/upservicii/" class="instagram"></a>
  </div>
</template>
<script>

export default {
  name: "MenuSocials",
};
</script>
<style scoped src="@/assets/styles/beneficiary.css"></style>
