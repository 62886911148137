<template>
  <div>
    <!-- <div class="language-div">
      <LanguageBar />
    </div> -->

    <div class="loginWrapper">
      <div class="containerSingleBox">
        <!--PAN and code box -->
        <div class="loginBox background-white">
          <div class="logo">
            <img src="../../assets/images/up.svg" />
            <div v-show="!registrationDataConfirmed">
              <p v-show="!cardDataSubmitted">
                {{ $t("title.selectCardTitle") }}
              </p>
              <p v-show="cardDataSubmitted && !this.showPasswordForm">
                {{ $t("title.registrationCheckDataTitle") }}
              </p>
              <p v-show="cardDataSubmitted && this.showPasswordForm">
                {{ $t("title.setPasswordTitle") }}
              </p>
              <p class="loginError" v-if="this.registrationError && this.registrationError.error_code" >
                {{ this.config.errors[this.registrationError.error_code] }}
              </p>
            </div>
          </div>
          <div id="select_card" v-if="!cardDataSubmitted">
            <div class="loginForm">
              <form @submit.prevent="submitCompanyData">
                <!-- <span>{{ $t("title.searchByCompanyType") }}:</span> -->
                <select class="w-100" @change="companyTypeSelected">
                  <option :selected="this.registrationFormData.type === 'CST'" value="CST">{{ $t("title.searchCompanyTypeClient") }}</option>
                  <option :selected="this.registrationFormData.type === 'AFF'" value="AFF">{{ $t("title.searchCompanyTypeAffiliate") }}</option>
                </select>
                <input type="text" :placeholder="[[$t('title.companyCodeLabel')]]" id="input-card-number"
                  v-model="registrationFormData.code" required />
                <input type="text" :placeholder="[[$t('title.fiscalCodeLabel')]]" id="input-code"
                  v-model="registrationFormData.identification" required />

                <input type="submit" :value="[[$t('title.confirmButtonLabel')]]" variant="primary" class="submitCompanies" />

              </form>
            </div>
          </div>
          <!--End PAN and code box -->
          <!-- Begin beneficiary details box -->

          <div class="loginForm" v-if="cardDataSubmitted && !registrationDataConfirmed">
            <form @submit.prevent="submitCompanyDetails">
              <div v-if="!showPasswordForm">
                <input type="text" :placeholder="[[$t('title.surnameFieldLabel')]]" id="input-first-name"
                v-model="companyData.first_name" disabled required />
                <br />
                <input type="text" :placeholder="[[$t('title.usernameLabel')]]" id="input-first-name"
                  v-model="companyData.username" disabled required />
                <br />
                <input type="text" :placeholder="[[$t('title.emailField')]]" id="input-email"
                  v-model="companyData.email" :style="validateEmailClass"
                  :disabled="this.companyData.active" required />
                <br />

                <div class="form-check">
                  <input type="checkbox" id="terms" v-model="terms" class="form-check-input" required />
                  <label for="terms">
                    {{ $t("title.textReadAndAgree") }}
                    <b><a @click="
              showModalPopup('modal_popup_terms', true, 'terms')
              " href="#">{{ $t("title.termsAndConditionsName") }}</a></b>
                  </label>
                </div>
                <!-- <div class="form-check">
                  <input type="checkbox" id="gdpr" v-model="gdpr" class="form-check-input" required />
                  <label for="gdpr">
                    {{ $t("title.textReadAndAgree") }}
                    <b><a @click="
                      showModalPopup('modal_popup_terms', true, 'gdpr')
                    " href="#">{{ $t("title.gdprName") }}</a></b>
                  </label>
                </div> -->
                <input type="button" :value="[[$t('title.confirmButtonLabel')]]" variant="primary" class="submitCompanies"
                  @click="showPasswordFields" />
              </div>
              <div v-if="showPasswordForm">
                <div class="password">
                  <input   :placeholder="[[$t('title.passwordField')]]"  v-model="companyData.password"
                  @input="validatePassword" required
                    :type="showNewPassword ? 'text' : 'password'"
                      />
                    <i v-on:click="showNewPassword = !showNewPassword">
                      <font-awesome-icon :icon="showNewPassword ? 'eye-slash' : 'eye'"
                        />

                    </i>
                </div>
                <div class="password">
                  <input    :placeholder="[[$t('title.confirmPasswordField')]]" required
                  :style="confirmPasswordStyle" @input="validatePassword" v-model="confirmPassword"
                    :type="showConfirmPassword ? 'text' : 'password'"
                      />
                    <i v-on:click="showConfirmPassword = !showConfirmPassword">
                      <font-awesome-icon :icon="showConfirmPassword ? 'eye-slash' : 'eye'"
                        />

                    </i>
                </div>
                <div class="passwordConditions">
                  <p>
                    <img v-if="!isIE" :src="this.validPassword.passwordLengthValid
              ? require('../../assets/images/yes.png')
              : require('../../assets/images/no.png')
              " />
                    &nbsp; Minimum 8 characters
                  </p>
                  <p>
                    <img v-if="!isIE" :src="this.validPassword.containsLetter
              ? require('../../assets/images/yes.png')
              : require('../../assets/images/no.png')
              " />
                    &nbsp; At least one letter character
                  </p>
                  <p>
                    <img v-if="!isIE" :src="this.validPassword.containsNumber
              ? require('../../assets/images/yes.png')
              : require('../../assets/images/no.png')
              " />
                    &nbsp; At least one numeric digit
                  </p>
                </div>
                <br />
                <br />
                <input type="submit" :value="[[$t('title.confirmButtonLabel')]]" variant="primary" class="submitCompanies" />
              </div>
            </form>
          </div>
          <div id="modal_popup_terms" class="modal-popup">
            <div class="modal-popup-content">
              <a @click="showModalPopup('modal_popup_terms', false)" class="close-button">
                <img src="../../assets/images/close-button.svg" />
              </a>
              <p>
                <iframe :src="this.docSrc"></iframe>
              </p>
            </div>
          </div>
          <!--End Email box -->
          <!-- Begin congrats registration -->
          <div class="loginForm" v-if="this.registrationDataConfirmed
              ">
              <br />
                <form @submit.prevent="completeRegistration" align="center">
                  <h2 align="center">{{ $t("title.successMessageTitle") }}</h2>
                  <div v-if="showPasswordForm">

                  <p align="center" class="lightGrey">
                    {{ $t("title.registrationSuccessMessageCompany", {code: this.companyData.username, name: this.companyData.first_name}) }}
                    <br />
                  </p>
                  </div>
                  <div v-else>
                    <!-- account already exists -->
                    <p align="center" class="lightGrey">
                      {{ $t("title.registrationAlreadyMessageCompany", {code: this.companyData.code, name: this.companyData.first_name}) }}
                      </p>
                    <router-link to="/forgot_password_company">
                      {{ $t("title.forgotPasswordLink") }}
                    </router-link>
                  </div>
                  <br />
                  <input type="submit" value="CONFIRM" class="submitCompanies" />
                </form>
          </div>
          <!-- End congrats registration-->
        </div>
      </div>
      <!-- <vue-recaptcha ref="recaptcha" :sitekey="recaptchaKey" @verify="getRecaptchaRP" size="invisible" badge="bottomleft"
        @expired="onCaptchaExpired" :loadRecaptchaScript="true" language="ro"></vue-recaptcha> -->
    </div>
    <div id="modal_popup_loading" class="modal-popup">
      <div class="modal-popup-content">
        <img src="../../assets/images/loading.gif" />
        <p v-html="$t('title.loadingPopupMessage')">
        </p>
      </div>
    </div>
  </div>
</template>
<!-- <script src="https://unpkg.com/vue-recaptcha@^1/dist/vue-recaptcha.min.js"></script> -->
<script>
  import httpService from "../../http/http-common.js";
  import httpServiceCompany from "../../http/http-beneficiary.js";
  import validator from "../../plugins/fieldValidation";
  import utils from "@/utils.js";
  import Cookies from "js-cookie";
  // import LanguageBar from "@/components/LanguageBar.vue";
  import { VueReCaptcha, useReCaptcha } from 'vue-recaptcha-v3'


  export default {
    setup() {
      const { formatPAN } = utils();

      const { executeRecaptcha, recaptchaLoaded } = useReCaptcha()

      const recaptcha = async () => {
        // (optional) Wait until recaptcha has been loaded.
        await recaptchaLoaded()

        // Execute reCAPTCHA with action "login".
        const token = await executeRecaptcha()

        return token;
        // Do stuff with the received token.
      }
      return {
        executeRecaptcha,
        recaptchaLoaded,
        recaptcha,
        formatPAN,
      }
    },
    components: {
      // LanguageBar, 
      VueReCaptcha
    },
    name: "create_account_company",
    props: {
      msg: String,
    },
    data() {
      return {
        registrationFormData: {
          identification: "",
          code: "",
          type: "CST"
        },

        token: "",
        confirmPassword: "",
        config: null,
        selectedCardIndex: 0,
        showNewPassword: false,
        showConfirmPassword: false,
        showPasswordForm: false,
        email: "",
        companyData: null,
        url: null,
        registrationError: null,
        validPassword: {
          passwordValid: false,
          passwordLengthValid: false,
          containsLetter: false,
          containsNumber: false,
          confirmPasswordValid: false,
        },

        terms: false,
        gdpr: false,
        docSrc: "",
        isIE: false,
        recaptchaToken: null,
        recaptchaKey: "6Lc4RJ8kAAAAADYYxYgaoCCPXbEg7857BOI-BzpO",
      };
    },
    methods: {
      companyTypeSelected(event) {
        this.registrationFormData.type = event.target.value;
      },
      handleSubmit() {
        this.$refs.recaptcha.execute("recaptcha");
      },
      submitCompanyData() {
        if (this.companyData) {
          return;
        }
        this.showModalPopup("modal_popup_loading", true);
        httpService.companyService
          .userCheckForRegistration(this.registrationFormData, this.token, this.recaptchaToken)
          .then(function (response) {
            return response;
          })
          .catch((error) => {
            return error.response;
          })
          .then((response) => {
            console.log(response)
            if (response.status == 200) {
              this.companyData = response.data;
              this.companyData.password = "";
              this.registrationError = null;
              this.showPasswordForm = false;
            } else if (response.status == 400) {
              console.log(response);
              this.companyData = null;
              this.registrationError = response.data;
              this.showPasswordForm = false;
            } else {
              this.companyData = null;
              this.registrationError = { error_code: response.status };
              this.showPasswordForm = false;
            }
            this.showModalPopup("modal_popup_loading", false);
          });
      },
      showPasswordFields() {
        this.registrationError = null;
        if (
          !this.terms ||
          this.terms === false
          // ||
          // !this.gdpr ||
          // this.gdpr === false
        ) {
          this.registrationError = {
            description: "Terms",
            error_code: "TERMS",
          };
          return;
        }
              this.showPasswordForm = true;
        // this.handleSubmit();

      },
      submitCompanyDetails() {
        if (!this.validPassword.passwordValid) {
          this.registrationError = {
            description: "Invalid password",
            error_code: "LS03",
          };
          return;
        } else {

          this.companyData.active = true;
          this.showModalPopup("modal_popup_loading", true);

          httpService.companyService
            .confirmCompanyData(this.companyData, this.token)
            .then(function (response) {
              // handle success
              return response;
            })
            .catch((error) => {
              // handle error
              return error.response;
            })
            .then((response) => {
              if (response.status == 200) {
                this.companyData = response.data;
                this.registrationError = null;
                // this.showPasswordForm = false;

              } else if (response.status == 400) {
                this.registrationError = response.data;
                this.companyData.active = false;
              } else {
                this.registrationError = { error_code: response.code };
                this.companyData.active = false;
              }
              this.showModalPopup("modal_popup_loading", false);
            });
        }
      },
      validatePassword() {
        this.validPassword = validator.validatePassword(
          this.companyData.password,
          this.confirmPassword
        );
      },
      completeRegistration() {
        this.registrationFormData = null;
        this.confirmPassword = "";
        this.selectedCardIndex = 0;
        this.showPasswordForm = false;
        this.registrationError = null;
        this.validPassword = null;
        this.$router.push({
          name: "login",
        });
      },
      showModalPopup(elementId, show, documentKey) {
        if (elementId === "modal_popup_terms") {
          this.docSrc = this.config[documentKey];
        }
        if (show) {
          document.getElementById(elementId).style.visibility = "visible";
        } else {
          document.getElementById(elementId).style.visibility = "hidden";
        }
      },
      loadConfigData() {
        this.config = JSON.parse(sessionStorage.getItem("client_jsonFile"));

        httpService.companyService
          .requestTokenForRegistration("clientopen")
          .then(function (response) {
            // handle success
            return response.data;
          })
          .then((data) => {
            this.token = data.access_token;
          })
          .catch((error) => {
            // handle error
          });
      },
    },
    mounted() {
      this.isIE = /*@cc_on!@*/ false || !!document.documentMode;

      if (!sessionStorage.getItem("client_jsonFile")) {
        if (localStorage.language === undefined) {
          localStorage.language = "ro";
        }
        httpService.commonsService
          .getConfigFile(localStorage.language, "client")
          .then(function (response) {
            return response;
          })
          .then((data) => {
            this.loadConfigData();
          });
      } else {
        this.loadConfigData();
      }
    },
    computed: {
      cardDataSubmitted() {
        return this.companyData !== null;
      },
      registrationDataConfirmed() {
        return (
          this.companyData !== null && this.companyData.active
        );
      },
      confirmPasswordStyle() {
        return this.companyData.password === this.confirmPassword
          ? ""
          : "outline-color: #ff0000a6; ";
      },
    },
  };
</script>
<style scoped src="@/assets/styles/login.css"></style>
