export default [
    {
        fileName: "beneficiary/beneficiar.jpg",
        link:
            "https://upmoldova.md/harta-afiliati/",
        category: "Banner",
        action: "Click",
        label: "utilizatori",
    },
];
