<template>
  <div>
    <div v-if="this.company" class="menu-items">
      <a v-if="(this.user.role === 'FAC'  || this.user.role === 'HLP') " href="/company/search" class="search-icon" 
      @click="removeSelectedCompany" :class="this.menuSelected.companySearch && 'search-active'">{{
    $t("title.searchName") }}</a>
      <a v-if="this.company.company_id !== 0 "
        href="/customer/dashboard" class="dashboard" :class="this.menuSelected.dashboard && 'dashboard-active'">{{
          $t("title.dashboardName") }}</a>
      <a v-if="this.company.company_id !== 0" href="/customer/orders" class="orders"
        :class="this.menuSelected.orders && 'orders-active'">{{ $t("title.ordersName") }}</a>
      <a v-if="this.company.company_id !== 0" href="/customer/beneficiaries" class="beneficiaries"
        :class="this.menuSelected.beneficiaries && 'beneficiaries-active'">{{ $t("title.beneficiariesName") }}</a>
      <a v-if="this.company.company_id !== 0" href="/customer/delivery_points" class="points"
        :class="this.menuSelected.deliveryPoints && 'points-active'">{{ $t("title.deliveryPointsName") }}</a>
      <a v-if="this.company.company_id !== 0" href="/customer/invoices" class="invoices"
        :class="this.menuSelected.invoices && 'invoices-active'">{{ $t("title.invoicesName") }}</a>
      <a href="/customer/account" class="amanage" :class="this.menuSelected.account && 'amanage-active'">{{
        $t("title.settingsName") }}</a>
      <a v-if="this.company.company_id !== 0 &&
        !user.username.includes('@') &&
        this.company &&
        this.user.role !== 'FAC' &&
        !this.company.state_owned &&
        this.company.user_config &&
        this.company.user_config.roles &&
        this.company.user_config.roles.includes('EDITOR')
        " href="/company/manage" class="cmanage" :class="this.menuSelected.manageCompany && 'cmanage-active'">{{
    $t("title.companyManagementTitle") }}</a>
      <a v-if="this.user.role !== 'FAC' " href="/customer/contact" class="contact"
        :class="this.menuSelected.contact && 'contact-active'">{{ $t("title.contactName") }}</a>
      <a v-if="this.user.role !== 'FAC' " href="/customer/faq" class="faq"
        :class="this.menuSelected.faq && 'faq-active'">{{ $t("title.faqName") }}</a>
      <a v-if="this.user.role !== 'FAC' " href="/customer/terms" class="term"
        :class="this.menuSelected.terms && 'term-active'">{{ $t("title.termsAndConditionsName") }}</a>
      <!--      <a-->
      <!--        v-if=""-->
      <!--        href="/customer/create-notification"-->
      <!--        :class="this.menuSelected.adminNotification && 'notification-active'"-->
      <!--        class="position-relative"-->
      <!--      >-->
      <!--        <font-awesome-icon :icon="['fas', 'bell']" class="fa-lg notification-bell"-->
      <!--                          style="position: absolute; left: 0; top: 2px"/>-->
      <!--        {{ $t("title.notifications") }}</a>-->

      <a href="#" class="logout" @click="logout()">{{ $t("title.logoutName") }}</a>
    </div>

    <a href="/customer/new_order" class="notification-action step0">
      {{ $t("title.newOrderName") }}
    </a>
    <MenuSocials />
  </div>
</template>
<script>
import Cookies from "js-cookie";
import MenuSocials from "@/components/MenuSocials.vue";

export default {
  name: "MenuItemsCustomer",
  components: {
    MenuSocials
  },
  props: {
    company: Object,
    user: Object,
    menuSelected: {
      dashboard: false,
      beneficiaries: false,
      orders: false,
      deliveryPoints: false,
      invoices: false,
      newOrder: false,
      faq: false,
      account: false,
      contact: false,
      terms: false,
      gdpr: false,
      companySearch: false,
      manageCompany: false,
      base_notification: false,
      create_notification: false
    },
  },
  data: function () {
    return {  };
  },
  mounted() {
    if (this.menuSelected.companySearch) {
      this.company.companyId = "0";
    }
  },
  methods: {
    logout() {
      Cookies.remove("userLink");
      Cookies.remove("token");
      Cookies.remove("selectedCompany");

      this.$router.push({
        name: "login",
        params: { loginUserSelected: false }
      });
    },
    removeSelectedCompany(){
      Cookies.remove("selectedCompany");
    },
    goBack() {
      window.history.length > 1 ? this.$router.go(-1) : this.$router.push("/");
    },
  }
};
</script>
<style scoped src="@/assets/styles/beneficiary.css"></style>
