import { createStore } from 'vuex'

const store = createStore({
  state() {
    return{
    language: localStorage.language ? localStorage.language : "ro" ,
		beneficiaryLink: "",
		accessToken: "",
    }
  },
  // mutations: {
  //   increment (state) {
  //     state.count++
  //   }
  // }
});

export default store