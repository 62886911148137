<template>
  <div>
    <div >
      <div v-if="!isMobile" class="menu">
        <a href="/company" :class="{ logo: true }">
          <img src="@/assets/images/logo-up.png" />
        </a>
        <MenuItems :user="this.user" :menuSelected="this.menuSelected" ref="menu_items" />
      </div>

      <div v-if="isMobile" class="menu-mobile p-0 w-100" :class="{ 'open-menu': showMobileMenu }">
        <!-- <div class="p-3 w-100 d-flex m-0 box-sizing-bb border-bottom">
          <div class="openNotifications" @click="showModalPopup('modal_popup_mobileNotifications', true)">
            <div v-if="this.readMessagesCount > 0" class="unread-notifications">
              <span>{{ this.readMessagesCount }}</span>
            </div>
            <img src="@/assets/images/clopotel-alb.svg" />
          </div>

          <div id="modal_popup_mobileNotifications" class="modal-popup">
            <div class="modal-popup-content">
              <a @click="
                showModalPopup('modal_popup_mobileNotifications', false)
                " class="close-button">
                <img src="@/assets/images/close-button.svg" />
              </a>
              <div class="notifications">
                <NotificationsBox :notifications="this.companyMessages" />
              </div>
            </div>
          </div>

          <div>
            <a href="#">{{ this.customer.name | titleCase }}</a>
          </div>
          <div class="ml-auto close-menu" @click="openMenu(false)">
            <img src="@/assets/images/x.svg" />
          </div>
        </div> -->
        <div class="px-3">
          <MenuItems :user="this.user" :menuSelected="this.menuSelected" ref="menu_items" />
        </div>
      </div>

      <div class="container-right">
        <div class="barTop" :class="'bar-top-white'">

          <div class="mobile-nav" >
            <div class="arrow-back" @click="goBack()">
              <img src="@/assets/images/arrow-back.svg" />
            </div>
            <div class="page-title">
              <h3>{{ this.getPageTitle() }}</h3>
            </div>
            <div class="menu-white" @click="openMenu(true)">
              <img src="@/assets/images/menu-burger-gri.svg" />
            </div>
          </div>
          <TopBar :company="this.company" :user="this.user" :companyMessages="this.companyMessages"
            @languageChanged="languageChanged" />
        </div>

        <div :class="{
          content: true,
          'dashboard-beneficiary': this.menuSelected.dashboard
        }">
          <CompanySearch :customer="this.company" v-if="this.menuSelected.companySearch" />
          <CompanySettings :companyDetails="this.company" :user="this.user" :userRole="this.user.role"
            v-if="this.menuSelected.account" />
        </div>
      </div>
    </div>
    <div id="modal_popup_loading" class="modal-popup">
      <div class="modal-popup-content">
        <img src="@/assets/images/loading.gif" />
        <p v-html="$t('title.loadingPopupMessage')"></p>
      </div>
    </div>
  </div>
</template>
<style>
.box-sizing-bb {
  box-sizing: border-box !important;
}
</style>
<script>
import MenuItems from "@/components/company/MenuItems.vue";
import TopBar from "@/components/company/TopBar.vue";
import CompanySearch from "@/components/company/CompanySearch.vue";
import CompanySettings from "@/components/company/CompanySettings.vue";

const LinkFrame = () => import("@/components/beneficiary/LinkFrame.vue");
import Cookies from "js-cookie";
import httpService from "@/http/http-common.js";

export default {
  components: {
    MenuItems,
    TopBar,
    LinkFrame,
    CompanySearch,
    CompanySettings,
  },
  props: {
    user: Object,
    company: Object
  },
  data() {
    return {
      userLink: "",
      token: "",
      customer: null,
      customerMessages: null,
      dataLoaded: false,
      menuSelected: {
        companySearch: true,
        account: false
      },
      config: JSON.parse(sessionStorage.getItem("client_jsonFile")),
      isOpen: false,
      isOpenNotification: false,
      readMessagesCount: 0,
      linkFrameIndex: 1,
      companyMessages: [
        {
          dateIssued: "2021-04-28T21:00:00.000Z",
          dateRead: "2021-05-19T14:06:19.069Z",
          id: 1,
          lang: "ro",
          message:
            "Acest mesaj are peste 100 de caractere! Acest mesaj are peste 100 de caractere! Acest mesaj are peste 100 de caractere! Acest mesaj are peste 100 de caractere!",
          read: true,
          title: "Mesaj lung",
          user_id: 123799,
          validFrom: "2021-04-28T21:00:00.000Z",
          validTo: "2021-05-28T21:00:00.000Z"
        },
      ],
      isMobile: (document.body.clientWidth < 1004),
      showMobileMenu: false
    };
  },

  methods: {
    // loadMessages() {
    //   let formData =
    //     "?user_id=" +
    //     this.customer.company_id +
    //     "&lang=" +
    //     localStorage.language;
    //   httpServiceCustomer.customerService
    //     .getMessages(formData, this.token)
    //     .then(function (response) {
    //       // handle success
    //       return response;
    //     })
    //     .then((response) => {
    //       // total no of messages X-Up-Count
    //       // no of read messages
    //       this.readMessagesCount = Number(response.headers["x-up-count1"]);
    //       // no of unread messages X-Up-Count2
    //       this.customerMessages = response.data;
    //     })
    //     .catch((error) => {
    //       // handle error
    //     });
    // },
    openMenu(open) {
      this.showMobileMenu = open;
    },
    openUserMenu() {
      this.isOpen = !this.isOpen;
    },
    // openNotifications() {
    //   this.loadMessages();
    //   this.isOpenNotification = !this.isOpenNotification;
    // },
    // isReadNotification(message) {
    //   message.read = true;
    //   httpServiceCustomer.customerService
    //     .markMessageAsRead(message, this.token)
    //     .catch((error) => {
    //       // handle success
    //     });
    // },
    hide() {
      this.isOpen = false;
    },
    // hideNotification() {
    //   this.isOpenNotification = false;
    // },
    getPageTitle() {
      return "Company search";
    },
    logout() {
      this.$store.commit('logout');
      Cookies.remove("userLink");
      Cookies.remove("token");
      localStorage.removeItem("customer_data");
    },
    goBack() {
      let foundBackFunction = false;
      let childIndex = null;
      if (foundBackFunction && childIndex !== null) {
        this.$children[childIndex].goBack();
      } else {
        window.history.length > 1
          ? this.$router.go(-1)
          : this.$router.push("/");
      }
    },
    showModalPopup(elementId, show) {
      if (show) {
        document.getElementById(elementId).style.visibility = "visible";
      } else {
        document.getElementById(elementId).style.visibility = "hidden";
      }
    },
    languageChanged() {
      setTimeout(() => {
        this.config = JSON.parse(sessionStorage.getItem("client_jsonFile"));
        this.linkFrameIndex++;
      }, 1000);
    },
  },
  mounted() {

    //  if (this.company.role === "CST" && this.checkNpsSurvey("nps_client") < 4){
    //     this.showModalPopup("modal_popup_nps", true);
    //   }
  },
  created() {
    this.menuSelected.companySearch = true;
    if (!sessionStorage.getItem("client_jsonFile")) {
      httpService.commonsService.getConfigFile(localStorage.language, "client");
    }
  }
};
</script>
<style scoped src="@/assets/styles/beneficiary.css"></style>

