<template>
  <div>
    <div class="loginWrapper">
      <div class="sliderLogin">
        <!--Login box -->
        <div class="loginBox oauth background-white">
          <div class="logo">
            <img src="../../assets/images/up.svg" />
            <div v-if="loginOAUTHStep1">
              <p>{{ $t("title.clientText", { client: this.clientOAuth }) }}</p>
              <p>{{ $t("title.loginTitle") }}</p>
            </div>
            <div v-else-if="loginOAUTHStep2">
              <p>{{ $t("title.selectCardText") }}</p>
              <p>{{ $t("title.selectCardTitleText") }}</p>
            </div>
            <div v-else-if="loginOAUTHStep3">
              <p>{{ $t("title.permissionsTitleText", { client: this.clientOAuth }) }}</p>
            </div>
            <div v-else-if="loginOAUTHStep4">
              <p>{{ $t("title.permissionsText4") }}</p>
            </div>
            <div v-else-if="loginOAUTHFinish">
              <p>{{ $t("title.successOAUTHText1") }}</p>
              <p>{{ $t("title.successOAUTHText2") }}</p>
            </div>
            <p class="loginError" v-if="this.loginError != null" v-html="this.getErrorMessage()"></p>
          </div>

          <div v-if="loginOAUTHStep1" class="loginForm">
            <form @submit.prevent="handleSubmit">
              <input type="text" :placeholder="[
                [
                  this.loginUserSelected
                    ? $t('title.usernameField')
                    : $t('title.userFieldLabel')
                ]
              ]" id="input-2" v-model="formData.username" required="true" />
              <input type="password" :placeholder="[[$t('title.passwordFieldLabel')]]" id="input-3" v-model="formData.password"
                required />
              <p align="right" v-if="loginUserSelected">
                <small>
                  <router-link to="/forgot_password">
                    {{ $t("title.forgotPasswordLink") }}
                  </router-link>
                </small>
              </p>
              <p align="right" v-else>
                <small>
                  <router-link to="/forgot_password_company">
                    {{ $t("title.forgotPasswordLink") }}
                  </router-link>
                </small>
              </p>
              <input type="submit" :value="[[$t('title.loginButtonLabel')]]" variant="primary" class="submitCompanies" />
              <p align="right" v-if="!loginUserSelected">
                <small>
                  <a style="cursor: pointer" @click="showModalPopup('modal_popup_help', true)">{{ $t("title.loginHelpLink")
                  }}</a>
                </small>
              </p>
              <p align="center" v-if="loginUserSelected">
                <small>
                  <router-link to="/create_account">
                    {{ $t("title.createAccountLink") }}
                  </router-link>
                </small>
              </p>
            </form>
          </div>
          <div v-if="loginOAUTHStep4" class="loginForm">
            <form @submit.prevent="sendOtp">
              <input type="text" :placeholder="[[$t('title.otpField')]]" id="input-otp" v-model="otp_password" required />
              <p class="text-right">
                <a href="#" class="link" @click="resendOtp()">{{
                  $t("title.otpResendText")
                }}</a>
              </p>
              <p v-if="otpResendSuccess" class="lightGrey text-center">
                {{ $t("title.msgEmailSuccess") }}
              </p>
              <input type="submit" :value="[[$t('title.confirmButtonLabel')]]" variant="primary" class="submitCompanies" />
            </form>
          </div>
          <div v-else-if="loginOAUTHStep2" class="loginForm">
            <form @submit.prevent="selectCard">
              <div v-if="beneficiaryCardsDetails" class="selectCard">
                <div v-for="(benef, index) in beneficiaryCardsDetails.accounts" :key="index"
                  class="card1 position-relative">
                  <b-form-checkbox v-model="selectedCards" name="selectedCards" :value="benef"
                    class="custom-radio"></b-form-checkbox>
                  <img :src="require('@/assets/images/card_' + benef.product + '.png')" />
                  <p>{{ benef.card.pan }}</p>
                  <pre>{{ benef.card.name }}</pre>
                </div>
              </div>
              <input type="submit" :value="[[$t('title.nextStepButtonLabel')]]" variant="primary" class="submitCompanies" />
            </form>
          </div>
          <div v-else-if="loginOAUTHStep3" class="loginForm">
            <form @submit.prevent="putCardsForBenficiariesOAUTH">
              <ul>
                <li>{{ $t("title.permissionsText1") }}</li>
                <li>{{ $t("title.permissionsText2") }}</li>
                <li>{{ $t("title.permissionsText3") }}</li>
              </ul>
              <input type="submit" :value="[[$t('title.finishButtonLabel')]]" variant="primary" class="submitCompanies" />
            </form>
          </div>
        </div>
        <!--End Login box -->
      </div>
    </div>
  </div>
</template>

<script>
require("@/assets/styles/login.css");
import httpService from "@/http/http-common.js";
import httpServiceBeneficiary from "@/http/http-beneficiary.js";
import utils from "@/utils.js";
import Cookies from "js-cookie";
import { BFormCheckbox } from "bootstrap-vue-3";
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';

export default {
  name: "OAUTH",
  setup() {
    const { getUrlParam } = utils();
    return {
      getUrlParam,
    }
  },
  props: {
    msg: String
  },
  components: {
    "b-form-checkbox": BFormCheckbox
  },
  data() {
    return {
      formData: {
        username: "",
        password: "",
        scope: "",
        userOAuth: ""
      },
      loginUserSelected: true,
      loginError: null,
      options: {
        expires: 10,
        sameSite: "Strict"
      },
      urlRedirect: "",
      clientOAuth: "",
      loginOAUTHStep1: false,
      loginOAUTHStep2: false,
      loginOAUTHStep3: false,
      loginOAUTHStep4: false,
      loginOAUTHFinish: false,
      beneficiaryCardsDetails: null,
      selectedCards: [],
      tokenUserOAUTH: null,
      beneficiaryIdOAUTH: null,
      termsConfirm: true,
      otp_password: null,
      otpResendSuccess: false,
      uuid: null
    };
  },
  methods: {
    handleSubmit() {
      this.authorizeLogin();
    },
    selectLoginUser(isSelectBeneficiaryLogin, fromRouter = false) {
      this.formData.username = "";
      this.formData.password = "";
      this.loginError = null;
      this.loginUserSelected = isSelectBeneficiaryLogin;
      if (!fromRouter) {
      }
    },
    authorizeLogin() {
      httpService.commonsService
        .oAuthLogin(this.formData, this.uuid)
        .then(response => {
          if (response.status == 200) {
            if (response.headers["x-up-oauth"]) {
              if (this.getUrlParam("accord_id")) {
                this.loginError = null;
                this.loginOAUTHStep1 = false;
                if (response.headers["x-up-captcha"] === "required") {
                  this.loginOAUTHStep4 = true;
                } else {
                  this.loginOAUTHStep2 = true;
                }
                let userHeader = response.headers["x-up-user"].split(":");
                this.beneficiaryIdOAUTH = userHeader[0];
                this.tokenUserOAUTH = userHeader[1];
                this.urlRedirect = response.headers["x-up-oauth"];
              } else {
                this.urlRedirect = response.headers["x-up-oauth"];
                window.location.href = this.urlRedirect;
              }
            } else {
              if ((this.formData.scope = "web")) {
                let beneficiaryLink = response.headers["link"].split(";")[0];
                beneficiaryLink = beneficiaryLink.replace(">", "");
                beneficiaryLink = beneficiaryLink.replace("<", "");
                Cookies.set("beneficiaryLink", beneficiaryLink, {
                  expires: response.data.expires_in / (24 * 60 * 60),
                  sameSite: "Strict"
                });
                Cookies.set("token", response.data.access_token, {
                  expires: response.data.expires_in / (24 * 60 * 60),
                  sameSite: "Strict"
                });
                this.$router.push({
                  name: "beneficiary"
                });
              } else {
                let userLink = response.headers["link"].split(";")[0];
                userLink = userLink.replace(">", "");
                userLink = userLink.replace("<", "");
                Cookies.set("userLink", userLink, {
                  expires: response.data.expires_in / (24 * 60 * 60),
                  sameSite: "Strict"
                });
                Cookies.set("token", response.data.access_token, {
                  expires: response.data.expires_in / (24 * 60 * 60),
                  sameSite: "Strict"
                });

                this.$router.push({
                  name: "company"
                });
              }
            }
          } else if (response.status == 400 || response.status == 403) {
            this.loginError = {
              code: response.data.error,
              description: response.data.error_description
            };
          } else if (response.status == 302) {
          } else {
            this.loginError = {
              code: "server_error",
              description: response.description
            };
          }
        })
        .catch(error => {
          // handle error
          if (error.response.status == 400 || error.response.status == 403) {
            this.loginError = {
              code: error.response.data.error,
              description: error.response.data.error_description
            };
          } else if (error.response.status == 302) {
            this.urlRedirect = error.response.headers["Location"];
          } else {
            this.loginError = {
              code: error.response.status,
              description: error.response.data
            };
            return error.response;
          }
        });
    },
    checkLoginPage() {
      let elements = document.getElementsByClassName('login-pages')
      if (elements.length > 0) {
        elements[0].classList.remove('login-pages');
      }
    },
    getErrorMessage() {
      if (this.loginError.description) {
        let index = this.loginError.description.indexOf("_");
        if (index > 0) {
          return this.$t(
            "errorMessage_" + this.loginError.description.substring(0, index),
            {
              param: this.loginError.description.substring(index + 1)
            }
          );
        } else {
          return this.$t("title.errorMessage_" + this.loginError.code);
        }
      } else {
        return this.$t("title.errorMessage_" + this.loginError.code);
      }
    },
    getCardsForBenficiariesOAUTH() {
      httpServiceBeneficiary.beneficiaryService
        .getCardsForBenficiariesOAUTH(this.beneficiaryIdOAUTH, this.tokenUserOAUTH)
        .then(response => {
          if (response.status == 200) {
            this.beneficiaryCardsDetails = response.data;
          }
        })
        .catch(error => {
          this.loginError = {
            code: "server_error",
            description: error.response.description
          };
        });
    },
    putCardsForBenficiariesOAUTH() {
      let idAccountsArray = []
      this.selectedCards.forEach(x => {
        idAccountsArray.push({ id: x.id });
      })
      let formData = {
        id: 14285,
        terms: {
          active: this.termsConfirm,
          date: moment()
        },
        accounts: idAccountsArray
      }

      httpServiceBeneficiary.beneficiaryService
        .putCardsForBenficiariesOAUTH(this.beneficiaryIdOAUTH, formData, this.tokenUserOAUTH)
        .then(response => {
          if (response.status == 200) {
            this.loginOAUTHStep1 = false;
            this.loginOAUTHStep2 = false;
            this.loginOAUTHStep3 = false;
            this.loginOAUTHFinish = true;
            window.location.href = this.urlRedirect;
          }
        })
        .catch(error => {
          // handle error
          this.loginError = {
            code: "server_error",
            description: error.response.description
          };
        });
    },
    selectCard() {
      if (this.selectedCards.length === 0) {
        this.loginError = {
          code: "EMPTY_CARDS",
          description: "The card list is empty"
        };
      } else {
        this.loginError = null;
        this.loginOAUTHStep2 = false;
        this.loginOAUTHStep3 = true;
      }
    },
    resendOtp() {
      let otpData = {
        otp_resend: true,
      };
      this.otpResendSuccess = false;

      httpService.beneficiaryService
        .validateOtp(otpData, this.token)
        .then((response) => {
          if (response.status == 200) {
            this.otpResendSuccess = true;
          } else if (response.status == 400 || response.status == 403) {
            this.registrationError = {
              code: response.data.error_code,
              description: response.data.description,
            };
          } else {
            this.registrationError = { description: response.code };
          }
        })
        .catch((error) => {
          return error.response;
        });
    },
    sendOtp() {
      let otpData = {
        otp: this.otp_password,
        trusted: true
      };

      let headers = {
        Authorization: "Bearer " + btoa(this.tokenUserOAUTH),
        "Content-Type": "application/json",
      };

      httpService.beneficiaryService
        .sendOtp(otpData, headers)
        .then((response) => {
          if (response.status == 200) {
            this.getCardsForBenficiariesOAUTH();
            this.loginOAUTHStep4 = false;
            this.loginOAUTHStep2 = true;
          } else if (response.status == 400 || response.status == 403) {
            this.registrationError = {
              code: response.data.error_code,
              description: response.data.description,
            };
          } else {
            this.registrationError = { description: response.code };
          }
        })
        .catch((error) => {
          return error.response;
        });
    },
  },
  mounted() {
    this.loginOAUTHStep1 = true;
    this.checkLoginPage();
    this.formData.scope = this.getUrlParam("scope");
    this.formData.userOAuth = this.getUrlParam("login_id");
    this.clientOAuth = atob(decodeURIComponent(this.getUrlParam("client_id")));
    let serverError = this.getUrlParam("serverError");
    if (serverError !== null) {
      this.loginError = {
        code: "server_error",
        description: "Server Error"
      };
    }
    Cookies.remove("selectedCompany");
    Cookies.remove("token");
    Cookies.remove("userLink");
    Cookies.remove("first");
    localStorage.removeItem("customer_data");
  },
  created() {
    if (localStorage.getItem("uuid")) {
      this.uuid = localStorage.getItem("uuid");
    } else {
      this.uuid = uuidv4();
    }
  }
};
</script>
<style scoped src="@/assets/styles/login.css"></style>
<style scoped>
.custom-radio {
  position: absolute;
  left: 10%;
  top: 50%;
  transform: translateY(-50%);
}

.loginBox {
  height: auto;
  padding-bottom: 20px;
}
</style>
<style>
.custom-control-input:checked~.custom-control-label::before {
  color: #fff;
  border-color: #007bff;
  background-color: #007bff;
}
</style>
