<template>
  <div>
    <!-- filter invoices -->
    <div class="container-alb filter-mobile">
      <table cellpadding="10" cellspacing="0" width="95%" class="hover-table" align="center">
        <tr>
          <td>
            <img src="@/assets/images/filter.svg" />
            <span class="mobile-show margin-left-5">{{
              $t("title.invoicesFilterTitle")
            }}</span>
          </td>
          <td>
            {{ $t("title.invoicesFilterProduct") }}
            <select class="filtrare" @change="productSelected">
              <option v-for="(entry, index) in affiliate.products" :key="index" :value="entry.brand"
                :selected="isSelectedProduct(entry.brand)">{{ entry.name }}</option>
            </select>
          </td>
          <td>
            <form @submit.prevent="
              ">
              {{ $t("title.cardTransactionFilterSubsidiary") }}
              <select class="filtrare" @change="subsidiarySelected">
                <option selected :value="0">{{ $t("title.selectLabel") }}</option>
                <option v-for="(entry, index) in this.workingPointAddress" :key="index" :value="entry.codcom"
                  :selected="isSelectedSubsidiary(entry.codcom)">{{ customLabel(entry) }}</option>
              </select>
            </form>
          </td>
          <!-- <td>
            {{ $t("title.invoicesFilterStatus") }}
            <select class="filtrare" @change="invoiceStatusSelected">
              <option value="A" selected>{{ $t("title.selectLabel") }}</option>
              <option v-for="(entry, index) in invoiceStatuses" :key="index" :value="entry.value"
                :selected="isSelectedInvoiceStatus(entry.value)">
                {{ $t("title.invoiceStatus_" + entry.value) }}
              </option>
            </select>
          </td> -->
          <td>
            {{ $t("title.invoicesFilterPeriod") }}
            <date-range class="invoices-filter" @close="periodFilterChanged" :time="this.filters.period" />
          </td>
        </tr>
      </table>
    </div>

    <!-- invoice history -->
    <div class="container-alb">
      <div class="table-header-wrapper">
        <div class="left">
          <h3 class="table-title">
            {{ $t("title.lastInvoicesTitle") }}
            <!-- <img class="download" @click="getInvoicesInArchive" src="@/assets/images/download.svg" /> -->
          </h3>
        </div>
      </div>
      <br />
      <InvoiceTableDesktop :company="this.affiliate" :companyInvoices="this.affiliateInvoices"
        :deliveryAddresses="this.workingPointAddress" :pending="false" :userRoleInvTable="this.userRole"
        @change="sortBy(sortField, sortDirection)" />

      <div class="pagination-wrapper border-box">
        <b-pagination v-if="this.affiliateInvoices" v-model="currentPage" :total-rows="this.invoicesCount"
          :per-page="perPage" size="sm"></b-pagination>
      </div>

      <div class="error-wrapper border-box" v-if="this.affiliateInvoices && this.affiliateInvoices.length === 0">
        <p>{{ $t("title.noInvoicesMessage") }}</p>
      </div>
    </div>
    <div id="modal_popup_loading" class="modal-popup">
      <div class="modal-popup-content">
        <img src="@/assets/images/loading.gif" />
        <p v-html="$t('title.loadingPopupMessage')"></p>
      </div>
    </div>
  </div>
</template>

<script>
import InvoiceTableDesktop from "@/components/company/InvoiceTableDesktop.vue";
import utils from "@/utils.js";

import DateRange from "@/components/DateRange.vue";
import httpServiceUser from "@/http/http-user.js";
import httpServiceAffiliate from "@/http/http-affiliate.js";
import { BPagination } from "bootstrap-vue-3";
import Cookies from "js-cookie";
import moment from "moment";
import { saveAs } from "file-saver";

export default {
  setup() {
    const { getUrlParam, base64ToArrayBuffer, getProductById } = utils();
    return {
      getUrlParam,
      base64ToArrayBuffer,
      getProductById
    }
  },
  props: {
    affiliate: Object,
    userRole: String,
    user: Object,
    msg: String
  },
  components: {
    InvoiceTableDesktop,
    DateRange,
    "b-pagination": BPagination,
  },
  data() {
    return {
      invoiceError: null,
      affiliateInvoices: null,
      tempInvoices: null,
      filters: {
        product: "dejun",
        // invoiceStatus: "A",
        period: [moment().subtract(3, "month"), moment()],
        subsidiary: "A"
      },
      // invoiceStatuses: [
      //   { value: "P", description: "Paid" },
      //   { value: "U", description: "Unpaid" },
      // ],
      invoicesCount: 10,
      perPage: 10,
      currentPage: 1,
      sortField: "date",
      sortDirection: "desc",
      subsidiaries: null,
      selectedProduct: null,
      workingPointAddress: [],
      selectedWorkingPointAddress: "",
      detailsMobile: null,
      isLoading: false,
    };
  },
  methods: {
    productSelected(event) {
      this.filters.product = event.target.value;
      this.getAffiliateInvoices();
      this.getAddressPoints(this.filters.product);
    },
    periodFilterChanged(value) {
      this.filters.period = value;
      this.getAffiliateInvoices();
    },
    subsidiarySelected(event) {
      this.filters.subsidiary = event.target.value;
      this.getAffiliateInvoices();
      // this.getDashboardGeneralInfos(this.filters.product);
    },
    isSelectedProduct(product) {
      return product === this.filters.product;
    },
    isSelectedSubsidiary(subsidiary) {
      return subsidiary === this.filters.subsidiary;
    },
    sortBy(sortField, sortDirection) {
      this.sortField = sortField;
      this.sortDirection = sortDirection;
      this.getAffiliateInvoices();
    },
    getAffiliateInvoices() {
      this.affiliateInvoices = null;
      var invoicesData =
        "?type=" + "affiliate" + "&brand=" + this.filters.product;
      if (this.affiliate.user_config && this.affiliate.user_config.config) {
        if (this.affiliate.user_config.config.invoice_group) {
          invoicesData += "&invoice_group=1";
        }
      }
      if (this.filters.subsidiary > 0) {
        invoicesData += "&codall=" + this.filters.subsidiary;
      }

      invoicesData +=
        "&start_date=" +
        moment(this.filters.period[0]).format("YYYYMMDD") +
        "&end_date=" +
        moment(this.filters.period[1]).format("YYYYMMDD");
      // if (this.filters.invoiceStatus !== "A") {
      //   invoicesData += "&paid=" + this.filters.invoiceStatus;
      // } else {
      //   invoicesData += "&paid=U,V,L,R,P";
      // }
      invoicesData += "&per_page=" + this.perPage;
      invoicesData +=
        "&page=" + this.currentPage + "&sort=" + this.sortDirection;
      if (this.sortDirection === "desc") {
        invoicesData += "&desc=" + this.sortField;
      } else {
        invoicesData += "&asc=" + this.sortField;
      }
      httpServiceUser.userService
        .getCompanyInvoices(
          this.affiliate.company_id,
          invoicesData,
          Cookies.get("token")
        )
        .then(function (response) {
          return response;
        })
        .catch(error => {
          this.invoiceError = {
            code: error.response.data,
            description: "Error fetching invoices"
          };
        })
        .then(response => {
          if (response.status == 200) {
            this.affiliateInvoices = response.data;
            this.invoicesCount = Number(response.headers["x-up-count"]); //["Content-Length"];
          } else if (response.status == 400 || response.status == 403) {
            this.invoiceError = {
              code: response.data,
              description: "Error fetching invoices"
            };
          } else {
            this.invoiceError = { description: response.description };
          }
        });
    },
    getAddressPoints(brand) {
      this.isLoading = true;
      var params =
        "?type=affiliate" +
        "&brand=" +
        brand +
        "&per_page=10000&page=1";
      if (this.affiliate.user_config && this.affiliate.user_config.config) {
        if (this.affiliate.user_config.config.invoice_group) {
          params += "&invoice_group=1";
        }
      }

      httpServiceUser.userService
        .getAddressPoints(
          this.affiliate.company_id,
          params,
          Cookies.get("token")
        )
        .then(response => {
          if (response.status == 200) {
            this.subsidiaries = response.data;
            this.isLoading = false;
            this.subsidiaries.forEach(x => {
              if (x.codcom_card) {
                this.workingPointAddress.push({
                  codcom: x.codcom_card,
                  name: x.name,
                  address: x.address.street_name + ", " + x.address.locality
                });
              }
              if (
                !this.workingPointAddress.find(
                  ({ codcom }) => codcom === x.codcom
                )
              ) {
                this.workingPointAddress.push({
                  codcom: x.codcom,
                  name: x.name,
                  address: x.address.street_name + ", " + x.address.locality
                });
              }
            });
          } else if (response.status == 400 || response.status == 403) {
            return null;
          } else {
            return null;
          }
        })
        .catch(error => {
          return null;
        });
    },
    selectProduct(index) {
      this.selectedProduct = this.affiliate.products[index];
      this.getAddressPoints(this.selectedProduct.brand);
      this.getAffiliateInvoices("pending");
      this.affiliateInvoices = this.getAffiliateInvoices();
      // this.getDashboardGeneralInfos(this.filters.product);
    },
    showModalPopup(elementId, show) {
      if (show) {
        document.getElementById(elementId).style.visibility = "visible";
      } else {
        document.getElementById(elementId).style.visibility = "hidden";
      }
    },
    customLabel({ codcom, address }) {
      return `${codcom} – ${address}`;
    },
    getInvoicesInArchive() {
      // retreive customer beneficiaries from service
      let document = {
        company_type: "affiliate",
        brand: this.filters.product,
        start_date: this.filters.period[0],
        end_date: this.filters.period[1],
        paid:
          this.filters.invoiceStatus !== "A"
            ? [this.filters.invoiceStatus]
            : ["L", "R", "P", "U", "V"],
        sort: this.sortDirection,
        per_page: 100000,
        page: 1
      };
      if (this.affiliate.user_config && this.affiliate.user_config.config) {
        if (this.affiliate.user_config.config.invoice_group) {
          document.invoice_group = true;
        } else {
          document.invoice_group = false;
        }
      }
      if (this.filters.subsidiary !== "A") {
        document.codall = [this.filters.subsidiary];
      }

      if (this.sortDirection === "desc") {
        document.desc = [this.sortField];
      } else {
        document.asc = [this.sortField];
      }
      this.showModalPopup("modal_popup_loading", true);
      return httpServiceAffiliate.affiliateService
        .getInvoicesArchive(
          document,
          this.affiliate.company_id,
          Cookies.get("token")
        )
        .then(response => {
          if (response.status == 200) {
            var blob = new Blob(
              [this.base64ToArrayBuffer(response.data.response_content)],
              {
                type: response.data.content_type
              }
            );
            saveAs(blob, this.affiliate.company_id);
            this.showModalPopup("modal_popup_loading", false);
            // download content
          } else if (response.status == 400 || response.status == 403) {
            this.error = { code: 500 };
            this.showModalPopup("modal_popup_loading", false);
            this.showModalPopup("modal_popup_info", true);
          } else if (response.status == 404) {
            // show file not found
            this.error = { code: 500 };

            this.showModalPopup("modal_popup_loading", false);
            this.showModalPopup("modal_popup_info", true);
          } else {
            this.error = { code: 500 };

            this.showModalPopup("modal_popup_loading", false);
            this.showModalPopup("modal_popup_info", true);
          }
        })
        .catch(error => {
          this.error = { code: 500 };
          this.showModalPopup("modal_popup_loading", false);
          this.showModalPopup("modal_popup_info", true);
        });
    },
  },
  mounted() {
    this.filters.period = [moment().subtract(3, "month"), moment()];
    let productURL = this.getUrlParam("product");
    if (productURL) {
      this.filters.product = this.getProductById(
        parseInt(productURL),
        this.affiliate.products
      ).brand;
    } else {
      this.filters.product = this.affiliate.products[0].brand;
    }
    this.filters.subsidiary = "A";
    this.selectProduct(0);
    // this.getDashboardGeneralInfos(0);
  },
  watch: {
    currentPage: function () {
      this.getAffiliateInvoices();
    }
  },
  computed: {
  }
};
</script>

<style scoped src="@/assets/styles/beneficiary.css"></style>
