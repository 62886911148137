export default [
  {
    Companies: [
      {
        fileName: "login/companii/Homepage1.jpg",
        link:
          "#",
        category: "Banner",
        action: "Click",
        label: "companii_login",
      },
      {
        fileName: "login/companii/Homepage2.jpg",
        link:
          "#",
        category: "Banner",
        action: "Click",
        label: "companii_login2",
      },
    ],
    Beneficiaries: [
      {
        fileName: "login/utilizatori/Homepage.jpg",
        link:
          "#",
        category: "Banner",
        action: "Click",
        label: "beneficiari_login",
      },
    ],
  },
];
