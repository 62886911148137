<template>
  <div>
    <div class="menu-items">
      <a href="/company/search" class="search-icon" :class="this.menuSelected.companySearch && 'search-active'">{{
        $t("title.searchName") }}</a>
      <a href="/customer/account" class="amanage" :class="this.menuSelected.account && 'amanage-active'">{{
        $t("title.settingsName") }}</a>

      <a href="#" class="logout" @click="logout()">{{ $t("title.logoutName") }}</a>
    </div>

    <MenuSocials />
  </div>
</template>
<script>
import Cookies from "js-cookie";
import MenuSocials from "@/components/MenuSocials.vue";

export default {
  name: "MenuItemsCustomer",
  components: {
    MenuSocials
  },
  props: {
    user: Object,
    menuSelected: {
      account: false,
      companySearch: false,
    },
  },
  data: function () {
    return {};
  },
  mounted() {
  },
  methods: {
    logout() {
      Cookies.remove("userLink");
      Cookies.remove("token");
      this.$router.push({
        name: "login",
        params: { loginUserSelected: false }
      });
    },
    goBack() {
      window.history.length > 1 ? this.$router.go(-1) : this.$router.push("/");
    },
  }
};
</script>
<style scoped src="@/assets/styles/beneficiary.css"></style>
