// import Vue from "vue";
import {createRouter, createWebHistory} from "vue-router";
import routes from "./routes.js";

import Cookies from "js-cookie";

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
  mode: "history",
	base: process.env.BASE_URL,
	routes: routes,
});

/*
 * acts like a middleware, intercepts each navigation to check for additional rules
 */
router.beforeEach((to, from, next) => {
	// not found handling
	if (to.name === null) {
		let authToken = Cookies.get("token");

		if (typeof authToken === 'undefined') {
			window.location = '/';
		}

		let selectedCompany = Cookies.getJSON("selectedCompany");
		if (selectedCompany) {
			window.location = '/company';
		} else {
			window.location = '/beneficiary';
		}
	}

  next();
})

export default router;
