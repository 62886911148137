<template>
  <div>
    <!-- <div class="language-div">
      <LanguageBar />
    </div> -->

    <div class="loginWrapper">
      <div class="containerSingleBox">
        <!--PAN and code box -->
        <div class="loginBox background-white">
          <div class="logo">
            <img src="../../assets/images/up.svg" />
            <div>
              <p v-show="cardDataSubmitted && this.showPasswordForm && !this.hasAccount">
                {{ $t("title.setPasswordTitle") }}
              </p>
              <p v-show="cardDataSubmitted && this.showPasswordForm && this.hasAccount">
                {{ $t("title.insertPasswordTitle") }}
              </p>
              <p class="loginError" v-if="this.registrationError !== null">
                {{ this.config.errors[this.registrationError.error_code] }}
              </p>
            </div>
          </div>
          <!-- 8445645a-2571-4328-a8be-cafb4db96bf4 -->
          <!-- Begin beneficiary details box -->

          <div class="loginForm" v-if="cardDataSubmitted && !registrationDataConfirmed">
            <form @submit.prevent="submitBeneficiaryDetails">
              <div v-if="!showPasswordForm">
                <div>
                  <p v-if="invitationData.exists" v-html="$t('title.flexAccountAlreadyCreated', {
                      email: invitationData.username,
                    })
                    "></p>
                  <p v-else v-html="$t('title.flexAccountNew')"></p>
                </div>
                <input type="text" :placeholder="[[$t('title.surnameFieldLabel')]]" id="input-first-name"
                  v-model="invitationData.first_name" :disabled="this.invitationData.exists" required />
                <input type="text" :placeholder="[[$t('title.lastNameField')]]" id="input-last-name"
                  v-model="invitationData.last_name" :disabled="this.invitationData.exists" required />
                <input type="text" v-if="invitationData.mobile_phone_required" :placeholder="[[$t('title.mobileField')]]"
                  id="input-mobile" v-model="invitationData.mobile_phone" :style="validateEmailClass" required />
                <input type="text" v-if="invitationData.email_required" :placeholder="[[$t('title.emailField')]]"
                  id="input-mobile" v-model="invitationData.email" :style="validateEmailClass" required />
                <br />
                <div class="form-check" v-if="!invitationData.exists">
                  <input type="checkbox" id="hasAccount" v-model="hasAccount" class="form-check-input" required />
                  <label for="hasAccount">
                    {{ $t("title.accountAlready") }}</label>
                </div>

                <input type="text" :placeholder="[[$t('title.usernameField')]]" id="input-email"
                  v-model="invitationData.username" :style="validateEmailClass" :disabled="this.invitationData.exists"
                  required />
                <div class="form-check">
                  <input type="checkbox" id="terms" v-model="terms" class="form-check-input" required />
                  <label for="terms">
                    {{ $t("title.textReadAndAgree") }}
                    <b><a @click="showModalPopup(true, 'terms')" href="#">{{
                      $t("title.termsAndConditionsName")
                    }}</a></b>
                    <span>{{
                      $t("title.textReadAndAgreeAnd")
                    }}</span>
                    <b><a @click="showModalPopup(true, 'terms_multiben')" href="#">{{
                      $t("title.termsAndConditionsMultibenName") }}</a></b>
                  </label>
                </div>
                <!-- <div class="form-check">
                  <input type="checkbox" id="gdpr" v-model="gdpr" class="form-check-input" required />
                  <label for="gdpr">
                    {{ $t("title.textReadAndAgree") }}
                    <b><a @click="showModalPopup(true, 'gdpr')" href="#">{{
                      $t("title.gdprName")
                    }}</a></b>
                    <span>{{
                      $t("title.textReadAndAgreeAnd")
                    }}</span>
                    <b><a @click="showModalPopup(true, 'gdpr_multiben')" href="#">{{
                      $t("title.gdprMultibenName") }}</a></b>
                  </label>
                </div> -->
                <input type="button" :value="[[$t('title.confirmButtonLabel')]]" variant="primary" class="submitCompanies"
                  @click="showPasswordFields" />


              </div>
              <div v-if="showPasswordForm">
                <div class="password">
                  <input :placeholder="[[$t('title.passwordField')]]" v-model="invitationData.password"
                    @input="validatePassword" required :type="showNewPassword ? 'text' : 'password'" />
                  <i v-on:click="showNewPassword = !showNewPassword">
                    <font-awesome-icon :icon="showNewPassword ? 'eye-slash' : 'eye'" />

                  </i>
                </div>
                <div class="password" v-if="!this.hasAccount">
                  <input :placeholder="[[$t('title.confirmPasswordField')]]" required :style="confirmPasswordStyle"
                    @input="validatePassword" v-model="confirmPassword"
                    :type="showConfirmPassword ? 'text' : 'password'" />
                  <i v-on:click="showConfirmPassword = !showConfirmPassword">
                    <font-awesome-icon :icon="showConfirmPassword ? 'eye-slash' : 'eye'" />

                  </i>
                </div>
                <br />
                <div v-if="!this.hasAccount" class="passwordConditions">
                  <p>
                    <img v-if="!isIE" :src="this.validPassword.passwordLengthValid
                        ? require('../../assets/images/yes.png')
                        : require('../../assets/images/no.png')
                      " />
                    &nbsp; Minimum 8 characters
                  </p>
                  <p>
                    <img v-if="!isIE" :src="this.validPassword.containsLetter
                        ? require('../../assets/images/yes.png')
                        : require('../../assets/images/no.png')
                      " />
                    &nbsp; At least one letter character
                  </p>
                  <p>
                    <img v-if="!isIE" :src="this.validPassword.containsNumber
                        ? require('../../assets/images/yes.png')
                        : require('../../assets/images/no.png')
                      " />
                    &nbsp; At least one numeric digit
                  </p>
                </div>
                <br />

                <input type="submit" :value="[[$t('title.confirmButtonLabel')]]" variant="primary" class="submitCompanies" />
              </div>
            </form>
          </div>
          <div id="modal_popup_terms" class="modal-popup">
            <div class="modal-popup-content">
              <a @click="showModalPopup(false)" class="close-button">
                <img src="../../assets/images/close-button.svg" />
              </a>
              <p>
                <iframe :src="this.docSrc"></iframe>
              </p>
            </div>
          </div>
          <!--End Email box -->
          <!-- Begin congrats registration -->
          <!-- completeRegistrationWithNotifications -->
          <div class="loginForm" v-if="this.registrationDataConfirmed
            ">
            <form @submit.prevent="completeRegistration" align="center">
              <img class="margin20" src="../../assets/images/congrats.png" />

              <h2 align="center">{{ $t("title.successMessageTitle") }}</h2>
              <p align="center" class="lightGrey">
                {{ $t("title.invitationSuccessMessage") }}
                <br />
                <strong>{{ this.invitationData.username }}</strong>
              </p>
              <input type="submit" value="CONFIRM" class="submitCompanies" />
            </form>
          </div>
          <!-- End congrats registration-->
        </div>
      </div>
    </div>
  </div>
</template>
<!-- <script src="https://unpkg.com/vue-recaptcha@^1/dist/vue-recaptcha.min.js"></script> -->
<script>
import httpService from "../../http/http-common.js";
import validator from "../../plugins/fieldValidation";
import utils from "../../plugins/utils.js";
// import LanguageBar from "@/components/LanguageBar.vue";
import VueRecaptcha from "vue-recaptcha-v3";

export default {
  components: {  
    // LanguageBar, 
    VueRecaptcha },
  name: "activate_invite",
  props: {
    msg: String,
  },
  data() {
    return {
      registrationFormData: {
        cardNumber: "",
        code: "",
      },
      token: "",
      confirmPassword: "",
      config: null,
      showNewPassword: false,
      showConfirmPassword: false,
      showPasswordForm: false,
      invitationData: null,
      url: null,
      registrationError: null,
      validPassword: {
        passwordValid: false,
        passwordLengthValid: false,
        containsLetter: false,
        containsNumber: false,
        confirmPasswordValid: false,
      },
      registrationDataConfirmed : false,
      hasAccount: false,
      activationCode: "",
      terms: false,
      gdpr: false,
      docSrc: "",
      isIE: false,
      recaptchaToken: null,
      recaptchaKey: "6Lc4RJ8kAAAAADYYxYgaoCCPXbEg7857BOI-BzpO",
    };
  },
  methods: {
    handleSubmit() {
      this.$refs.recaptcha.execute("recaptcha");
    },
    getBeneficiaryInfosForActivation() {
      httpService.beneficiaryService
        .userGetInvitationInfos("uuid=" + this.activationCode, this.token)
        .then(function (response) {
          return response;
        })
        .catch((error) => {
          return error.response;
        })

        // email : userlogin actual
        // code : email-ul pe care a venit invitatia
        // exists: true / false; daca e true nu mai cer parola

        .then((response) => {
          if (response.status == 200) {
            this.invitationData = response.data;
            this.invitationData.password = "";
            this.registrationError = null;
            this.showPasswordForm = false;
          } else if (response.status == 400) {
            this.invitationData = null;
            this.registrationError = response.data;
            this.showPasswordForm = false;
          } else {
            this.invitationData = null;
            this.registrationError = { error_code: "ISSS" };
                        this.showPasswordForm = false;
          }
        });
    },
    showPasswordFields() {
      this.registrationDataConfirmed = false;
      this.registrationError = null;
      if (
        !this.terms ||
        this.terms === false ||
        !this.gdpr ||
        this.gdpr === false
      ) {
        this.registrationError = {
          description: "Terms",
          error_code: "TERMS",
        };
        return;
      }
      if (this.invitationData.exists) {
        this.hasAccount = true;
        this.submitBeneficiaryDetails();
      } else {
        
        if (!validator.validateEmail(this.invitationData.username) && !validator.validateMobilePhone(this.invitationData.username)) {
          this.registrationError = {
            description: "Invalid username",
            error_code: "IS06",
          };
          return;
        }
        if (this.invitationData.mobile_phone_required && !validator.validateMobilePhone(this.invitationData.mobile_phone)){
          this.registrationError = {
            description: "Invalid mobile phone",
            error_code: "IS04",
          };
          return;
        }
        if (this.invitationData.email_required && !validator.validateEmail(this.invitationData.email)){
          this.registrationError = {
            description: "Invalid email",
            error_code: "LS04",
          };
          return;
        }

        this.showPasswordForm = true;
      }
    },
    submitBeneficiaryDetails() {
      this.registrationDataConfirmed = false;
      if (!this.validPassword.passwordValid && !this.hasAccount ) {
        this.registrationError = {
          description: "Invalid password",
          error_code: "LS03",
        };
        return;
      } else {
        //   this.invitationData.product = 30;
        //   if (this.invitationData.exists) {
        //     this.invitationData.password = null;
        //   }
        // this.invitationData.exists = true;
        this.invitationData.exists = this.hasAccount;
        httpService.beneficiaryService
          .confirmBeneficaryInvite(this.invitationData, this.token)
          .then(function (response) {
            // handle success
            return response;
          })
          .catch((error) => {
            // handle error
            return error.response;
          })
          .then((response) => {
            if (response.status == 200) {
              this.invitationData = response.data;
              this.registrationError = null;
              this.showPasswordForm = false;
            this.registrationDataConfirmed = true;

            } else if (response.status == 400) {
              this.registrationError = response.data;
              this.showPasswordForm = false;
              this.invitationData.exists = false;
              this.hasAccount = false;
              this.invitationData.password = "";

            } else {
              this.registrationError = { error_code: "ISSS" };
              this.showPasswordForm = false;
              this.invitationData.exists = false;
              this.hasAccount = false;
              this.invitationData.password = "";
            }
          });
      }
    },
    completeRegistrationWithNotifications() {
      this.$router.push({
        name: "login",
      });
    },
    validatePassword() {
      this.validPassword = validator.validatePassword(
        this.invitationData.password,
        this.confirmPassword
      );
    },
    completeRegistration() {
      this.registrationFormData = null;
      this.confirmPassword = "";
      this.showPasswordForm = false;
      this.registrationError = null;
      this.validPassword = null;

      if (this.activationCode) {
        this.$router.push({
          name: "login",
          params: { loginUserSelected: true },
        });
      }
    },
    showModalPopup(show, documentKey) {
      if (show) {
        this.docSrc = this.config[documentKey];
        document.getElementById("modal_popup_terms").style.visibility =
          "visible";
      } else {
        document.getElementById("modal_popup_terms").style.visibility =
          "hidden";
      }
    },
    loadConfigData() {
      this.config = JSON.parse(sessionStorage.getItem("mobile_jsonFile"));

      var query = window.location.pathname; // Remove question mark
      var parameters = query.split("/");
      this.activationCode = parameters[parameters.length - 1];

      httpService.beneficiaryService
        .requestTokenForRegistration("webopen")
        .then(function (response) {
          // handle success
          return response.data;
        })
        .then((data) => {
          this.token = data.access_token;
          this.getBeneficiaryInfosForActivation();
        })
        .catch((error) => {
          // handle error
        });
    },
    onCaptchaExpired(recaptcha) {
      this.$refs.recaptcha.reset();
      this.recaptchaToken = false;
    },
    getRecaptchaRP(recaptchaToken) {
      this.recaptchaToken = recaptchaToken;
      this.submitCardNumber()
      this.$refs.recaptcha.reset();

    },
  },
  mounted() {
    this.isIE = /*@cc_on!@*/ false || !!document.documentMode;

    if (!sessionStorage.getItem("mobile_jsonFile")) {
      if (localStorage.language === undefined) {
        localStorage.language = "ro";
      }
      httpService.commonsService
        .getConfigFile(localStorage.language, "mobile")
        .then(function (response) {
          return response;
        })
        .then((data) => {
          this.loadConfigData();
        });
    } else {
      this.loadConfigData();
    }
  },
  computed: {
    cardDataSubmitted() {
      return this.invitationData !== null;
    },
    confirmPasswordStyle() {
      return this.invitationData.password === this.confirmPassword
        ? ""
        : "outline-color: #ff0000a6; ";
    },
    validateEmailClass() {
      return this.invitationData.email === ""
        ? ""
        : validator.validateEmail(this.invitationData.email)
        ? ""
        : "outline-color: #ff0000a6; ";
    },
  },
};
</script>
<style scoped src="@/assets/styles/login.css"></style>
