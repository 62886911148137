<template>
  <div>
    <table cellpadding="10" cellspacing="0" width="95%" class="hover-table invoices-table" align="center">
      <thead>
        <tr>
          <td valign="middle" v-if="pending &&
            (userRoleInvTable === 'AFF')
            "></td>
          <td valign="middle">
            <strong>{{ $t("title.invoiceNumberHeader") }}</strong>&nbsp;
          </td>
          <td class="priority-1" valign="middle">
            <strong>{{ $t("title.invoiceDateHeader") }}</strong>&nbsp;
            <img v-if="pending === false" src="@/assets/images/arrow-down-fill.svg" @click="sortBy('date')" :style="sortByField === 'date' && reverse && 'transform: rotate(180deg)'
              " />
          </td>
          <td class="priority-2" valign="middle" v-if="userRoleInvTable !== 'AFF'">
            <strong>{{ $t("title.invoiceStatusHeader") }}</strong>&nbsp;
            <img v-if="pending === false" src="@/assets/images/arrow-down-fill.svg" @click="sortBy('status')" :style="sortByField === 'status' &&
              reverse &&
              'transform: rotate(180deg)'
              " />
          </td>
          <td class="priority-3" valign="middle">
            <strong>{{ $t("title.invoiceAmountHeader") }}</strong>&nbsp;
            <img v-if="pending === false" src="@/assets/images/arrow-down-fill.svg" @click="sortBy('amount')" :style="sortByField === 'amount' &&
              reverse &&
              'transform: rotate(180deg)'
              " />
          </td>
          <td v-if="userRoleInvTable === 'AFF'" class="priority-4" valign="middle">
            <strong>{{ $t("title.invoiceAffiliateNameHeader") }}</strong>&nbsp;
          </td>
          <!-- <td class="priority-4" valign="middle"  v-if="userRoleInvTable !== 'AFF'">
            <strong>{{ $t("title.invoiceDownloadPDFHeader") }}</strong>&nbsp;
          </td> -->
          <!-- <td v-if="userRoleInvTable === 'AFF'" class="priority-4" valign="middle">
            <strong>{{ $t("title.invoiceDownloadEXLHeader") }}</strong>&nbsp;
          </td> -->
          <td valign="middle" ></td>
          <!-- <td valign="middle" v-if="pending &&
            (userRoleInvTable === 'AFF' )
            "></td> -->
        </tr>
      </thead>
      <tbody>
        <tr v-if="this.companyInvoices === null || this.companyInvoices === undefined
          ">
          <td colspan="12">
            <div class="loading">
              <img src="@/assets/images/loading.gif" />
            </div>
          </td>
        </tr>
        <template v-for="(invoice, index) in companyInvoices" :key="index">
          <tr :id="index" class="trhover parent" :class="index % 2 === 0 && 'gri-cell'">
            <td valign="middle">{{ invoice.invoice }}</td>
            <td valign="middle" class="priority-1">
              {{ displayDate(invoice.date) }}
            </td>
            <td valign="middle" class="priority-2" v-if="userRoleInvTable !== 'AFF'">
              <span class="status-background" :class="selectColorStatus(invoice.status)">{{ $t("title.invoiceStatus_" +
                invoice.status) }}</span>
            </td>
            <td valign="middle" class="priority-3">
              {{ invoice.amount }} {{ $t("title.currency") }}
            </td>
            <td valign="middle" class="priority-4" v-if="userRoleInvTable === 'AFF'" >
              {{ displayAddressName(invoice.external_id) }}
            </td>
            <!-- <td valign="middle" class="priority-4"  v-if="userRoleInvTable !== 'AFF'">
              <a class="invoice-icon cursor-pointer" @click="downloadInvoiceFile(invoice.id)">&nbsp;</a>
            </td> -->
            <!-- <td v-if="userRoleInvTable === 'AFF'" valign="middle" class="priority-4">
              <a v-if="invoice.product.card > 0" class="invoice-excel-icon cursor-pointer"
                @click="downloadInvoiceExcelFile(invoice)">&nbsp;</a>
            </td> -->
            <!-- <td valign="middle">
              
              <a class="see-more" @click="
                showModalPopup('modal_popup_invoices' + pending, true, index)
                ">
                <font-awesome-icon far icon="envelope" size="2x" />
              </a>
            </td> -->

          </tr>
          <tr :id="index + companyInvoices.length" class="child" :class="index % 2 === 0 && 'gri-cell'"
            v-show="detailsMobile && invoice === invoiceSelectedMobile">
            <td colspan="12">
              <p class="priority-show-1">
                <strong>{{ $t("title.invoiceDateHeader") }} : &nbsp;&nbsp;</strong>
                <span>{{ displayDate(invoice.date) }}</span>
              </p>
              <p class="priority-show-2" v-if="userRoleInvTable !== 'AFF'">
                <strong>{{ $t("title.invoiceStatusHeader") }} : &nbsp;&nbsp;</strong>
                <span class="status-background" :class="selectColorStatus(invoice.status)">{{ $t("title.invoiceStatus_" +
                  invoice.status) }}</span>
              </p>
              <p class="priority-show-3">
                <strong>{{ $t("title.invoiceAmountHeader") }} : &nbsp;&nbsp;</strong>
                <span>{{ invoice.amount }} {{ $t("title.currency") }}</span>
              </p>
              <p class="priority-show-4">
                <strong>{{ $t("title.invoiceAffiliateNameHeader") }} : &nbsp;&nbsp;</strong>
                <span>{{ displayAddressName(invoice.external_id)  }}</span>
              </p>
              <!-- <p class="priority-show-4"  v-if="userRoleInvTable !== 'AFF'">
                <strong>{{ $t("title.invoiceDownloadPDFHeader") }} : &nbsp;&nbsp;</strong>
                <span>
                  <a class="invoice-icon cursor-pointer" @click="downloadInvoiceFile(invoice.id)">&nbsp;</a>
                </span>
              </p> -->
              <!-- <p v-if="(userRoleInvTable === 'AFF') &&
                invoice.product.card > 0
                " class="priority-show-4">
                <strong>{{ $t("title.invoiceDownloadEXLHeader") }} : &nbsp;&nbsp;</strong>
                <span>
                  <a class="invoice-icon cursor-pointer" @click="downloadInvoiceExcelFile(invoice)">&nbsp;</a>
                </span>
              </p> -->
            </td>
          </tr>
        </template>
      </tbody>
    </table>
    <!-- 
    <div :id="'modal_popup_invoices' + pending" class="modal-popup">
      <div class="modal-popup-content">
        <a @click="showModalPopup('modal_popup_invoices' + pending, false, -1)" class="close-button">
          <img src="@/assets/images/close-button.svg" />
        </a>
        <div v-if="sentInvoicesError === null">
          <p v-if="!successSentInvoices">
            <input type="email" class="invoices-input formedituser" v-model="emailSender"
              :placeholder="[[$t('title.emailField')]]" />
          </p>
          <a v-if="!successSentInvoices" class="button-orange" @click="sendInvoiceFile(invoiceSelectedMobile.id)">{{
            $t("title.textButtonForm") }}</a>
          <p v-else>{{ $t("title.msgEmailSuccess") }}</p>
        </div>
        <div v-else>
          <p v-if="this.sentInvoicesError" v-html="$t('title.errorActionBeneficiary_500')"></p>
        </div>
      </div>
    </div> -->


    <div :id="'modal_popup_info' + pending" class="modal-popup">
      <div class="modal-popup-content">
        <a @click="showModalPopup('modal_popup_info' + pending, false)" class="close-button">
          <img src="@/assets/images/close-button.svg" />
        </a>
        <div v-if="this.downloadInvoicesError">
          <p v-html="$t('title.errorMessage_' + this.downloadInvoicesError.code)"></p>
        </div>
      </div>
    </div>

    <div :id="'modal_popup_loading' + pending" class="modal-popup">
      <div class="modal-popup-content">
        <img src="@/assets/images/loading.gif" />
        <p v-html="$t('title.loadingPopupMessage')"></p>
      </div>
    </div>
  </div>
</template>
<script>
import httpServiceCustomer from "@/http/http-customer.js";

import { saveAs } from "file-saver";
import Cookies from "js-cookie";
import utils from "@/utils.js";
import moment from "moment";

export default {
  name: "InvoiceTableDesktop",
  setup() {
    const { base64ToArrayBuffer } = utils();
    return {
      base64ToArrayBuffer,
    }
  },
  props: {
    companyInvoices: Array,
    company: Object,
    userRoleInvTable: String,
    msg: String,
    pending: Boolean,
    deliveryAddresses: Array
  },
  data() {
    return {
      detailsMobile: false,
      invoiceSelectedMobile: null,
      sortByField: "date",
      reverse: false,
      // emailSender: null,
      // successSentInvoices: false,
      // sentInvoicesError: null,
      downloadInvoicesError: null,
      terms: false
    };
  },
  methods: {
    displayDate(date) {
      return moment(new Date(date), "YYYY-MM-DDThh:mm:ss").format("DD/MM/YYYY");
    },
    downloadInvoiceFile(invoiceNumber, isExcel) {
      this.showModalPopup("modal_popup_loading" + this.pending, true, -1);
      let link =
        this.company.company_id + "/customer_order_documents/" + invoiceNumber;
      httpServiceCustomer.customerService
        .getOrderDocumentFile(link, Cookies.get("token"), false)
        .then(function (response) {
          return response;
        })

        .then(response => {
          if (response.status == 200) {
            this.showModalPopup(
              "modal_popup_loading" + this.pending,
              false,
              -1
            );
            var blob = new Blob(
              [this.base64ToArrayBuffer(response.data.content)],
              {
                type:
                  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheetapplication/vnd.ms-excel"
              }
            );
            saveAs(blob, response.data.invoice + (isExcel ? ".xls" : ".pdf"));
            this.downloadInvoicesError = null;
          } else if (response.status == 400 || response.status == 403) {
            this.downloadInvoicesError = { code: response.status };
            this.deliveryAddresses = null;
            this.showModalPopup(
              "modal_popup_loading" + this.pending,
              false,
              -1
            );
            this.showModalPopup("modal_popup_info" + this.pending, true, -1);
          } else if (response.status == 404) {
            this.downloadInvoicesError = { code: response.status };
            this.showModalPopup(
              "modal_popup_loading" + this.pending,
              false,
              -1
            );
            this.showModalPopup("modal_popup_info" + this.pending, true, -1);
          } else {
            this.downloadInvoicesError = { code: "503" };
            this.showModalPopup(
              "modal_popup_loading" + this.pending,
              false,
              -1
            );
            this.showModalPopup("modal_popup_info" + this.pending, true, -1);
          }
        })
        .catch(error => {
          this.downloadInvoicesError = { code: "503" };
          this.deliveryAddresses = null;
          this.showModalPopup("modal_popup_loading" + this.pending, false, -1);
          this.showModalPopup("modal_popup_info" + this.pending, true, -1);
        });
    },
    downloadInvoiceExcelFile(invoice) {
      let document = "212" + invoice.id.substring(3);
      this.downloadInvoiceFile(document, true);
    },
    // sendInvoiceFile(invoiceNumber) {
    //   let formData = {
    //     code: this.company.company_id,
    //     invoice: invoiceNumber,
    //     email_sender: this.emailSender
    //   };
    //   httpServiceCustomer.customerService
    //     .sentEmailInvoiceFile(Cookies.get("token"), formData)
    //     .then(function (response) {
    //       return response;
    //     })
    //     .catch(error => {
    //       this.sentInvoicesError = {
    //         code: error.response.status
    //       };
    //     })
    //     .then(response => {
    //       if (response.status == 200) {
    //         this.successSentInvoices = true;
    //       } else if (
    //         response.status == 400 ||
    //         response.status == 403 ||
    //         response.status == 404
    //       ) {
    //         this.sentInvoicesError = response.response;
    //       }
    //     });
    // },
    selectInvoiceMobile(index) {
      this.invoiceSelectedMobile = this.companyInvoices[index];
      this.detailsMobile = !this.detailsMobile;
    },
    sortBy(fieldName) {
      if (this.sortByField !== fieldName) {
        this.reverse = false;
      }
      this.sortByField = fieldName;
      this.reverse = !this.reverse;
      this.$emit("change", fieldName, this.reverse ? "asc" : "desc");
    },
    selectColorStatus(status) {
      switch (status) {
        case "P":
        case "L":
        case "R":
          return "background-active";
        case "U":
        case "Not paid":
          return "background-will-expire";
        case "O":
          return "background-suspended";
        default:
          return "background-will-expire";
      }
    },
    displayAddressName(invoiceAddress) {
      for (let index in this.deliveryAddresses) {
        if (invoiceAddress === this.deliveryAddresses[index].codcom + "") {
          return this.deliveryAddresses[index].name + " (" + this.deliveryAddresses[index].address + ")";
        }
      }
      return "";
    },
    showModalPopup(elementId, show, index) {
      if (index >= 0) {
        this.invoiceSelectedMobile = this.companyInvoices[index];
      }
      if (show) {
        document.getElementById(elementId).style.visibility = "visible";
      } else {
        document.getElementById(elementId).style.visibility = "hidden";
      }
    },
  },
};
</script>
<style scoped src="@/assets/styles/beneficiary.css"></style>
