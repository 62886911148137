export default [
    {
        fileName: "cross sell 1.jpeg",
        link:
            "https://upmoldova.md/tichete-cadou/",
        label: "Up Cadou",
    },
    {
        fileName: "cross sell 2.jpeg",
        link:
            "https://upmoldova.md/tichete-de-masa/",
        label: "Up Dejun",
    },
];
