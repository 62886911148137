<template>
  <div class="loginWrapper">
    <div class="containerSingleBox">
      <!--Login box -->
      <div class="loginBox background-white">
        <div class="logo">
          <img src="../../assets/images/up.svg" />
          <br />
          <br />
          <div v-if="!loading">
            <h2 v-if="this.newPasswordData === null && !this.resetComplete">
              {{ $t("title.forgotPasswordTitle") }}
            </h2>
            <h2 v-if="this.newPasswordData !== null && !this.resetComplete">
              {{ $t("title.resetPasswordTitle") }}
            </h2>
            <p
              class="lightGrey"
              v-if="this.newPasswordData === null && !this.resetComplete"
              v-html="$t('title.forgotPasswordMessageCompany')"
            ></p>
            <p
              class="lightGrey"
              v-if="
                this.newPasswordData !== null &&
                  !this.resetComplete &&
                  !this.registrationError
              "
              v-html="$t('title.resetPasswordMessageCompany')"
            ></p>
            <p class="loginError" v-if="this.registrationError != null">
              {{ this.config.errors[this.registrationError.error_code] }}
            </p>
          </div>
        </div>

        <div
          class="loginForm"
          v-if="this.newPasswordData === null && this.resetComplete"
        >
          <form @submit.prevent="sendResetPasswordEmail">
            <!-- Begin congrats mail sent -->
            <div class="loginForm">
              <form @submit.prevent="completePasswordReset" align="center">
                <img src="../../assets/images/congrats.png" />

                <h2 align="center">{{ $t("title.successMessageTitle") }}</h2>
                <p align="center" class="lightGrey">
                  {{ $t("title.resetPasswordSuccessMessageCompany") }}
                </p>
                <input type="submit" value="CONFIRM" class="submitCompanies" />
              </form>
            </div>
            <!-- End congrats mail sent -->
          </form>
        </div>
        <div
          class="loginForm"
          v-if="this.newPasswordData === null && !this.resetComplete"
        >
          <form @submit.prevent="sendResetPasswordEmail">
            <div v-if="loading" class="loading">
              <img src="@/assets/images/loading.gif" class="w-100" />
            </div>
            <div v-else>
              <input
                type="text"
                :placeholder="[[$t('title.companyCodeOrEmailLabel')]]"
                id="input-CompanyCode"
                v-model="formData.companyCode"
                required
                @keyup="updateInputCharacters()"
              />
              <input
                type="number"
                :placeholder="[[$t('title.fiscalCodeOrCodeCompanyLabel')]]"
                id="input-passs-token"
                v-model="formData.passToken"
                required
              />
              <input
                type="submit"
                :value="[[$t('title.confirmButtonLabel')]]"
                variant="primary"
                class="submitCompanies"
              />
            </div>
          </form>
        </div>
        <div
          class="loginForm"
          v-if="this.newPasswordData !== null && !this.resetComplete"
        >
          <form @submit.prevent="resetCustomerPassword">
            <!-- change password form -->
            <div v-if="this.newPasswordData !== null && !this.resetComplete">
              <div class="password">
                <input :placeholder="[[$t('title.passwordField')]]" v-model="newPasswordData.new_password"
                  @input="validatePassword" required :type="showNewPassword ? 'text' : 'password'" />
                <i v-on:click="showNewPassword = !showNewPassword">
                  <font-awesome-icon :icon="showNewPassword ? 'eye-slash' : 'eye'" />

                </i>
              </div>
              <div class="password">
                <input :placeholder="[[$t('title.confirmPasswordField')]]" required :style="confirmPasswordStyle"
                  @input="validatePassword" v-model="confirmPassword" :type="showConfirmPassword ? 'text' : 'password'" />
                <i v-on:click="showConfirmPassword = !showConfirmPassword">
                  <font-awesome-icon :icon="showConfirmPassword ? 'eye-slash' : 'eye'" />

                </i>
              </div>
              <input
                type="submit"
                :value="[[$t('title.confirmButtonLabel')]]"
                variant="primary"
                class="submitCompanies"
              />
            </div>
          </form>
        </div>
        <!-- Begin congrats -->
        <div
          class="loginForm"
          v-if="this.newPasswordData != null && this.resetComplete"
        >
          <form @submit.prevent="completePasswordReset" align="center">
            <img src="../../assets/images/congrats.png" />

            <h2 align="center">{{ $t("title.successMessageTitle") }}</h2>
            <p align="center" class="lightGrey">
              {{ $t("title.resetPasswordSuccessMessage") }}
            </p>
            <input type="submit" value="CONFIRM" class="submitCompanies" />
          </form>
        </div>
        <!-- End congrats -->
      </div>
      <!--End Login box -->
    </div>
  </div>
</template>

<script>
import utils from "@/utils.js";
import httpCommon from "../../http/http-common.js";

export default {
  name: "forgot_password_company",
  setup() {
    const { getUrlParam } = utils();

    return {
      getUrlParam
    }
  },
  props: {
    msg: String
  },
  data() {
    return {
      formData: {
        companyCode: "",
        passToken: ""
      },
      token: null,
      registrationError: null,
      showNewPassword: false,
      showConfirmPassword: false,
      resetComplete: false,
      newPasswordData: null,
      confirmPassword: "",
      category: "",
      config: null,
      loading: false
    };
  },
  methods: {
    loginCustomer() {
      let passData = {
        username: this.formData.companyCode,
        email: this.formData.companyCode,
        pass_token: this.formData.passToken
      };
      this.loading = true;
      httpCommon.companyService
        .userCheckForPasswordReset(passData, this.token)
        .then(function(response) {
          // handle success
          return response;
        })
        .catch(error => {
          // handle error
          this.loading = false;
          this.registrationError = {
            code: "server_error",
            description: error.response.code
          };
          return error.response;
        })
        .then(response => {
          this.loading = false;
          if (response.status == 200) {
            this.resetComplete = true;
            // do congrats
          } else if (response.status == 400) {
            this.registrationError = response.data;
          } else {
            this.registrationError = {
              error_code: "server_error",
              description: response.code
            };
          }
        });
    },
    sendResetPasswordEmail() {
      this.getTokenForValidateCredentials();
    },
    getTokenForValidateCredentials(resetCode) {
      httpCommon.companyService
        .requestTokenForRegistration("clientopen")
        .then(function(response) {
          // handle success
          return response.data;
        })
        .then(data => {
          this.token = data.access_token;
          // validate client credentials
          if (resetCode) {
            this.checkPasswordResetCode(resetCode);
          } else {
            if (this.newPasswordData === null) {
              this.loginCustomer();
            } else {
              this.resetCustomerPassword();
            }
          }
        })
        .catch(error => {
          // handle error
        });
    },
    completePasswordReset() {
      this.registrationError = null;
      this.formData = null;
      this.resetComplete = false;

      this.$router.push({
        name: "login",
        params: { loginUserSelected: false }
      });
    },
    resetCustomerPassword() {
      let passwordData = {
        auth_password: this.newPasswordData.reset_code,
        new_password: this.newPasswordData.new_password,
        password_id: 1
      };
      this.registrationError = null;
      httpCommon.companyService
        .companyPasswordReset(passwordData, this.token)
        .then(function(response) {
          // handle success
          return response;
        })
        .catch(error => {
          // handle error
          this.registrationError = error.response.data;
          return error.response;
        })
        .then(response => {
          if (response.status == 200) {
            this.registrationError = null;
            this.resetComplete = true;
            // do congrats
          } else if (response.status == 400) {
            this.registrationError = response.data;
          } else {
            this.registrationError = {
              error_code: response.data.error_code,
              description: response.data.error_code
            };
          }
        });
    },
    checkPasswordResetCode(resetCode) {
      this.newPasswordData = {
        reset_code: resetCode,
        new_password: ""
      };
      httpCommon.companyService
        .userResetCodeCheck("resetCode=" + resetCode, this.token)
        .then(function(response) {
          // handle success
          return response;
        })

        .then(response => {
          if (response.status == 200) {
            this.passwordData = {
              password_id: 1,
              auth_password: resetCode
            };
            // do congrats
          } else if (response.status == 400) {
            this.registrationError = response.data.error_code;
          } else {
            this.registrationError = {
              error_code: response.data.error_code,
              description: response.data.error_code
            };
          }
        })
        .catch(error => {
          // handle error
          this.registrationError = error.response.data;
          return error.response;
        });
    },
    confirmPasswordStyle() {
      return this.confirmPassword === ""
        ? ""
        : this.newPasswordData.new_password === this.confirmPassword
        ? ""
        : "outline-color: #ff0000a6;";
    },
    onlyAlphaNumericCharacters(variable) {
      if (/^[a-zA-Z0-9@.]*$/i.test(variable)) {
        return variable;
      } else {
        return variable.replace(/[&\/\\|`#\[\]\,+=()$~%'":*!?<>{}]/g, "");
      }
    },
    updateInputCharacters() {
      this.formData.companyCode = this.onlyAlphaNumericCharacters(
        this.formData.companyCode
      );
      this.$forceUpdate();
    }
  },
  mounted() {
    if (this.$gtm) {
      this.$gtm.enable(false);
    }
    let resetCode = this.getUrlParam("resetCode");
    this.category = this.getUrlParam("category");

    if (resetCode !== null) {
      this.getTokenForValidateCredentials(resetCode);
    } else {
      this.newPasswordData = null;
    }
    if (!sessionStorage.getItem("mobile_jsonFile")) {
      httpCommon.commonsService
        .getConfigFile(localStorage.language, "mobile")
        .then(function(response) {
          return response;
        })
        .then(data => {
          this.config = JSON.parse(
            sessionStorage.getItem("mobile_jsonFile")
          );
        });
    } else {
      this.config = JSON.parse(sessionStorage.getItem("mobile_jsonFile"));
    }
  }
};
</script>
<style scoped src="@/assets/styles/login.css"></style>
