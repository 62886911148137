let emailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
let mobilePhoneRegex = /(07[2-9][0-9]{7})/;
let gpsCoordonateRegex = /^([\d]{1,2})((\.)([\d]{6}))$/;

export default {
	validatePassword(password, confirmPassword) {
		var passwordLengthValid = false;
		var containsLetter = false;
		var containsNumber = false;
		var passwordLength = password.length;
		var validPassword = false;
		var confirmPasswordValid = false;
		if (passwordLength > 7) {
			passwordLengthValid = true;
		}
		containsLetter = /[a-z]|[A-Z]/.test(password);
		containsNumber = /\d/.test(password);
		if (containsLetter && containsNumber) {
			validPassword = false;
			if (passwordLengthValid) {
				if (password == confirmPassword) {
					confirmPasswordValid = true;
					validPassword = true;
				}
			}
		}

		return {
			passwordValid: validPassword,
			passwordLengthValid: passwordLengthValid,
			containsLetter: containsLetter,
			containsNumber: containsNumber,
			confirmPasswordValid: confirmPasswordValid,
		};
	},
	validateEmail(email) {
		return emailRegex.test(email);
	},
	validateMobilePhone(mobilePhone) {
		return mobilePhoneRegex.test(mobilePhone);
	},
  validateGPSCoordonate(coord) {
    return gpsCoordonateRegex.test(coord)
  }
};
