//import ref function to define reactive properties
import { ref } from 'vue'
export default function utils() {

	// const reuseData = ref ("Reusable data")
	function titleCase(value) {
		if (!value) return "";
		value = value
			.toLowerCase()
			.replace("-", " ")
			.split(" ");
		for (var i = 0; i < value.length; i++) {
			value[i] = value[i].charAt(0).toUpperCase() + value[i].slice(1);
		}
		return value.join(" ");
	}
	function checkNotEmptyObj(obj) {
		if (Array.isArray(obj) && obj.length > 0) {
			return true;
		}
		return (
			typeof obj !== "undefined" &&
			obj !== null &&
			!(
				(obj.constructor === Array || obj.constructor === Object) &&
				Object.keys(obj).length === 0
			)
		);
	}
	function scrollToTop(pixels = 0) {
		window.scrollTo({ top: pixels, behavior: "smooth" });
	}
	function formatDate(date, format, fromFormat) {
		return moment(date, fromFormat).format(format);
	}
	function sendClickToGA(category, action, label) {
		if (this.$ga) {
			this.$ga.event({
				eventCategory: category,
				eventAction: action,
				eventLabel: label,
			});
		}
	}
	function checkNpsSurvey(type) {
		let x = parseInt(
			Cookies.get(type + "_count")
				? Cookies.get(type)
				: 0
		);
		return Cookies.get(type) ? 5 : x;
	}
	function showModalPopup(elementId, show) {
		if (show) {
			document.getElementById(elementId).style.visibility = "visible";
		} else {
			document.getElementById(elementId).style.visibility = "hidden";
		}
	}
	function getUrlParam(name) {
		var query = window.location.search.substring(1); // Remove question mark
		var parameters = query.split("&");

		for (var i = 0; i < parameters.length; i++) {
			var pair = parameters[i].split("=");

			if (pair[0] == name) {
				return pair[1];
			}
		}

		return null;
	}
	function openWindowWithPost(url, params) {
		var form = document.createElement("form");
		form.setAttribute("method", "post");
		form.setAttribute("action", url);
		if (url.includes("mobilpay")) {
			form.setAttribute("target", "_blank");
		}

		for (var i in params) {
			if (params.hasOwnProperty(i)) {
				var input = document.createElement("input");
				input.type = "hidden";
				input.name = i;
				input.value = params[i];
				form.appendChild(input);
			}
		}

		document.body.appendChild(form);

		//note I am using a post.htm page since I did not want to make double request to
		//the page it might have some Page_Load call which might screw things up.
		// window.open("post.htm");

		form.submit();

		document.body.removeChild(form);
	}
	function getProductName(productId) {
		var cards = JSON.parse(sessionStorage.getItem("mobile_jsonFile"))
			.cards;
		for (var index in cards) {
			var product = cards[index];
			if (product.product === productId) {
				return product.name;
			}
		}
		return "";
	}
	function getCompanyProductName(productId, productList) {
		let companyProduct = this.getProductById(productId, productList);
		let configProducts = null;
		if (sessionStorage.getItem("client_jsonFile")) {
			configProducts = JSON.parse(sessionStorage.getItem("client_jsonFile"))
				.products;
		} else if (sessionStorage.getItem("affiliate_jsonFile")) {
			configProducts = JSON.parse(sessionStorage.getItem("affiliate_jsonFile"))
				.products;
		}
		for (var index in configProducts) {
			var product = configProducts[index];
			if (!productId.toString().startsWith("15")) {
				productId = parseInt(productId.toString().substring(0, 2));
			}
			if (
				productId.toString().length > 2 &&
				productId === parseInt(product.card + "" + product.card_profile)
			) {
				return product.name;
			} else if (
				productId.toString().length <= 2 &&
				(product.card === companyProduct.card + "")
			) {
				return product.name;
			}
		}
		return "";
	}
	function getProductById(productId, productList) {
		for (var index in productList) {
			var product = productList[index];
			if (
				productId.toString().length > 2 &&
				productId === parseInt(product.card + "" + product.card_profile)
			) {
				return product;
			} else if (
				product.card + "" === productId + ""
			) {
				return product;
			}
		}
		return "";
	}
	function formatPAN(pan) {
		return (
			pan.substring(0, 4) +
			" " +
			pan.substring(4, 8) +
			" " +
			pan.substring(8, 12) +
			" " +
			pan.substring(12, 16)
		);
	}
	function formatAmount(value) {
		let val = (value / 1).toFixed(2).replace(".", ",");
		return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
	}
	function selectColorStatus(status) {
		switch (status) {
			case "A":
			case "Z":
				return "background-blocked";
			case "V":
			case "L":
				return "background-active";
			case "S":
			case "N":
			case "P":
			case "R":
			case "PR":
			case "PV":
				return "background-will-expire";
			case "X":
				return "background-expired";
			default:
				return "background-will-expire";
		}
	}
	function base64ToArrayBuffer(base64) {
		var binaryString = window.atob(base64);
		var binaryLen = binaryString.length;
		var bytes = new Uint8Array(binaryLen);
		for (var i = 0; i < binaryLen; i++) {
			var ascii = binaryString.charCodeAt(i);
			bytes[i] = ascii;
		}
		return bytes;
	}
	function uploadFile(fileReceived) {
		var content = "maria";
		var reader = new FileReader();
		reader.onload = (e) => {
			if (!e) {
				content = btoa(reader.content);
			} else {
				content = btoa(e.target.result);
			}
		};
		//For Browsers other than IE.
		if (reader.readAsBinaryString) {
			reader.readAsBinaryString(fileReceived);
		} else {
			alert("This browser does not support HTML5.");
		}

		return content;
	}
	return {
		titleCase,
		checkNotEmptyObj,
		checkNpsSurvey,
		scrollToTop,
		formatDate,
		formatPAN,
		formatAmount,
		sendClickToGA,
		showModalPopup,
		getUrlParam,
		getProductById,
		getCompanyProductName,
		getProductName,
		openWindowWithPost,
		base64ToArrayBuffer,
		uploadFile,
		selectColorStatus
	}
}