<template>
  <div>
    <div class="account-title">
      <h1>{{ $t("title.companySettingsTitle") }}</h1>
      <!-- <p>{{ $t("title.userSettingsDescription") }}</p> -->
    </div>
    <div class="content-delivery mb-5">
      <div class="second-tabs">
        <h3 v-if="!user.username.includes('@')">
          <img src="@/assets/images/company-icon.svg" />
          &nbsp;&nbsp;&nbsp;{{ companyDetails.name }}
        </h3>
        <h3 v-else>
          {{ user.first_name + " " + user.last_name }}
        </h3>
        <div class="grey-info">
          <b-container fluid class="border-box">
            <b-row class="border-box">
              <b-col v-if="!user.username.includes('@')" class="border-box grey-info" xl="6" md="6">
                <p>&nbsp;</p>
                <p>
                  <small>{{ $t("title.companyCodeLabel") }}</small>
                  <br />
                  <input disabled type="text" class="formedituser" v-model="companyDetails.company_id" />
                </p>
                <p class="position-relative">
                  <small>{{ $t("title.fiscalCodeLabel") }}</small>
                  <br />
                  <input disabled type="text" class="formedituser" v-model="companyDetails.fiscal_code" />
                </p>
                <p class="position-relative">
                  <small>{{ $t("title.invoiceAddressLabel") }}</small>
                  <br />
                  <input disabled type="text" class="formedituser" v-model="companyDetails.address" />
                </p>
              </b-col>
              <b-col v-else class="border-box grey-info" xl="6" md="6">
                <p>&nbsp;</p>
                <p>
                  <small>{{ $t("title.surnameFieldLabel") }}</small>
                  <br />
                  <input id="first_name" disabled type="text" class="formedituser" v-model="formData.firstName"
                    @keyup="updateInputCharacters()" />
                </p>
                <p>
                  <small>{{ $t("title.lastNameField") }}</small>
                  <br />
                  <input id="last_name" disabled type="text" class="formedituser" v-model="formData.lastName"
                    @keyup="updateInputCharacters()" />
                </p>
                <p>
                  <small>{{ $t("title.emailField") }}</small>
                  <br />
                  <input id="email" disabled type="email" class="formedituser" v-model="user.email" />
                </p>
                <!-- <p>
                  <small>{{ $t("title.mobileField") }}</small>
                  <br>
                  <input
                    id="mobile_phone"
                    :disabled="!editMode"
                    type="text"
                    class="formedituser"
                    v-model="formData.mobilePhone"
                    @keyup="updateInputCharacters()"
                  />
                </p> -->
                <p v-if="!this.editMode &&
                  customer &&
                  customer.role &&
                  customer.role !== 'FAC' &&
                  !customer.state_owned &&
                  customer.user_config &&
                  customer.user_config.roles &&
                  customer.user_config.roles.includes('EDITOR')
                  ">
                  <a class="notification-action custom-notification-action" @click="editData()">{{
                    $t("title.editButtonLabel")
                  }}</a>
                </p>
                <p v-if="this.editMode" class="d-flex align-items-center justify-content-between">
                  <a class="notification-action" @click="saveNewData()">{{
                    $t("title.saveChangesLabel")
                  }}</a>
                  <a class="resetButton" @click="resetData()">{{
                    $t("title.cancelChangesLabel")
                  }}</a>
                </p>
              </b-col>
              <b-col class="border-box grey-info" offset-xl="2" offset-md="2" xl="4" md="4">
                <form>
                  <small>{{ $t("title.placeholderOldPassword") }}</small>
                  <br />
                  <div class="password">
                    <input v-model="oldPassword" required class="formedituser"
                      :type="showPassword ? 'text' : 'password'" />
                    <i v-on:click="showPassword = !showPassword">
                      <font-awesome-icon :icon="showPassword ? 'eye-slash' : 'eye'" />

                    </i>
                  </div>
                  <small>{{ $t("title.passwordField") }}</small>
                  <br />
                  <div class="password">
                    <input v-model="newPassword" @input="validatePassword" required class="formedituser"
                      :type="showNewPassword ? 'text' : 'password'" />
                    <i v-on:click="showNewPassword = !showNewPassword">
                      <font-awesome-icon :icon="showNewPassword ? 'eye-slash' : 'eye'" />

                    </i>
                  </div>
                  <small>{{ $t("title.confirmPasswordField") }}</small>
                  <br />
                  <div class="password">
                    <input required :style="confirmPasswordStyle" @input="validatePassword" v-model="confirmPassword"
                      class="formedituser" :type="showConfirmPassword ? 'text' : 'password'" />
                    <i v-on:click="showConfirmPassword = !showConfirmPassword">
                      <font-awesome-icon :icon="showConfirmPassword ? 'eye-slash' : 'eye'" />

                    </i>
                  </div>
                  <p>
                    <img v-if="!isIE" :src="this.validPassword.passwordLengthValid
                      ? require('../../assets/images/yes.png')
                      : require('../../assets/images/no.png')
                      " />
                    &nbsp; {{ $t("title.passwordCriteria1Text") }}
                  </p>
                  <p>
                    <img v-if="!isIE" :src="this.validPassword.containsLetter
                      ? require('../../assets/images/yes.png')
                      : require('../../assets/images/no.png')
                      " />
                    &nbsp; {{ $t("title.passwordCriteria2Text") }}
                  </p>
                  <p>
                    <img v-if="!isIE" :src="this.validPassword.containsNumber
                      ? require('../../assets/images/yes.png')
                      : require('../../assets/images/no.png')
                      " />
                    &nbsp; {{ $t("title.passwordCriteria3Text") }}
                  </p>
                  <br />
                  <p>
                    <a @click="submitUserPassword()" class="notification-action">{{ $t("title.changePasswordLabel") }}</a>
                  </p>
                </form>
              </b-col>
            </b-row>
          </b-container>
        </div>
        <br />
      </div>
    </div>
    <div v-if="user.username.includes('@')" class="content-delivery mb-5">
      <div class="second-tabs">
        <div class="">
          <h3 class="title-address-table">
            <strong>{{ $t("title.manageCompaniesTitleSection") }}</strong>
          </h3>
          <form>
            <select class="filtrare" @change="subsidiarySelected">
              <option selected :value="0">{{ $t("title.selectLabel") }}</option>
              <option v-for="(entry, index) in this.manageCompanies" :key="index" :value="entry.company_id"
                :selected="isSelectedSubsidiary(entry.company_id)">{{ customLabel(entry) }}</option>
            </select>
            <!-- <multiselect v-model="selectedManageCompany" :options="manageCompanies" :placeholder="$t('title.selectLabel')"
              select-label="" selected-label="" deselect-label="" openDirection="bottom" track-by="company_id"
              :custom-label="customLabel" :loading="isLoading" @input="subsidiarySelected()">
            </multiselect> -->
          </form>
        </div>
        <table cellpadding="10" cellspacing="0" width="100%" class="hover-table invoices-table" align="center">
          <thead>
            <tr>
              <td>
                <strong>{{ $t("title.searchByCompanyName") }}</strong>
              </td>
              <td>
                <strong>{{ $t("title.codallLabel") }}</strong>
              </td>
              <td class="priority-4">
                <strong>{{ $t("title.typeUPPartner") }}</strong>
              </td>
              <td class="priority-5">
                <strong>{{ $t("title.roleLabel") }}</strong>
              </td>
              <td class="priority-5">
                <strong>{{ $t("title.invoicesFilterStatus") }}</strong>
              </td>
              <td>
                <strong>{{ $t("title.beneficiariesTableAction") }}</strong>
              </td>
            </tr>
          </thead>
          <tbody>
            <tr v-if="this.manageCompanies === null ||
              this.manageCompanies === undefined
              ">
              <td colspan="6">
                <div class="loading">
                  <img src="@/assets/images/loading.gif" />
                </div>
              </td>
            </tr>
            <template v-for="(comp, index) in filterManageCompany" :key="index">
              <tr :id="index" class="trhover parent" :class="index % 2 === 0 && 'gri-cell'">
                <td valign="middle">
                  <a class="
                      detailsOrderMobile
                      priority-show-5
                      display-inline-block
                    " @click="selectManageCompanyMobile(index)">
                    <font-awesome-icon icon="minus-circle" v-if="detailsMobile && comp === manageCompanySelectedMobile
                      " />
                    <font-awesome-icon icon="plus-circle" v-else />&nbsp;&nbsp;
                  </a>
                  <strong>{{ comp.name }}</strong>
                </td>
                <td valign="middle">
                  {{ comp.company_id }}
                </td>
                <!-- <td valign="middle" class="priority-3">
                  {{ comp.fiscal_code }}
                </!-->
                <td valign="middle" class="priority-4">
                  {{ $t("title.userTypeLabel_" + comp.role) }}
                </td>
                <td valign="middle" class="priority-5">
                  <span v-if="comp.user_config">{{
                    $t("title.userModeLabel_" + comp.user_config.roles[0])
                  }}</span>
                </td>
                <td valign="middle" class="priority-5">
                  {{ $t("title.companyStatus_" + comp.active) }}
                </td>
                <td valign="middle">
                  <a class="action-button mb-1" @click="confirmDeleteCompany(comp)">
                    <span>{{ $t("title.deletePOSLabel") }}</span>
                  </a>
                </td>
              </tr>
              <tr :id="index + manageCompanies.length" class="child" :class="index % 2 === 0 && 'gri-cell'"
                v-show="detailsMobile && comp === manageCompanySelectedMobile">
                <td colspan="12">
                  <!-- <p class="priority-show-3">
                    <strong >{{ $t("title.vat") }} : &nbsp;&nbsp;</strong>
                    <span>{{ comp.fiscal_code }}</span>
                  </p> -->
                  <p class="priority-show-4">
                    <strong>{{ $t("title.typeUPPartner") }} : &nbsp;&nbsp;</strong>
                    <span>{{ $t("title.userTypeLabel_" + comp.role) }}</span>
                  </p>
                  <p class="priority-show-5">
                    <strong>{{ $t("title.roleLabel") }} : &nbsp;&nbsp;</strong>
                    <span v-if="comp.user_config">{{
                      $t("title.userModeLabel_" + comp.user_config.roles[0])
                    }}</span>
                  </p>
                  <p class="priority-show-5">
                    <strong>{{ $t("title.vat") }} : &nbsp;&nbsp;</strong>
                    <span>{{ $t("title.companyStatus_" + comp.active) }}</span>
                  </p>
                </td>
              </tr>
            </template>
          </tbody>
        </table>
      </div>
    </div>
    <div id="modal_popup_info" class="modal-popup">
      <div class="modal-popup-content">
        <a @click="showModalPopup('modal_popup_info', false)" class="close-button">
          <img src="@/assets/images/close-button.svg" />
        </a>
        <div v-if="this.companyDataError">
          <p v-html="this.companyDataError.description"></p>
        </div>
        <div v-else-if="changePasswordSuccess">
          <p>{{ $t("title.changePasswordSuccess") }}</p>
        </div>
        <div v-else-if="deleteCompanySuccess">
          <p>{{ $t("title.deleteCompanySuccessMessage") }}</p>
        </div>
      </div>
    </div>
    <div id="modal_popup_confirmDelete" class="modal-popup">
      <div class="modal-popup-content">
        <a @click="showModalPopup('modal_popup_confirmDelete', false)" class="close-button">
          <img src="@/assets/images/close-button.svg" />
        </a>
        <p v-if="manageCompanies.length > 1">
          {{ $t("title.deleteCompanyText") }}
        </p>
        <p v-else>
          {{ $t("title.deleteLastCompanyText") }}
        </p>
        <div class="buttons">
          <a class="yesButton" @click="deleteCompany()">{{
            $t("title.notificationsChooseOptionYes")
          }}</a>
          <a class="noButton" @click="showModalPopup('modal_popup_confirmDelete', false)">{{
            $t("title.notificationsChooseOptionNo") }}</a>
        </div>
      </div>
    </div>
  </div>
</template>
<style lang="scss">
.custom-switch .custom-control-input:checked~.custom-control-label::after {
  background-color: #f59100 !important;
}
</style>
<script>
import httpServiceUser from "@/http/http-user.js";
import validator from "@/plugins/fieldValidation";
import Cookies from "js-cookie";

import {
  BContainer,
  BRow,
  BCol,
} from "bootstrap-vue-3";

export default {
  components: {
    "b-container": BContainer,
    "b-row": BRow,
    "b-col": BCol,
  },
  props: {
    companyDetails: Object,
    user: Object,
    userRole: String,
    msg: String
  },
  data() {
    return {
      isIE: null,
      companyDataError: null,
      oldPassword: null,
      newPassword: null,
      confirmPassword: "",
      showPassword: false,
      showNewPassword: false,
      showConfirmPassword: false,
      validPassword: {
        passwordValid: false,
        passwordLengthValid: false,
        containsLetter: false,
        containsNumber: false,
        confirmPasswordValid: false
      },
      editMode: false,
      currentPassword: null,
      formData: null,
      manageCompanies: this.user.companies,
      manageCompanySelectedMobile: null,
      detailsMobile: false,
      selectedCompanyForDelete: null,
      selectedManageCompany: "",
      isLoading: false,
      deleteCompanySuccess: false,
      changePasswordSuccess: false,
      notifications: []
    };
  },
  methods: {
    isSelectedSubsidiary(subsidiary) {
      return subsidiary === this.filters.subsidiary;
    },
    subsidiarySelected(event) {
      this.filters.subsidiary = event.target.value;
      this.getCardTransaction();
    },
    selectManageCompanyMobile(index) {
      this.manageCompanySelectedMobile = this.manageCompanies[index];
      this.detailsMobile = !this.detailsMobile;
    },
    validatePassword() {
      this.validPassword = validator.validatePassword(
        this.newPassword,
        this.confirmPassword
      );
    },
    submitUserPassword() {
      this.changePasswordSuccess = false;
      this.deleteCompanySuccess = false;
      this.companyDataError = null;
      this.showModalPopup("modal_popup_info", true);
      if (!this.validPassword.passwordValid) {
        this.companyDataError = {
          description: "Invalid password",
          code: ""
        };
        return;
      } else {
        httpServiceUser.userService
          .confirmUserData(
            this.url,
            {
              id: this.companyDetails.company_id,
              current_password: this.oldPassword,
              new_password: this.newPassword
            },
            this.token
          )
          .then(function (response) {
            return response;
          })
          .catch(error => {
            this.companyDataError = error.response.code;
          })
          .then(response => {
            if (response.status == 200) {
              this.oldPassword = "";
              this.newPassword = "";
              this.confirmPassword = "";
              this.changePasswordSuccess = true;
            } else if (response.status == 400) {
              this.companyDataError = response.data;
            } else {
              this.companyDataError = { description: response.code };
            }
          });
      }
    },
    showModalPopup(elementId, show) {
      if (show) {
        document.getElementById(elementId).style.visibility = "visible";
      } else {
        document.getElementById(elementId).style.visibility = "hidden";
      }
    },
    editData() {
      this.editMode = true;
    },
    saveNewData() {
      this.changePasswordSuccess = false;
      this.showModalPopup("modal_popup_loading", true);
      httpServiceUser.userService
        .confirmUserData(
          this.url,
          {
            id: this.companyDetails.company_id,
            // current_password: this.currentPassword,
            last_name: this.formData.lastName,
            first_name: this.formData.firstName
            // mobile_phone: this.formData.mobilePhone
          },
          this.token
        )
        .then(function (response) {
          return response;
        })
        .then(response => {
          if (response.status == 200) {
            this.companyDataError = null;
            // this.currentPassword = "";
            this.changePasswordSuccess = true;
            this.resetData();
            this.showModalPopup("modal_popup_loading", false);
            this.showModalPopup("modal_popup_info", true);
            window.location.reload();
          } else if (response.status == 400) {
            this.companyDataError = response.data;
            this.resetData();
            this.showModalPopup("modal_popup_loading", false);
            this.showModalPopup("modal_popup_info", true);
            window.location.reload();
          } else {
            this.companyDataError = { description: response.code };
            this.resetData();
            this.showModalPopup("modal_popup_loading", false);
            this.showModalPopup("modal_popup_info", true);
            window.location.reload();
          }
        })
        .catch(error => {
          if (error.response) {
            this.companyDataError = error.response.data;
          } else {
            this.companyDataError = this.$t("title.errorMessage_undefined");
          }
          this.resetData();
          // this.showModalPopup("modal_popup_changeData", false);
          this.showModalPopup("modal_popup_loading", false);
          this.showModalPopup("modal_popup_info", true);
          window.location.reload();
        });
    },
    resetData() {
      this.formData = {
        lastName: this.user.last_name,
        firstName: this.user.first_name
        // mobilePhone: this.user.mobile_phone
      };
      this.editMode = false;
    },
    onlyAlphabetCharacters(variable) {
      if (/^[a-zA-Z]*$/i.test(variable)) {
        return variable;
      } else {
        return variable.replace(/[0-9&\/\\|`#@\[\]\-,+=()$~%.'":*!?<>{}]/g, "");
      }
    },
    // onlyNumericCharacters(variable) {
    //   if (/^[0-9]*$/i.test(variable)) {
    //     return variable;
    //   } else {
    //     return variable.replace(
    //       /[a-zA-Z&\/\\|`#@\[\]\-,+=()$~%.'":*!?<>{}]/g,
    //       ""
    //     );
    //   }
    //   return variable;
    // },
    onlyAlphaNumericCharacters(variable) {
      if (/^[a-zA-Z0-9@.]*$/i.test(variable)) {
        return variable;
      } else {
        return variable.replace(/[&\/\\|`#\[\]\-,+=()$~%'":*!?<>{}]/g, "");
      }
    },
    updateInputCharacters() {
      this.formData.lastName = this.onlyAlphabetCharacters(
        this.formData.lastName
      );
      this.formData.firstName = this.onlyAlphabetCharacters(
        this.formData.firstName
      );
      // this.formData.mobilePhone = this.onlyNumericCharacters(
      //   this.formData.mobilePhone
      // );
      this.formData.email = this.onlyAlphaNumericCharacters(
        this.formData.email
      );
      this.$forceUpdate();
    },
    confirmDeleteCompany(company) {
      this.showModalPopup("modal_popup_confirmDelete", true);
      this.selectedCompanyForDelete = company;
    },
    deleteCompany() {
      this.changePasswordSuccess = false;
      this.deleteCompanySuccess = false;
      this.companyDataError = null;
      this.showModalPopup("modal_popup_confirmDelete", false);
      this.showModalPopup("modal_popup_loading", true);

      httpServiceUser.userService
        .deleteCompanyFromUser(
          this.selectedCompanyForDelete.company_id,
          Cookies.get("token")
        )
        .then(response => {
          if (response.status == 200 || response.status == 204) {
            if (this.user.companies.length === 1) {
              this.logout();
            } else {
              this.deleteCompanySuccess = true;
              this.showModalPopup("modal_popup_loading", false);
              this.showModalPopup("modal_popup_info", true);
              window.location.reload();
            }
          } else {
            this.companyDataError = { description: response.status };
            this.showModalPopup("modal_popup_loading", false);
            this.showModalPopup("modal_popup_info", true);
          }
        })
        .catch(error => {
          if (error.response) {
            this.companyDataError = { description: error.response.status };
          } else {
            this.companyDataError = { description: "undefined" };
          }
          this.showModalPopup("modal_popup_loading", false);
          this.showModalPopup("modal_popup_info", true);
        });
    },
    customLabel({ company_id, name }) {
      return `${company_id} – ${name}`;
    },
    logout() {
      Cookies.remove("userLink");
      Cookies.remove("token");
      this.$router.push({
        name: "login",
        params: { loginUserSelected: false }
      });
    },
  },
  mounted() {
    this.isIE = /*@cc_on!@*/ false || !!document.documentMode;
  },
  computed: {
    confirmPasswordStyle() {
      return this.confirmPassword === ""
        ? ""
        : this.newPassword === this.confirmPassword
          ? ""
          : "outline-color: #ff0000a6;";
    },
    filterManageCompany: function () {
      if (this.selectedManageCompany !== "") {
        const result = this.manageCompanies.filter(
          x => x.company_id === this.selectedManageCompany.company_id
        );
        return result;
      } else {
        return this.manageCompanies;
      }
    },
  },
  created() {
    this.url = Cookies.get("userLink");
    this.token = Cookies.get("token");
    this.resetData();
    // this.getManageCompanies();
  }
};
</script>
<style scoped src="bootstrap/dist/css/bootstrap.css"></style>
<!-- <style scoped src="bootstrap-vue-3/dist/bootstrap-vue.css"></style> -->
<!-- <style src="vue-multiselect/dist/vue-multiselect.min.css"></style> -->
<!-- <style src="@/assets/styles/multiselect.css"></style> -->
<style scoped src="@/assets/styles/beneficiary.css"></style>
<style scoped>
.bottom-0 {
  bottom: 0;
}

.right-0 {
  right: 0;
}

.disabled-input-file {
  background: transparent;
  color: #f49400;
  border: 1px solid #f49400;
}

.modal-popup-content-notif {
  max-width: 1400px;
  width: 100%;
  height: auto;
  border-radius: 10px;
  background: #fff;
  margin: 0px auto;
  position: relative;
  text-align: center;
}

.content {
  width: 95%;
  margin: 20px auto 20px auto;
}
</style>
<style>
.custom-control-input:checked~.custom-control-label::before {
  color: #fff;
  border-color: #007bff;
  background-color: #007bff;
}
</style>
