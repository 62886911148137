<template>
  <div>
    <div class="container-alb filter-mobile">
      <table cellpadding="10" cellspacing="0" width="95%" class="hover-table" align="center">
        <tr>
          <td>
            <img src="@/assets/images/filter.svg" />
            <span class="mobile-show margin-left-5">{{
              $t("title.invoicesFilterTitle")
            }}</span>
          </td>
          <td>
            {{ $t("title.cardTransactionFilterProduct") }}
            <select class="filtrare" @change="productSelected">
              <option v-for="(entry, index) in cardProducts" :key="index" :value="entry.card"
                :selected="isSelectedProduct(entry.card)">{{ entry.name }}</option>
            </select>
          </td>
          <td>
            <form @submit.prevent="
              ">
              {{ $t("title.cardTransactionFilterSubsidiary") }}
              <select class="filtrare" @change="subsidiarySelected">
                <option selected :value="0">{{ $t("title.selectLabel") }}</option>
                <option v-for="(entry, index) in this.workingPointAddress" :key="index" :value="entry.codcom"
                  :selected="isSelectedSubsidiary(entry.codcom)">{{ customLabel(entry) }}</option>
              </select>
            </form>
          </td>
          <td>
            {{ $t("title.invoicesFilterPeriod") }}
            <date-range class="invoices-filter" @close="periodFilterChanged" :time="this.filters.period" />
          </td>
        </tr>
      </table>
    </div>

    <!-- card transaction -->
    <div class="container-alb">
      <div class="table-header-wrapper">
        <div class="left">
          <h3 class="table-title d-inline-block">
            {{ $t("title.cardTransactionTitle") }}
          </h3>
          <img class="download d-inline-block" @click="getExcelTransaction()" src="@/assets/images/download.svg" />
        </div>
      </div>
      <br />

      <CardTransactionTable :company="this.affiliate" :companyTransactions="this.transactionCard"
      @change="sortBy(sortField, sortDirection)" />
      <div class="loading" v-if="this.transactionsLoading">
        <img src="@/assets/images/loading.gif" />
      </div>

      <div class="error-wrapper border-box" v-if="this.transactionCard && this.transactionCard.length === 0">
        <p>{{ $t("title.noTransactionMessage") }}</p>
      </div>

      <div class="error-wrapper border-box" v-if="this.transactionError">
        <p>{{ $t("title.errorTransactionMessage") }}</p>
      </div>

      <div class="pagination-wrapper border-box">
        <b-pagination v-model="currentPage" :total-rows="this.transactionsCount" :per-page="perPage"
          size="sm"></b-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import CardTransactionTable from "@/components/company/affiliate/CardTransactionsTable.vue";
import utils from "@/utils.js";

import DateRange from "@/components/DateRange.vue";
import httpServiceAffiliate from "@/http/http-affiliate.js";
import httpServiceUser from "@/http/http-user.js";
import { BPagination } from "bootstrap-vue-3";
import Cookies from "js-cookie";
import moment from "moment";
import { saveAs } from "file-saver";

export default {
  setup() {
    const { getUrlParam, base64ToArrayBuffer, getProductById } = utils();
    return {
      getUrlParam,
      base64ToArrayBuffer,
      getProductById
    }
  },
  props: {
    affiliate: Object,
    msg: String
  },
  components: {
    CardTransactionTable,
    DateRange,
    "b-pagination": BPagination,
  },
  data() {
    return {
      transactionError: null,
      transactionCard: null,
      tempInvoices: null,
      filters: {
        product: 11,
        subsidiary: "",
        period: [moment().subtract(1, "month"), moment()]
      },
      transactionsCount: 10,
      perPage: 10,
      currentPage: 1,
      sortField: "date",
      sortDirection: "desc",
      subsidiaries: null,
      environment: null,
      selectedProduct: null,
      error: null,
      workingPointAddress: [],
      isLoading: false,
      transactionsLoading: false,
    };
  },
  methods: {
    productSelected(event) {
      this.filters.product = event.target.value;
      this.getCardTransaction();
    },
    subsidiarySelected(event) {
      this.filters.subsidiary = event.target.value;
      this.getCardTransaction();
    },
    periodFilterChanged(value) {
      this.filters.period = value;
      this.getCardTransaction();
    },
    isSelectedProduct(product) {
      return product === this.filters.product;
    },
    isSelectedSubsidiary(subsidiary) {
      return subsidiary === this.filters.subsidiary;
    },
    sortBy(sortField, sortDirection, status) {
      this.sortField = sortField;
      this.sortDirection = sortDirection;
      this.getCardTransaction(status);
    },
    showModalPopup(elementId, show) {
      if (show) {
        document.getElementById(elementId).style.visibility = "visible";
      } else {
        document.getElementById(elementId).style.visibility = "hidden";
      }
    },
    getCardTransaction() {
      this.transactionsLoading = true;
      this.transactionCard = null;
      var transactionData =
        "?product=" +
        this.filters.product +
        (this.filters.subsidiary > 0
          ? "&codfou=" + this.filters.subsidiary : "") +
        "&start_date=" +
        moment(this.filters.period[0]).format("YYYYMMDD") +
        "&end_date=" +
        moment(this.filters.period[1]).format("YYYYMMDD");
      transactionData +=
        "&per_page=" +
        this.perPage +
        "&page=" +
        this.currentPage +
        "&sort=" +
        this.sortDirection;
      if (this.sortDirection === "desc") {
        transactionData += "&desc=" + this.sortField;
      } else {
        transactionData += "&asc=" + this.sortField;
      }
      // this.showModalPopup("modal_popup_loading", true);

      httpServiceAffiliate.affiliateService
        .searchTransactionsForAccount(
          this.affiliate.company_id,
          transactionData,
          Cookies.get("token")
        )
        .then(function (response) {
          return response;
        })
        .then((response) => {
          if (response.status == 200) {
            this.transactionCard = response.data;
            this.transactionsCount = Number(response.headers["x-up-count"]);
          } else if (response.status == 400) {
            this.transactionError = { code: response.data.code };
          } else {
            this.transactionError = { code: response.code };
          }
          this.transactionsLoading = false;
          // this.showModalPopup("modal_popup_loading", false);
        })
        .catch((error) => {
          this.transactionError = { code: "500" };
          this.transactionsLoading = false;
          // this.showModalPopup("modal_popup_loading", false);
        });
    },
    getAddressPoints(env, brand) {
      this.environment = env;
      this.isLoading = true;
      var params =
        "?type=affiliate" +
        "&brand=" +
        brand +
        "&environment=" +
        this.environment +
        "&per_page=10000&page=1";
      if (this.affiliate.user_config && this.affiliate.user_config.config) {
        if (this.affiliate.user_config.config.invoice_group) {
          params += "&invoice_group=1";
        }
      }

      httpServiceUser.userService
        .getAddressPoints(
          this.affiliate.company_id,
          params,
          Cookies.get("token")
        )
        .then((response) => {
          if (response.status == 200) {
            this.subsidiaries = response.data;
            this.isLoading = false;
            this.subsidiaries.forEach((x) => {
              this.workingPointAddress.push({
                codcom: x.codcom,
                address: x.address.street_name + ", " + x.address.locality
              });
            });
          } else {
            return null;
          }
        })
        .catch((error) => {
          return null;
        });
    },
    selectProduct(index) {
      this.selectedProduct = this.cardProducts[index];
      let environment = "card";
      this.getAddressPoints(environment, this.selectedProduct.brand);
    },
    getExcelTransaction() {
      this.showModalPopup("modal_popup_loading", true);

      var formData =
        "?product=" +
        this.filters.product +
        (this.filters.subsidiary === "A"
          ? ""
          : "&codfou=" + this.filters.subsidiary) +
        "&start_date=" +
        moment(this.filters.period[0]).format("YYYYMMDD") +
        "&end_date=" +
        moment(this.filters.period[1]).format("YYYYMMDD") +
        "&per_page=" +
        100000 +
        "&page=" +
        1 +
        "&sort=" +
        this.sortDirection;
      if (this.sortDirection === "desc") {
        formData += "&desc=" + this.sortField;
      } else {
        formData += "&asc=" + this.sortField;
      }
      return httpServiceAffiliate.affiliateService
        .getTransactionFile(
          formData,
          this.affiliate.company_id,
          Cookies.get("token")
        )
        .then((response) => {
          if (response.status == 200) {
            var blob = new Blob(
              [this.base64ToArrayBuffer(response.data.content)],
              {
                type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheetapplication/vnd.ms-excel",
              }
            );
            saveAs(blob, response.data.name);
          } else if (response.status == 400 || response.status == 403) {
            this.error = { code: 500 };
          } else {
            this.error = { code: 500 };
          }
          this.showModalPopup("modal_popup_loading", false);
        })
        .catch((error) => {
          this.error = { code: 500 };
          this.showModalPopup("modal_popup_loading", false);
        });
    },
    customLabel({ codcom, address }) {
      return `${codcom} – ${address}`;
    }
  },
  computed: {
    cardProducts() {
      let cardProducts = [];
      for (let index in this.affiliate.products) {
        if (this.affiliate.products[index].card > 0) {
          cardProducts.push(this.affiliate.products[index]);
        }
      }
      return cardProducts;
    },
  },
  mounted() {
    let productURL = this.getUrlParam("product");
    if (productURL) {
      this.filters.product = this.getProductById(
        parseInt(productURL),
        this.affiliate.products
      ).card;
    } else {
      this.filters.product = this.affiliate.products[0].card;
    }
    this.filters.subsidiary = "A";
    this.transactionCard = this.getCardTransaction();
  },
  created() {
    this.selectProduct(0);
  },
  watch: {
    currentPage: function () {
      this.getCardTransaction();
    }
  }
};
</script>

<style scoped src="@/assets/styles/beneficiary.css"></style>
<style>
.page-link {
  color: #4a4a4a;
}

.page-link:hover {
  color: #4e5b59;
}

.page-item.active .page-link {
  background-color: #4e5b59 !important;
  border-color: #4e5b59 !important;
}

.invoices-filter .reportrange-text {
  height: 27px;
  font-size: 13px;
  padding: 3px 10px;
  border: none !important;
  color: #585858;
  background: #f1f1f1;
}
</style>
