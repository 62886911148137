<template>
  <div class="mt-2 pagination-container px-3">
    <div class="row">
      <div class="col-md-6 col-lg-8">
        <div class="d-flex align-items-center flex-column flex-lg-row">
          <span class="mr-3">{{ $t('title.flexben.pagination.title', {from: this.startItem, to: this.endItem, of: this.totalItems }) }}</span>
          <select class="form-control per-page col-md-3 col-lg-2" @change="changePerPage()" v-model="perPage">
          <option :value="item" v-for="(item,index) in paginationPerPages" :key="index">{{ item }}</option>
        </select>
      </div>
      </div>
      <div class="col-md-6 col-lg-4">
        <nav aria-label="Page navigation example">
          <ul class="pagination justify-content-end">
            <li class="page-item"
                :class="{disabled:currentPage === 1}">
              <button type="button" @click="pageChanged('first')" class="page-link">&laquo;</button>
            </li>
            <li class="page-item"
                :class="{disabled:currentPage === 1}">
              <button type="button" @click="pageChanged('prev')" class="page-link">‹</button>
            </li>
            <li class="page-item"
                v-for="item in visiblePages"
                :class="{active : item.value===currentPage}"
                :disable="item.value===currentPage"
                :key="item.value">
              <button type="button" @click="pageSelected(item.value)" class="page-link">{{item.value}}
              </button>
            </li>
            <li class="page-item"
                :class="{disabled:currentPage === totalPages}">
              <button type="button" @click="pageChanged('next')" class="page-link">›</button>
            </li>
            <li class="page-item"
                :class="{disabled:currentPage === totalPages}">
              <button type="button" @click="pageChanged('last')" class="page-link">&raquo;</button>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  </div>
</template>
<script>
export default {
    props: {
      currentPage: {type: Number, default: 1},
      itemsPerPage: {type: Number, default: 1},
      totalItems: {type: Number, default: 1},
      startItem: {type: Number, default: 1},
      endItem: {type: Number, default: 1},
      maxVisibleButtons: {type: Number, default: 5},
      defaultPerPage: {type: Number, default: 20},
    },
    computed: {
      startPage() {
        if (typeof this.halfMaxVisibleButtons === "undefined") {
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          this.halfMaxVisibleButtons = parseInt(this.maxVisibleButtons / 2);
        }

        if ((this.currentPage + this.halfMaxVisibleButtons) > this.totalPages) {
          return ((this.totalPages - this.maxVisibleButtons) > 0) ? this.totalPages - this.maxVisibleButtons + 1 : 1;
        }

        if (this.currentPage - this.halfMaxVisibleButtons >= 1) {
          return this.currentPage - this.halfMaxVisibleButtons;
        }

        return 1;
      },
      endPage() {
        if (this.currentPage === this.totalPages) {
          return this.currentPage;
        }

        if ((this.currentPage + this.halfMaxVisibleButtons) <= this.maxVisibleButtons) {
          return this.maxVisibleButtons < this.totalPages ? this.maxVisibleButtons : this.totalPages;
        }
        if ((this.currentPage + this.halfMaxVisibleButtons) <= this.totalPages) {
          return this.currentPage + this.halfMaxVisibleButtons;
        }

        return this.totalPages;
      },
      totalPages: function () {
        return Math.ceil(this.totalItems / this.itemsPerPage);
      },
      visiblePages: function () {
        const range = [];
        for (let i = this.startPage; i <= this.endPage; i += 1) {
        range.push({value: i, isDisabled: i === this.currentPage});
        }
        return range;
      },
    },
    data() {
      return {
        activePage: this.currentPage,
        paginationPerPages: [
          10, 20, 50, 100, 1000
        ],
        perPage: 20,
      }
    },
    created() {
      this.perPage = this.defaultPerPage;
    },
    methods: {
      pageSelected(pageNb) {
        this.activePage = pageNb;

      if (pageNb !== this.currentPage) {
        this.$emit('pageChanged', this.activePage);
      }
      },
      pageChanged(direction) {
        switch (direction) {
          case 'first':
            if (this.currentPage !== 1) {
              this.activePage = 1;
              this.$emit('pageChanged', this.activePage);
            }
            break;
          case 'prev':
            if (this.currentPage > 1) {
              this.activePage -= 1;
              this.$emit('pageChanged', this.activePage);
            }
            break;
          case 'next':
            if (this.currentPage < this.totalPages) {
              this.activePage += 1;
              this.$emit('pageChanged', this.activePage);
            }
            break;
          case 'last':
            if (this.currentPage !== this.totalPages) {
              this.activePage = this.totalPages;
              this.$emit('pageChanged', this.activePage);
            }
            break;
        }
      },
      changePerPage() {
        this.$emit('changePerPage', this.perPage)
      }
    }
};
</script>
<style scoped src="../../../node_modules/bootstrap/dist/css/bootstrap.min.css"></style>
<style scoped lang="scss">
.pagination-container {
    .page-link {
      color: #6c757d;
    transition: transform .4s;
    }

    .page-link:hover {
      color: #ffffff;
    background-color: #f49400 !important;
    border-color: #f49400 !important;
    transform: scale(1.1);
    }

    .page-link:focus {
      -webkit-box-shadow: none;
      box-shadow: none;
    }

  .page-item.active .page-link {
    background-color: #f49400 !important;
    border-color: #f49400 !important;
    transform: scale(1.1);
  }
}

@media (max-width: 767.98px) {
    .pagination-container .pagination {
      margin-top: 1rem;
      -webkit-box-pack: center !important;
      justify-content: center !important;
    }
}

</style>
