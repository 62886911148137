<template>
  <div>
    <div v-if="dataLoaded" class="loginWrapper">
      <div class="containerSingleBox">
        <!--Login box -->
        <div class="loginBox background-white">
          <div class="logo">
            <img src="@/assets/images/up.svg" />
            <p v-if="!userCreateSuccess && !mandateUserSuccess">
              <span v-if="this.userCreate.flow === 'add_role_customer'">{{
                $t("title.createMandateTitle")
              }}</span>
              <span v-else>{{ $t("title.createUserTitle") }}</span>
            </p>
            <p
              class="loginError"
              v-if="this.userCreateError != null"
              v-html="this.userCreateError.description"
            ></p>
          </div>

          <div class="loginForm">
            <form
              v-if="
                this.userCreate.flow === 'add_role_customer' &&
                  !mandateUserSuccess
              "
              @submit.prevent="confirmMandat()"
            >
              <p align="center" class="lightGrey">
                {{
                  $t("title.mandateConfirmMessage", {
                    company_code: this.userCreate.login.code
                  })
                }}
              </p>
              <input
                type="submit"
                :value="[[$t('title.confirmButtonLabel')]]"
                class="submitCompanies"
                :class="mandateUserSuccess && 'mandate-button'"
              />
            </form>
            <form
              v-if="
                !userCreateSuccess &&
                  !mandateUserSuccess &&
                  this.userCreate.flow !== 'add_role_customer'
              "
              @submit.prevent="addUserForCompany()"
            >
              <div v-if="showPasswordForm">
                  <small>{{ $t("title.passwordField") }}</small>
                  <br />
                  <div class="password">
                  <input  v-model="newPassword"
                  @input="validatePassword" required
                  class="formedituser"
                    :type="showNewPassword ? 'text' : 'password'"
                      />
                    <i v-on:click="showNewPassword = !showNewPassword">
                      <font-awesome-icon :icon="showNewPassword ? 'eye-slash' : 'eye'"
                        />

                    </i>
                </div>
                  <small>{{ $t("title.confirmPasswordField") }}</small>
                  <br />
                  <div class="password">
                  <input required
                  :style="confirmPasswordStyle" @input="validatePassword" v-model="confirmPassword"
                  class="formedituser"
                  :type="showConfirmPassword ? 'text' : 'password'"
                      />
                    <i v-on:click="showConfirmPassword = !showConfirmPassword">
                      <font-awesome-icon :icon="showConfirmPassword ? 'eye-slash' : 'eye'"
                        />

                    </i>
                </div>
                <p>
                  <img
                    v-if="!isIE"
                    :src="
                      this.validPassword.passwordLengthValid
                        ? require('../../assets/images/yes.png')
                        : require('../../assets/images/no.png')
                    "
                  />
                  &nbsp; {{ $t("title.passwordCriteria1Text") }}
                </p>
                <p>
                  <img
                    v-if="!isIE"
                    :src="
                      this.validPassword.containsLetter
                        ? require('../../assets/images/yes.png')
                        : require('../../assets/images/no.png')
                    "
                  />
                  &nbsp; {{ $t("title.passwordCriteria2Text") }}
                </p>
                <p>
                  <img
                    v-if="!isIE"
                    :src="
                      this.validPassword.containsNumber
                        ? require('../../assets/images/yes.png')
                        : require('../../assets/images/no.png')
                    "
                  />
                  &nbsp; {{ $t("title.passwordCriteria3Text") }}
                </p>
                <input
                  type="submit"
                  :value="[[$t('title.confirmButtonLabel')]]"
                  variant="primary"
                  class="submitCompanies"
                />
              </div>
              <div v-else>
                <input
                  type="text"
                  :placeholder="$t('title.lastNameField')"
                  v-model="formData.first_name"
                  required="true"
                />
                <input
                  type="text"
                  :placeholder="$t('title.surnameFieldLabel')"
                  v-model="formData.last_name"
                  required="true"
                />
                <input
                  type="text"
                  :placeholder="$t('title.emailField')"
                  v-model="formData.email"
                  required="true"
                  disabled
                />
                <!-- <input
                  type="text"
                  :placeholder="$t('title.mobileField')"
                  v-model="formData.mobile_phone"
                  required="true"
                /> -->
                <input
                  type="button"
                  :value="[[$t('title.confirmButtonLabel')]]"
                  variant="primary"
                  class="submitCompanies"
                  @click="checkFillAllInputs()"
                />
              </div>
            </form>
            <form
              v-if="userCreateSuccess || mandateUserSuccess"
              @submit.prevent="redirectToLogin()"
              align="center"
            >
              <img
                v-if="!mandateUserSuccess"
                class="margin20"
                src="@/assets/images/congrats.png"
              />

              <h2 class="pt-3" align="center">
                {{ $t("title.successMessageTitle") }}
              </h2>
              <p v-if="userCreateSuccess" align="center" class="lightGrey">
                {{ $t("title.addUserSuccessMessage") }}
              </p>
              <p v-if="mandateUserSuccess" align="center" class="lightGrey">
                {{
                  $t("title.mandateLPMessage", {
                    company_code: this.userCreate.login.code,
                    company_name: this.userCreate.login.first_name
                  })
                }}
              </p>
              <input
                type="submit"
                :value="[[$t('title.finishButtonLabel')]]"
                class="submitCompanies"
                :class="mandateUserSuccess && 'mandate-button'"
              />
            </form>
          </div>
        </div>
        <!--End Login box -->
      </div>
    </div>
    <div id="modal_popup_loading" class="modal-popup">
      <div class="modal-popup-content">
        <img src="@/assets/images/loading.gif" />
        <p v-html="$t('title.loadingPopupMessage')">
        </p>
      </div>
    </div>
    <div id="modal_popup_info" class="modal-popup">
      <div class="modal-popup-content">
        <a @click="redirectToLogin()" class="close-button">
          <img src="@/assets/images/close-button.svg" />
        </a>
        <div v-if="this.userCreateError != null">
          <p>{{ this.userCreateError.description }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import Cookies from "js-cookie";
import utils from "@/utils.js";
import httpServiceUser from "@/http/http-user.js";
import httpServiceCommon from "@/http/http-common.js";
import validator from "@/plugins/fieldValidation";

export default {
  name: "create_user_company",
  setup() {
    const { getUrlParam } = utils();
    return {
      getUrlParam,
    }
  },
  data() {
    return {
      uuid: null,
      userCreate: null,
      userCreateError: null,
      userCreateSuccess: false,
      mandateUserSuccess: false,
      showNewPassword: false,
      showConfirmPassword: false,
      showPasswordForm: false,
      formData: {
        first_name: "",
        last_name: "",
        email: "",
        mobile_phone: ""
      },
      token: null,
      config: JSON.parse(sessionStorage.getItem("mobile_jsonFile")),
      dataLoaded: false,
      userExit: false,
      isIE: null,
      newPassword: null,
      confirmPassword: "",
      validPassword: {
        passwordValid: false,
        passwordLengthValid: false,
        containsLetter: false,
        containsNumber: false,
        confirmPasswordValid: false
      }
    };
  },
  methods: {
    addUserForCompany() {
      this.userCreateSuccess = false;
      if (
        !this.userExit &&
        (this.newPassword === null || this.confirmPassword === "")
      ) {
        this.userCreateError = { description: "undefined" };
        return;
      }
      if (!this.userExit) {
        this.userCreate.login.password = this.newPassword;
      }

      this.showModalPopup("modal_popup_loading", true);

      this.userCreate.login.first_name = this.formData.first_name;
      this.userCreate.login.last_name = this.formData.last_name;
      this.userCreate.login.email = this.formData.username;
      this.userCreate.login.mobile_phone = this.formData.mobile_phon;

      httpServiceUser.userService
        .confirmNewUserForManageCompany(
          this.userCreate.id,
          this.userCreate,
          this.token
        )
        .then(response => {
          if (response.status == 200 || response.status == 204) {
            this.userCreateSuccess = true;
            this.userCreateError = null;
            this.showModalPopup("modal_popup_loading", false);
          } else {
            this.userCreateError = {
              code: response.data.error_code,
              description: this.getErrorMessage(response.data.error_code)
            };
            this.showModalPopup("modal_popup_loading", false);
          }
        })
        .catch(error => {
          if (error.response) {
            this.userCreateError = {
              code: error.response.data.error_code,
              description: this.getErrorMessage(error.response.data.error_code)
            };
          } else {
            this.userCreateError = { code: error.response.status };
          }
          this.showModalPopup("modal_popup_loading", false);
        });
    },
    confirmDocumentForRole() {
      this.mandateUserSuccess = false;
      this.userCreate.flow = "add_role_customer";

      httpServiceUser.userService
        .confirmNewUserForManageCompany(
          this.userCreate.id,
          this.userCreate,
          this.token
        )
        .then(response => {
          if (response.status == 200 || response.status == 204) {
            this.mandateUserSuccess = true;
          } else {
            this.userCreateError = {
              code: response.data.error_code,
              description: this.getErrorMessage(response.data.error_code)
            };
          }
        })
        .catch(error => {
          if (error.response) {
            this.userCreateError = {
              code: error.response.data.error_code,
              description: this.getErrorMessage(error.response.data.error_code)
            };
          } else {
            this.userCreateError = { code: error.response.status };
          }
        });
    },
    getUserForCompany() {
      let uuidLink = "?uuid=" + this.uuid;
      this.userCreateError = null;
      this.userExit = false;

      this.showModalPopup("modal_popup_loading", true);

      httpServiceUser.userService
        .getUserForCompany(uuidLink, this.token)
        .then(response => {
          if (response.status == 200) {
            this.userCreate = response.data;
            this.formData = {
              first_name: this.userCreate.login.first_name,
              last_name: this.userCreate.login.last_name,
              email: this.userCreate.login.email,
              mobile_phone: ""
            };
            this.showModalPopup("modal_popup_loading", false);
            this.dataLoaded = true;
            // if (
            //   this.userCreate.login.first_name !== "" &&
            //   this.userCreate.login.last_name !== ""
            // ) {
            this.userExit = this.userCreate.login.active === true;
            // }
          } else {
            this.userCreateError = {
              code: response.data.error_code,
              description: this.getErrorMessage(response.data.error_code)
            };
            this.showModalPopup("modal_popup_loading", false);
            this.showModalPopup("modal_popup_info", true);
          }
        })
        .catch(error => {
          if (error.response) {
            if (error.response.status === 400) {
              this.userCreateError = {
                code: "LINK_EXPIRE",
                description: this.$t("title.errorMessage_LINK_EXPIRE")
              };
            } else {
              this.userCreateError = {
                code: error.response.data.error_code,
                description: this.getErrorMessage(
                  error.response.data.error_code
                )
              };
            }
          } else {
            this.userCreateError = { code: error.response.status };
          }
          this.showModalPopup("modal_popup_loading", false);
          this.showModalPopup("modal_popup_info", true);
        });
    },
    getTokenForValidateCredentials() {
      httpServiceCommon.companyService
        .requestTokenForRegistration("clientopen")
        .then(function(response) {
          // handle success
          return response.data;
        })
        .then(data => {
          this.token = data.access_token;
          this.getUserForCompany();
        })
        .catch(error => {
          // handle error
        });
    },
    getErrorMessage(code) {
      let config = JSON.parse(sessionStorage.getItem("mobile_jsonFile"));
      return config.errors[code];
    },
    redirectToLogin() {
      this.showModalPopup("modal_popup_info", false);
      this.$router.push({
        name: "login",
        params: { loginUserSelected: false }
      });
    },
    confirmMandat() {
      this.mandateUserSuccess = true;
      this.confirmDocumentForRole();
    },
    checkFillAllInputs() {
      if (this.formData.first_name === "" || this.formData.last_name === "") {
        this.userCreateError = {
          description: "Toate campurile sunt obligatorii"
        };
      } else {
        if (this.userExit) {
          this.addUserForCompany();
        } else {
          this.showPasswordForm = true;
        }
      }
    },
    showModalPopup(elementId, show) {
      if (show) {
        document.getElementById(elementId).style.visibility = "visible";
      } else {
        document.getElementById(elementId).style.visibility = "hidden";
      }
    },
    validatePassword() {
      this.validPassword = validator.validatePassword(
        this.newPassword,
        this.confirmPassword
      );
    }
  },
  mounted() {
    this.uuid = this.getUrlParam("uuid");
    this.getTokenForValidateCredentials();
  },
  computed: {
    confirmPasswordStyle() {
      return this.confirmPassword === ""
        ? ""
        : this.newPassword === this.confirmPassword
        ? ""
        : "outline-color: #ff0000a6;";
    }
  }
};
</script>
<style scoped src="@/assets/styles/login.css"></style>
<style scoped>
.loginForm input[type="submit"].mandate-button {
  width: auto;
  height: 30px;
  padding: 0 30px;
}
</style>
