<template>

  <div id="app">
    
    <router-view @sendUserRole="sendUserRole" />
    <div class="cookie text-center" v-if="showGdprText">
      <a @click="gdprOnAccept()" href="javascript:void(0)">
        <img
          src="@/assets/images/close-button.svg"
          class="close-button-cookie"
        />
      </a>
      <div class="row justify-content-center">
        <div class="col-xl-8 col-12">
          <p v-html="$t('title.cookiesBoxText')"></p>
        </div>
      </div>
      <div class="row justify-content-center">
        <div class="col-xl-2 col-6">
          <a
            href="javascript:void(0)"
            @click="gdprOnAccept()"
            class="buton customButon textAlb backgroundRosu bold"
            >{{ $t("title.cookiesAcceptBotton") }}</a
          >
        </div>
        <div class="col-xl-2 col-6">
          <a
            href="javascript:void(0)"
            @click="showModalPopup('modal_popup_cookie', true)"
            class="buton customButon textAlb backgroundRosu"
            >{{ $t("title.cookiesCookiePreferences") }}</a
          >
        </div>
      </div>
    </div>
    <!-- <div id="modal_popup_cookie" class="modal-popup">
      <div class="modal-popup-content">
        <a
          @click="showModalPopup('modal_popup_cookie', false)"
          class="close-button"
        >
          <img src="@/assets/images/close-button.svg" />
        </a>
        <div class="scroll-window">
          <Cookie />
        </div>
      </div>
    </div> -->

    <!-- <Chat
      v-if="activeChat"
      :href="this.$route.path"
      :userRole="userRole"
      :companyId="companyId"
    /> -->
  </div>
</template>

<script>
import httpService from "@/http/http-common.js";
// import Chat from "@/views/authentication/Chat.vue";

import Cookies from "js-cookie";
import Cookie from "@/views/Cookies.vue";

export default {
  name: "app",
  components: {
    Cookie,
    // Chat
  },
  data() {
    return {
      companyId: null,
      userRole: "",
      href: null,
      showGdprText: false
    };
  },
  computed:{
    activeChat: function () {
      if (!(typeof process.env.VUE_APP_ENABLE_CHAT_BOT == 'undefined' || parseInt(process.env.VUE_APP_ENABLE_CHAT_BOT))) {
        return false;
      }
      return typeof this.$route.meta == 'undefined' || typeof this.$route.meta.hide_chat_bot == 'undefined' || !this.$route.meta.hide_chat_bot
    }
  },
  methods: {
    gdprOnAccept() {
      Cookies.set("GDPR", "true");
      Cookies.set("functional", "true");
      Cookies.set("analytics", "true");
      Cookies.set("marketing", "true");
      // this.$gtm.enable(true);
      // this.$ga.enable(true);
      this.showGdprText = false;
    },
    showModalPopup(elementId, show) {
      if (show) {
        this.gdprOnAccept();
        document.getElementById(elementId).style.visibility = "visible";
      } else {
        document.getElementById(elementId).style.visibility = "hidden";
      }
    },
    sendUserRole(role, companyId) {
      this.userRole = role;
      this.companyId = companyId;
    }
  },
  destroyed() {
    Cookies.remove("userLink");
    Cookies.remove("token");
    localStorage.clear();
    Cookies.clear();
  },
  mounted() {
    // if (!localStorage.language) {
      localStorage.language = "ro";
    // }

    this.showGdprText = (Cookies.get("GDPR") == null);//show if cookie not set
    if (
      sessionStorage.getItem("mobile_jsonFile") &&
      !JSON.parse(sessionStorage.getItem("mobile_jsonFile")).terms_multiben
    ) {
      sessionStorage.removeItem("mobile_jsonFile");
    }

    sessionStorage.removeItem("client_jsonFile");

    if (sessionStorage.getItem("client_jsonFile")) {
      let configProducts = JSON.parse(sessionStorage.getItem("client_jsonFile"))
        .products;
      if (!configProducts[0].card_profile) {
        sessionStorage.removeItem("client_jsonFile");
      }
    }
    httpService.commonsService.getConfigFile(
      localStorage.language,
      "mobile"
    );
    httpService.commonsService.getConfigFile(localStorage.language, "client");
    httpService.commonsService.getConfigFile(
      localStorage.language,
      "affiliate"
    );
    // document.addEventListener("DOMContentLoaded", function(event) {
    //   DruidWebchat.init({
    //     botId: "06c87315-484e-488e-d260-08d99eb04d22",
    //     baseUrl: "https://prod-druid-api.azurewebsites.net",
    //     queryParams: Cookies.get("token")
    //       ? { access_token: Cookies.get("token") }
    //       : "",
    //     UI: {
    //       containerElement: document.getElementById("druidContainerElement")
    //     }
    //   });
    // });
  }
};
</script>

<style lang="scss">
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
#nav {
  padding: 30px;
  a {
    font-weight: bold;
    color: #2c3e50;
    &.router-link-exact-active {
      color: #42b983;
    }
  }
}

.language-button {
  padding: 5px;
  cursor: pointer;
  font-size: 22px;
  opacity: 0.5;
}

.language-div {
  text-align: right;
  padding-right: 10px;
  padding-top: 5px;
  height: 30px;
}

.close-button-cookie {
  position: absolute;
  right: 5px;
  top: 5px;
  width: 20px;
  cursor: pointer;
}
</style>
